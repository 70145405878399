/* eslint-disable no-underscore-dangle */
import { cilCalendar, cilCheckCircle, cilClock, cilEnvelopeClosed, cilThumbUp, cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCol, CContainer, CProgress, CRow } from '@coreui/react';
import axios from 'axios';
import clsx from 'clsx';
import isoWeek from 'dayjs/plugin/isoWeek';
import { FC, useContext, useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import AWSContext from '@/context/AWSContext.jsx';

import { dayjs } from '@/utils/dayjsSetup.js';

import LoadingPage from '@/views/pages/loading/LoadingPage.jsx';

dayjs.extend(isoWeek);
dayjs.locale('de');

interface Schedule {
    title: string;
    _id: string;
}

interface Patient {
    firstname: string;
    lastname: string;
    dob: string;
    _id: string;
}

interface PredictionDataEntry {
    patient?: Patient;
    schedule?: Schedule;
    start: string;
    end: string;
    predict: boolean;
    predict_proba?: number;
}

interface CopilotBetaStore {
    markedAppointments: {
        [key: string]: boolean;
    };
}

const useCopilotBetaStore = create<CopilotBetaStore>()(
    persist(
        () => ({
            markedAppointments: {},
        }),
        { name: 'copilot-beta-store' }
    )
);

const AppointmentCard: FC<{ predictionData: PredictionDataEntry }> = ({ predictionData }) => {
    const markedAppointments = useCopilotBetaStore((state) => state.markedAppointments);
    const isMarked: boolean | undefined = markedAppointments[predictionData.schedule?._id ?? ''];
    const roundedProbability = Math.floor((predictionData.predict_proba ?? 0) * 100);
    const isLowProbability = roundedProbability < 50;
    const isMarkedInfoShown = predictionData.predict_proba && isLowProbability;

    const markAsShown = () => {
        if (!predictionData.schedule) return;

        useCopilotBetaStore.setState({
            markedAppointments: {
                ...markedAppointments,
                [predictionData.schedule._id]: true,
            },
        });
    };

    return (
        <CCard
            style={{
                width: '30rem',
                transition: 'all 0.3s ease',
            }}
            className="p-4 d-flex flex-column gap-4"
        >
            <div>
                <div className="d-flex align-items-center gap-2">
                    <CIcon icon={cilUser} />
                    <p
                        className="p-0 m-0"
                        style={{ fontWeight: '600' }}
                    >{`${predictionData.patient?.firstname ?? ''} ${predictionData.patient?.lastname ?? ''}`}</p>
                </div>
                <div className="d-flex align-items-center gap-2" style={{ fontSize: '0.75rem' }}>
                    <p className="p-0 m-0">{`DOB: ${dayjs(predictionData.patient?.dob ?? '').format('DD.MM.YYYY')}`}</p>
                    <p className="p-0 m-0">{`ID: ${predictionData.patient?._id ?? ''}`}</p>
                </div>
            </div>
            <div className="d-flex align-items-center gap-2">
                <CIcon icon={cilCalendar} />
                <p className="p-0 m-0">{dayjs(predictionData.start).format('dddd, DD.MM.YYYY')}</p>
            </div>
            <div className="d-flex align-items-center gap-2">
                <CIcon icon={cilClock} />
                <p className="p-0 m-0">{dayjs(predictionData.start).format('HH:mm')}</p>
            </div>
            <div>
                <p
                    style={{
                        fontSize: 'clamp(1rem, 1.1vw, 1.25rem)',
                        fontWeight: '600',
                        whiteSpace: 'nowrap',
                        width: 'calc(100%)',
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    className="p-0 m-0"
                >
                    {predictionData.schedule?.title.split(', ').at(-1) ?? ''}
                </p>
            </div>
            <div>
                <div className="d-flex align-items-center justify-content-between">
                    <p className="p-0 m-0">Show Probability</p>
                    {predictionData.predict_proba && (
                        <p
                            style={{ fontSize: '1.5rem' }}
                            className={clsx('p-0 m-0', isLowProbability ? 'text-danger' : 'text-primary')}
                        >{`${roundedProbability}%`}</p>
                    )}
                    {!predictionData.predict_proba && (
                        <p style={{ fontSize: '1.25rem', color: 'darkgrey' }} className="p-0 m-0">
                            Auswertung erwartet
                        </p>
                    )}
                </div>
                <CProgress value={roundedProbability} color="dark" progressBarClassName="h-100" />
            </div>
            <div className="d-flex justify-content-between" style={{ height: '100%' }}>
                {isMarkedInfoShown && (
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                        }}
                    >
                        <CButton
                            onClick={markAsShown}
                            className="d-flex align-items-center gap-3"
                            style={{
                                transition: 'opacity 0.5s ease, transform 0.5s ease',
                                opacity: isMarked ? 0 : 1,
                                transform: isMarked ? 'scale(0)' : 'scale(1)',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                pointerEvents: isMarked ? 'none' : 'auto',
                                height: '40px',
                            }}
                        >
                            <CIcon icon={cilThumbUp} />
                            Ist erschienen
                        </CButton>

                        <div
                            className="d-flex align-items-center gap-2"
                            style={{
                                transition: 'opacity 0.5s ease, transform 0.5s ease',
                                opacity: isMarked ? 1 : 0,
                                transform: isMarked ? 'scale(1)' : 'scale(0)',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                pointerEvents: isMarked ? 'auto' : 'none',
                                display: 'flex',
                                alignItems: 'flex-end',
                                height: '100%',
                            }}
                        >
                            <CIcon size="xl" color="primary" icon={cilCheckCircle} />
                            Ist erschienen
                        </div>
                    </div>
                )}
                <div style={{ width: '100%' }} className="d-flex justify-content-end align-items-end">
                    <CButton
                        style={{
                            height: '40px',
                        }}
                        variant="outline"
                        className="d-flex align-items-center gap-2"
                    >
                        <CIcon icon={cilEnvelopeClosed} />
                        CC benachrichtigen
                    </CButton>
                </div>
            </div>
        </CCard>
    );
};

const CopilotBeta: FC = () => {
    // @ts-expect-error non ts context
    const { userToken } = useContext(AWSContext);
    const { data, isLoading } = useSWRImmutable({ key: 'true' }, () =>
        axios.get(
            import.meta.env.VITE_ENVIRONMENT === 'prod'
                ? 'https://xni9q6hl2b.execute-api.eu-central-1.amazonaws.com/prod/secure-admin/test'
                : 'https://bqyfkcg3a7.execute-api.eu-central-1.amazonaws.com/prod/secure-admin/test',
            {
                headers: {
                    Authorization: userToken,
                },
            }
        )
    );

    const lastUpdated: string | null = useMemo(
        () => (data?.data?.last_updated ? dayjs(data?.data?.last_updated).format('HH:mm, DD.MM.YYYY') : null),
        [data]
    );

    const predictionData: [string, PredictionDataEntry[]][] = useMemo(() => {
        if (!data?.data) return [];

        let sortedData: PredictionDataEntry[] = data.data.data.sort((a: PredictionDataEntry, b: PredictionDataEntry) =>
            a.start.localeCompare(b.start)
        );

        sortedData = sortedData.filter((entry: PredictionDataEntry) => entry.patient && entry.schedule);

        return Object.entries(
            sortedData.reduce(
                (
                    acc: {
                        [key: string]: PredictionDataEntry[];
                    },
                    item: PredictionDataEntry
                ) => {
                    const weekNumber = dayjs(item.start).isoWeek(); // ISO 8601 calendar week
                    const year = dayjs(item.start).isoWeekYear(); // Correct ISO year (part of isoWeek)

                    const key = `KW ${weekNumber} - ${year}`;
                    if (!acc[key]) acc[key] = [];
                    acc[key].push(item);
                    return acc;
                },
                {}
            )
        );
    }, [data]);

    if (isLoading) return <LoadingPage />;

    return (
        <>
            {lastUpdated && (
                <div
                    className="d-flex align-items-center justify-content-end"
                    style={{ fontSize: '0.8rem', color: 'gray' }}
                >{`Zuletzt berechnet: ${lastUpdated}`}</div>
            )}
            {predictionData.map(([key, predictionDataArray]) => (
                <CContainer className="mb-4 d-flex justify-content-center flex-column align-items-center" key={key}>
                    <div style={{ display: 'flex', alignItems: 'center', margin: '1rem 0', width: '50%' }}>
                        <div style={{ flexGrow: 1, height: '1px', backgroundColor: '#000' }} />
                        <span style={{ margin: '0 10px', whiteSpace: 'nowrap', fontSize: '1.25rem' }}>{key}</span>
                        <div style={{ flexGrow: 1, height: '1px', backgroundColor: '#000' }} />
                    </div>
                    <CRow className="d-flex flex-wrap gap-5 justify-content-center">
                        {predictionDataArray.map((predictionDataEntry) => (
                            <CCol
                                key={predictionDataEntry.schedule?._id ?? ''}
                                style={{ flex: '1 1 250px', maxWidth: '30rem' }}
                                className="d-flex"
                            >
                                <AppointmentCard predictionData={predictionDataEntry} />
                            </CCol>
                        ))}
                    </CRow>
                </CContainer>
            ))}
        </>
    );
};

export default CopilotBeta;
