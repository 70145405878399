import { useMemo, useReducer } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { REMOVE_ALERT, SET_ALERT } from '@/store/actions';

import AlertContext from './AlertContext';
import AlertReducer from './AlertReducer';

const AlertState = ({ children }) => {
    const initialState = [];

    const [state, dispatch] = useReducer(AlertReducer, initialState);

    const setAlert = (msg, type, timeout = 2000) => {
        const id = uuidv4();
        dispatch({
            type: SET_ALERT,
            payload: { msg: msg, type: type, id: id },
        });

        setTimeout(
            () =>
                dispatch({
                    type: REMOVE_ALERT,
                    payload: id,
                }),
            timeout
        );
    };

    const value = useMemo(
        () => ({
            alerts: state,
            setAlert: setAlert,
        }),
        [state]
    );

    return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
};

export default AlertState;
