import { cilGrid, cilTrash, cilWarning } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

const Question = ({ style, question, required, removeQuestion, isFixed = false }) => {
    const doesQuestionExist = !!question;

    const remove = () => {
        if (isFixed) return;

        removeQuestion();
    };

    return (
        <div
            className="p-3 d-flex align-items-center justify-content-between"
            style={{ ...style, fontSize: '0.8rem', position: 'relative' }}
        >
            {doesQuestionExist && (
                <>
                    <div className="d-flex align-items-center gap-2">
                        <CIcon icon={cilGrid} />
                        <p className="p-0 m-0" style={{ width: '25rem' }}>
                            {question.title[0].text}
                        </p>
                    </div>
                    <div
                        className="d-flex gap-3"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#ABABAB',
                        }}
                    >
                        <p className="p-0 m-0">{required ? 'Pflichtfrage' : 'Optional'}</p>
                        <p className="p-0 m-0" style={{ width: '5rem' }}>
                            {question.question_type}
                        </p>
                    </div>
                </>
            )}
            {!doesQuestionExist && (
                <div className="d-flex justify-content-center align-items-center gap-2">
                    <CIcon icon={cilGrid} />
                    <p className="p-0 m-0 d-flex justify-content-center align-items-center gap-3">
                        Diese Frage ist nicht mehr verfügbar und muss entfernt werden.
                        <CIcon icon={cilWarning} className="text-danger" />
                    </p>
                </div>
            )}
            <div
                className="d-flex align-items-center gap-1"
                style={{ cursor: !isFixed ? 'pointer' : 'not-allowed', opacity: !isFixed ? 1 : 0.4 }}
                onClick={remove}
            >
                <CIcon icon={cilTrash} />
                <p className="p-0 m-0">Löschen</p>
            </div>
        </div>
    );
};

export default Question;
