import { CCard, CCardBody, CCardTitle, CCol, CForm, CFormInput, CFormSelect } from '@coreui/react';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PatientContext from '@/context/patient/PatientContext';

const PatientAddressInfoModal = ({ func }) => {
    const { t } = useTranslation(['common']);
    const patientContext = useContext(PatientContext);
    const { patientAddressDetails, successMessage, error } = patientContext;
    const [country, setCountry] = useState([]);
    const [codes, setCodes] = useState([]);

    const [patientAddressInfo, setPatientAddressInfo] = useState({
        user_id: '',
        street: '',
        zip: '',
        city: '',
        country: {
            code: '',
            name: '',
        },
    });
    func(patientAddressInfo);
    const patientAddressOnChange = (e) => {
        setPatientAddressInfo({
            ...patientAddressInfo,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (patientAddressDetails !== null) {
            setPatientAddressInfo(patientAddressDetails);
        }
        countries.registerLocale(enLocale);
        const count = countries.getNames('en', { select: 'official' });
        const co = Object.entries(count).map((entry) => {
            return { label: entry[0], value: entry[0] };
        });
        const cn = Object.entries(count).map((entry) => {
            return { label: entry[1], value: entry[1] };
        });

        setCodes(co);
        setCountry(cn);
    }, [patientAddressDetails, successMessage, error]);

    return patientAddressInfo !== null && (JSON.stringify(patientAddressInfo) === '{}') !== true ? (
        <>
            <CCol lg={12} md={12} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}> {t('Address Data')} </CCardTitle>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={12} md={12} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}> {t('Street')} </CCardTitle>
                        <CForm>
                            <div className="mb-3">
                                <CFormInput
                                    type="text"
                                    value={patientAddressInfo.street}
                                    name="street"
                                    onChange={patientAddressOnChange}
                                />
                            </div>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}> {t('Zip Code')} </CCardTitle>
                        <CForm>
                            <div className="mb-3">
                                <CFormInput
                                    type="text"
                                    value={patientAddressInfo.zip}
                                    name="zip"
                                    onChange={patientAddressOnChange}
                                />
                            </div>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}> {t('City')} </CCardTitle>
                        <CForm>
                            <div className="mb-3">
                                <CFormInput
                                    type="text"
                                    value={patientAddressInfo.city}
                                    name="city"
                                    onChange={patientAddressOnChange}
                                />
                            </div>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}>{t('Country Name')}</CCardTitle>
                        <CFormSelect
                            aria-label="Search Dropdown"
                            options={country}
                            name="name"
                            value={patientAddressInfo.country.name}
                            onChange={(event) => {
                                setPatientAddressInfo((prevState) => ({
                                    ...prevState,
                                    country: {
                                        ...prevState.country,
                                        name: event.target.value,
                                    },
                                }));
                            }}
                        />
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}>{t('Country Code')}</CCardTitle>
                        <CFormSelect
                            aria-label="size 3 select example"
                            options={codes}
                            name="code"
                            value={patientAddressInfo.country.code}
                            onChange={(event) => {
                                setPatientAddressInfo((prevState) => ({
                                    ...prevState,
                                    country: {
                                        ...prevState.country,
                                        code: event.target.value,
                                    },
                                }));
                            }}
                        />
                    </CCardBody>
                </CCard>
            </CCol>
        </>
    ) : (
        ''
    );
};

export default PatientAddressInfoModal;
