import { CButton, CSpinner } from '@coreui/react';
import { CButtonProps } from '@coreui/react/src/components/button/CButton';
import { FC, PropsWithChildren, RefAttributes } from 'react';

type Props = PropsWithChildren &
    CButtonProps &
    RefAttributes<HTMLAnchorElement | HTMLButtonElement> & {
        onClick: () => void;
        disabled?: boolean;
        isLoading?: boolean;
    };

const ButtonWithLoadingSpinner: FC<Props> = ({
    onClick,
    isLoading = false,
    disabled = false,
    children,
    ...rest
}: Props) => {
    return (
        <CButton onClick={onClick} disabled={isLoading || disabled} {...rest}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '0.5rem',
                }}
            >
                {children}
                {isLoading && (
                    <CSpinner style={{ height: '1rem', width: '1rem', borderWidth: '2px', paddingLeft: '0.4rem' }} />
                )}
            </div>
        </CButton>
    );
};

export default ButtonWithLoadingSpinner;
