import { cilLockLocked, cilLockUnlocked } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
    CAvatar,
    CDropdown,
    CDropdownDivider,
    CDropdownHeader,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
} from '@coreui/react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import userAvatar from '@/assets/images/avatars/10.png';

import { useTheme } from '@/context/theme/ThemeContext';

import useAuth from '@/hooks/useAuth';

import { usePowerUserStore, usePrismicStore } from '@/zustandStore';

const AppHeaderDropdown = () => {
    const { logout, user } = useAuth();
    const { t } = useTranslation(['common']);
    const userGroups = usePrismicStore((state) => state.userGroups);
    const lastUsedCert = usePowerUserStore((state) => state.lastUsedCert);
    const { colors } = useTheme();

    const notify = (message) =>
        toast.success(message, {
            iconTheme: {
                primary: colors.secondary,
                secondary: '#fff',
            },
        });

    const getToken = async () => {
        const localStorageItems = { ...localStorage };
        const item = Object.keys(localStorageItems).find((key) => key.includes(user.id) && key.includes('idToken'));
        if (!item) return;
        await navigator.clipboard.writeText(localStorageItems[item]);
        notify('Copied to clipboard!');
    };

    const copyVersion = async () => {
        await navigator.clipboard.writeText(import.meta.env.VITE_VERSION);
        notify(t('VersionCopied'));
    };

    const getLastCert = () => {
        usePowerUserStore.setState({ showCertModal: true });
    };

    return (
        <CDropdown variant="nav-item">
            <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
                <CAvatar src={userAvatar} size="md" />
            </CDropdownToggle>
            <CDropdownMenu className="pt-0" placement="bottom-end">
                <CDropdownHeader className="bg-light fw-semibold py-2">{t('Settings')}</CDropdownHeader>
                {/* <CDropdownItem href="#"> */}
                {/*  <CIcon icon={cilUser} className="me-2" /> */}
                {/*  {t('Profile')} */}
                {/* </CDropdownItem> */}
                {/* <CDropdownItem href="#"> */}
                {/*  <CIcon icon={cilSettings} className="me-2" /> */}
                {/*  {t('Settings')} */}
                {/* </CDropdownItem> */}
                {user &&
                    (userGroups?.admin ||
                        userGroups?.[
                            `${
                                import.meta.env.VITE_ENVIRONMENT === 'local' ? 'dev' : import.meta.env.VITE_ENVIRONMENT
                            }-internal_engineers`
                        ]) && (
                        <CDropdownItem style={{ cursor: 'pointer' }} onClick={getToken}>
                            <CIcon icon={cilLockUnlocked} className="me-2" />
                            ID Token
                        </CDropdownItem>
                    )}
                {lastUsedCert && user && userGroups?.admin && (
                    <CDropdownItem style={{ cursor: 'pointer' }} onClick={getLastCert}>
                        <CIcon icon={cilLockUnlocked} className="me-2" />
                        Last Cert
                    </CDropdownItem>
                )}

                <CDropdownDivider />
                <CDropdownItem onClick={() => logout()}>
                    <CIcon icon={cilLockLocked} className="me-2" />
                    {t('Logout')}
                </CDropdownItem>
                <CDropdownDivider />
                <div onClick={copyVersion} className="d-flex align-items-center justify-content-center mt-2">
                    <p className="p-0 m-0" style={{ fontSize: '0.9rem', cursor: 'pointer' }}>
                        {`Version: ${import.meta.env.VITE_VERSION}`}
                    </p>
                </div>
            </CDropdownMenu>
        </CDropdown>
    );
};

export default AppHeaderDropdown;
