import { CButton, CCard, CTooltip } from '@coreui/react';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { saveSelectedFormsForAppointment } from '@/api/appointment/AppointmentCalls';

import AWSContext from '@/context/AWSContext';

import { UserStatus } from '@/utils/constants';
import { FeatureKey } from '@/utils/helpers';

import { useAdminConfigStore, useLocationStore, usePrismicStore, useTodaysAppointmentsStore } from '@/zustandStore';

import EternoSpinner from '../EternoLogoSpinner/EternoSpinner';
import { AppointmentStatus } from './AppointmentStatusTag';
import FormsSelection from './FormsSelection';
import PrinterDropdown from './PrinterDropdown';

const CheckinCard = ({
    appointment,
    patientName,
    status,
    showNewCheckin,
    showLegacyCheckin,
    checkin,
    resetCheckin,
    confirmCheckin,
    scrollToTop,
    startLegacyCheckin,
}) => {
    const { userToken } = useContext(AWSContext);
    const { t } = useTranslation();
    const featureAccess = useAdminConfigStore((state) => state.featureAccess);
    const professionals = usePrismicStore((state) => state.professionals);
    const checkinData = useTodaysAppointmentsStore((state) => state.checkinData);
    const prismicData = usePrismicStore((state) => state.todaysAppointmentsPrismicData);
    const sidebarShow = useSelector((state) => state.sidebarShow);
    const selectedForms = useTodaysAppointmentsStore((state) => state.selectedForms);
    const professionalFormTemplates = useTodaysAppointmentsStore((state) => state.professionalFormTemplates);
    const selectedLocation = useLocationStore((state) => state.selectedLocation);
    const [showFormSelection, setShowFormSelection] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const saveSelection = async (selectedFormsObj) => {
        scrollToTop();
        setIsLoading(true);
        const newSelectedForms = Object.keys(selectedFormsObj)
            .map(
                (formId) =>
                    selectedFormsObj[formId] && {
                        id: formId,
                        key: professionalFormTemplates[appointment.professional.key][formId].template_key,
                    }
            )
            .filter(Boolean);

        useTodaysAppointmentsStore.setState({
            selectedForms: { ...selectedForms, [appointment.id]: newSelectedForms },
        });

        const userId =
            appointment.user_id && appointment.user_id !== '-' ? appointment.user_id : appointment.data.generated_id;

        const data = {
            user_id: userId,
            appointment_id: appointment.id,
            data: {
                forms: newSelectedForms,
            },
        };

        await saveSelectedFormsForAppointment(userToken, data);

        setShowFormSelection(false);
        setIsLoading(false);
    };

    if (isLoading) {
        return (
            <CCard
                style={{ background: '#F9F8F4', fontSize: '0.9rem' }}
                className="p-3 mt-2 d-flex align-items-center justify-content-center"
            >
                <EternoSpinner />
            </CCard>
        );
    }

    if (appointment.status === 'CANCELLED') {
        return (
            <CCard style={{ background: '#F9F8F4', fontSize: '0.9rem' }} className="p-3 mt-2">
                <p className="p-0 m-0">Dieser Termin wurde abgesagt.</p>
            </CCard>
        );
    }

    if (appointment.user_status !== UserStatus.UNKNOWN && !appointment.professional?.key) {
        return (
            <CCard style={{ background: '#F9F8F4', fontSize: '0.9rem' }} className="p-3 mt-2">
                <p className="p-0 m-0">Dieser Termin kann nicht eingecheckt werden.</p>
            </CCard>
        );
    }

    if (status === AppointmentStatus.ARRIVED) {
        return (
            <CCard style={{ background: '#F9F8F4', fontSize: '0.9rem' }} className="p-3 mt-2">
                <p>{prismicData.title_filling_out_forms[0].text}</p>
                {appointment.professional?.key && (
                    <p className="p-0 m-0">{`Zugewiesenes Zimmer: ${
                        professionals[appointment.professional?.key]?.rooms?.[0]?.text
                    }`}</p>
                )}
                <div className="d-flex align-items-center gap-3 mt-3">
                    <CButton onClick={confirmCheckin} color="secondary">
                        Check-In bestätigen
                    </CButton>
                    {appointment.user_status !== UserStatus.UNKNOWN && (
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                        <p onClick={resetCheckin} style={{ cursor: 'pointer' }} className="link-dark m-0 p-0">
                            Check-In Abbrechen
                        </p>
                    )}
                </div>
            </CCard>
        );
    }

    if (!(appointment.data?.patient_name || patientName?.first) && status === AppointmentStatus.PENDING) {
        return (
            <CCard style={{ background: '#F9F8F4', fontSize: '0.9rem' }} className="p-3 mt-2">
                <p>{prismicData.subtitle__for_cta_check_in_bestatigen[0].text}</p>
                {appointment.professional?.key && (
                    <p className="p-0 m-0">{`Zugewiesenes Zimmer: ${
                        professionals[appointment.professional?.key]?.rooms?.[0]?.text
                    }`}</p>
                )}
                {featureAccess[FeatureKey.CONSENT_FORMS] && (
                    <FormsSelection
                        appointment={appointment}
                        showFormSelection={showFormSelection}
                        setShowFormSelection={setShowFormSelection}
                        saveSelection={saveSelection}
                    />
                )}
                <div className={clsx('d-flex gap-3', !featureAccess[FeatureKey.CONSENT_FORMS] && 'mt-3')}>
                    {showNewCheckin && !showFormSelection && (
                        <CTooltip content={t('newManualCheckinTooltip')}>
                            <PrinterDropdown>
                                <CButton
                                    size={!sidebarShow ? 'md' : 'sm'}
                                    onClick={() =>
                                        useTodaysAppointmentsStore.setState({ showNewManualCheckinModal: true })
                                    }
                                    color="secondary"
                                >
                                    Manueller Auto-Check-In
                                </CButton>
                            </PrinterDropdown>
                        </CTooltip>
                    )}

                    {showLegacyCheckin && !showFormSelection && (
                        <CTooltip content={t('legacyManualCheckinTooltip')}>
                            <CButton
                                size={!sidebarShow ? 'md' : 'sm'}
                                onClick={() =>
                                    useTodaysAppointmentsStore.setState({ showLegacyManualCheckinModal: true })
                                }
                                color="light"
                                className="border-secondary border-2"
                            >
                                Manueller Check-In
                            </CButton>
                        </CTooltip>
                    )}
                </div>
            </CCard>
        );
    }

    if (status === AppointmentStatus.PENDING) {
        return (
            <CCard style={{ background: '#F9F8F4', fontSize: '0.9rem' }} className="p-3 mt-2">
                <p>{prismicData.subtitle__for_cta_check_in_bestatigen[0].text}</p>
                <p className="p-0 m-0">{`Zugewiesenes Zimmer: ${
                    professionals[appointment.professional?.key]?.rooms?.[0]?.text
                }`}</p>
                {featureAccess[FeatureKey.CONSENT_FORMS] && (
                    <FormsSelection
                        appointment={appointment}
                        showFormSelection={showFormSelection}
                        setShowFormSelection={setShowFormSelection}
                        saveSelection={saveSelection}
                    />
                )}
                <div className={clsx('d-flex gap-3', !featureAccess[FeatureKey.CONSENT_FORMS] && 'mt-3')}>
                    {showNewCheckin && !showFormSelection && (
                        <CTooltip content={t('newCheckinTooltip')}>
                            <PrinterDropdown>
                                <CButton onClick={() => checkin()} color="secondary">
                                    {!featureAccess[FeatureKey.CONSENT_FORMS] ||
                                    !selectedForms[appointment.id] ||
                                    selectedForms[appointment.id].length === 0
                                        ? 'Auto-Check-In bestätigen'
                                        : 'Auto-Check-In starten'}
                                </CButton>
                            </PrinterDropdown>
                        </CTooltip>
                    )}
                    {showLegacyCheckin && !showFormSelection && (
                        <CTooltip content={t('legacyCheckinTooltip')}>
                            <CButton
                                size={!sidebarShow ? 'md' : 'sm'}
                                onClick={() => {
                                    if (selectedLocation?.config.is_patient_call_system_active) {
                                        useTodaysAppointmentsStore.setState({ showLegacyCheckinModal: true });
                                    } else {
                                        startLegacyCheckin();
                                    }
                                }}
                                color="light"
                                className="border-secondary border-2"
                            >
                                {!featureAccess[FeatureKey.CONSENT_FORMS] ||
                                !selectedForms[appointment.id] ||
                                selectedForms[appointment.id].length === 0
                                    ? 'Check-In bestätigen'
                                    : 'Check-In starten'}
                            </CButton>
                        </CTooltip>
                    )}
                </div>
            </CCard>
        );
    }

    if (status === AppointmentStatus.MANUALLY_CHECKED_IN || status === AppointmentStatus.CHECKED_IN) {
        const rooms =
            professionals[
                appointment.professional?.key || checkinData[appointment.id]?.appointment?.doctor_name
            ]?.rooms?.[0]?.text?.split(', ');

        if (!rooms) return null;

        let text;
        if (rooms.length === 1) {
            text = prismicData.subtitle_final_checked_in_status[0].text
                .replace(
                    '{patient_name}',
                    appointment.data?.patient_name ||
                        (patientName?.first && `${patientName.first} ${patientName.last}`) ||
                        checkinData[appointment.id]?.name ||
                        t('Patient')
                )
                .replace('{rooms}', rooms[0])
                .replace('oder {last_room}', '');
        } else {
            text = prismicData.subtitle_final_checked_in_status[0].text
                .replace(
                    '{patient_name}',
                    appointment.data?.patient_name ||
                        (patientName?.first && `${patientName.first} ${patientName.last}`) ||
                        checkinData[appointment.id]?.name ||
                        t('Patient')
                )
                .replace('{rooms}', rooms.slice(0, -1).join(', '))
                .replace('{last_room}', rooms.slice(-1)[0]);
        }

        return (
            <CCard style={{ background: '#F9F8F4', fontSize: '0.9rem' }} className="p-3 mt-2">
                <p className="p-0 m-0">{text}</p>
            </CCard>
        );
    }

    return null;
};

export default CheckinCard;
