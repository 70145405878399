import { useMemo, useReducer } from 'react';

import {
    getUserById,
    getUserMappingById,
    getUserMappings,
    getUsers,
    postUserMappingById,
} from '@/api/dcprofessional/DcProfessionalCalls';

import {
    CLEAR_ERRORS,
    CLEAR_OBJECTS,
    GET_ALL_DC_PROFESSIONALS_FAIL,
    GET_ALL_DC_PROFESSIONALS_SUCCESS,
    GET_DC_PROFESSIONAL_BY_ID_FAIL,
    GET_DC_PROFESSIONAL_BY_ID_SUCCESS,
    GET_DC_PROFESSIONAL_MAPPINGS_FAIL,
    GET_DC_PROFESSIONAL_MAPPINGS_SUCCESS,
    GET_DC_PROFESSIONAL_MAPPING_BY_ID_FAIL,
    GET_DC_PROFESSIONAL_MAPPING_BY_ID_SUCCESS,
    POST_PROFESSIONAL_DATA_FAIL,
    POST_PROFESSIONAL_DATA_SUCCESS,
    SET_TO_DEFAULT,
} from '@/store/actions';

import DcProfessionalContext from './DcProfessionalContext';
import DcProfessionalReducer from './DcProfessionalReducer';

const DcProfessionalState = ({ children }) => {
    const initialState = {
        dcProfessionals: [],
        dcProfessionalsMapping: [],
        dcProfessionalsIds: null,
        singleDcProfessionalRecord: null,
        error: null,
        successMessage: null,
    };
    const [state, dispatch] = useReducer(DcProfessionalReducer, initialState);
    // getDcProfessionals
    const getDcProfessionals = async (userToken) => {
        try {
            const response = await getUsers(userToken);
            dispatch({
                type: GET_ALL_DC_PROFESSIONALS_SUCCESS,
                payload: response.data.data,
                successMessage: response.data.data.length !== 0 ? 'Record Found!' : 'Record Not Found!!',
            });
        } catch (error) {
            dispatch({
                type: GET_ALL_DC_PROFESSIONALS_FAIL,
                payload: error.message,
            });
        }
    };

    // getDcProfessionalsMapping
    const getDcProfessionalsMapping = async (userToken) => {
        try {
            const response = await getUserMappings(userToken);
            dispatch({
                type: GET_DC_PROFESSIONAL_MAPPINGS_SUCCESS,
                payload: response.data.data,
                successMessage: 'Record Found!',
            });
        } catch (error) {
            dispatch({
                type: GET_DC_PROFESSIONAL_MAPPINGS_FAIL,
                payload: error.message,
            });
        }
    };

    // getPatientsCall
    const getDcProfessionalMappingByIds = async (userToken, id) => {
        try {
            const response = await getUserMappingById(userToken, id);
            dispatch({
                type: GET_DC_PROFESSIONAL_MAPPING_BY_ID_SUCCESS,
                payload: response.data,
                successMessage: 'Record Found!',
            });
        } catch (error) {
            dispatch({
                type: GET_DC_PROFESSIONAL_MAPPING_BY_ID_FAIL,
                payload: error.message,
            });
        }
    };

    // getDcProfessionalById
    const getDcProfessionalById = async (userToken, id) => {
        try {
            const response = await getUserById(userToken, id);
            dispatch({
                type: GET_DC_PROFESSIONAL_BY_ID_SUCCESS,
                payload: response.data,
                successMessage: 'Record Found!',
            });
        } catch (error) {
            dispatch({
                type: GET_DC_PROFESSIONAL_BY_ID_FAIL,
                payload: error.message,
            });
        }
    };

    // sendProfessionalMappingData
    const sendProfessionalMappingData = async (userToken, id, mappingDataInfo) => {
        try {
            const response = await postUserMappingById(userToken, id, mappingDataInfo);

            dispatch({
                type: POST_PROFESSIONAL_DATA_SUCCESS,
                payload: response.data.data,
            });
        } catch (error) {
            dispatch({
                type: POST_PROFESSIONAL_DATA_FAIL,
                payload: error.message,
            });
        }
    };

    const clearRecords = () => {
        dispatch({ type: CLEAR_OBJECTS });
    };

    const clearError = () => {
        dispatch({ type: CLEAR_ERRORS });
    };
    const setToDefault = () => {
        dispatch({ type: SET_TO_DEFAULT });
    };

    const value = useMemo(
        () => ({
            dcProfessionals: state.dcProfessionals,
            dcProfessionalsMapping: state.dcProfessionalsMapping,
            singleDcProfessionalRecord: state.singleDcProfessionalRecord,
            dcProfessionalsIds: state.dcProfessionalsIds,
            successMessage: state.successMessage,
            error: state.error,
            getDcProfessionals: getDcProfessionals,
            getDcProfessionalById: getDcProfessionalById,
            getDcProfessionalsMapping: getDcProfessionalsMapping,
            getDcProfessionalMappingByIds: getDcProfessionalMappingByIds,
            sendProfessionalMappingData: sendProfessionalMappingData,
            clearRecords: clearRecords,
            clearError: clearError,
            setToDefault: setToDefault,
        }),
        [state]
    );

    return <DcProfessionalContext.Provider value={value}>{children}</DcProfessionalContext.Provider>;
};
export default DcProfessionalState;
