import { _nav as items } from '@/routes';
import { CBadge } from '@coreui/react';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import useAuth from '@/hooks/useAuth';

import { useAdminConfigStore } from '@/zustandStore';

export const AppSidebarNav = () => {
    const [navItems, setNavItems] = useState([]);
    const { t } = useTranslation(['common']);
    const { user } = useAuth();
    const renderedPages = useAdminConfigStore((state) => state.renderedPages);

    useEffect(() => {
        if (items && renderedPages) {
            const array = items
                .filter(
                    (item) =>
                        user.email === import.meta.env.VITE_USER_ADMIN ||
                        user.groups?.includes('admin') ||
                        import.meta.env.VITE_ENVIRONMENT === 'local' ||
                        renderedPages[item.key]
                )
                .map((item) => {
                    let children;
                    if (item.items) {
                        children = item.items.map((child) => ({
                            ...child,
                            name: t(child.name),
                        }));
                    }

                    return {
                        ...item,
                        name: t(item.name),
                        items: children ?? null,
                    };
                });

            setNavItems(array);
        }
        console.debug('navItems: ', navItems);
    }, [renderedPages]);

    useEffect(() => {
        if (localStorage.getItem('i18nextLng')?.length > 2) {
            i18next.changeLanguage('en');
        }
    }, []);

    const location = useLocation();
    const navLink = (name, icon, badge) => {
        return (
            <>
                {icon && icon}
                {name && name}
                {badge && (
                    <CBadge color={badge.color} className="ms-auto">
                        {badge.text}
                    </CBadge>
                )}
            </>
        );
    };

    const navItem = (item, index) => {
        const { component, name, badge, icon, ...rest } = item;
        const Component = component;
        return (
            <Component
                {...(rest.to &&
                    !rest.items && {
                        component: NavLink,
                        activeClassName: 'active',
                    })}
                key={index}
                {...rest}
            >
                {navLink(name, icon, badge)}
            </Component>
        );
    };
    const navGroup = (item, index) => {
        const { component, name, icon, to, ...rest } = item;
        const Component = component;
        return (
            <Component
                idx={String(index)}
                key={index}
                toggler={navLink(name, icon)}
                visible={location.pathname.startsWith(to)}
                {...rest}
            >
                {item.items?.map((entry, idx) => (entry.items ? navGroup(entry, idx) : navItem(entry, idx)))}
            </Component>
        );
    };

    return navItems && navItems.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)));
};
