import {
    CButton,
    CCardTitle,
    CCol,
    CContainer,
    CForm,
    CFormSelect,
    CFormSwitch,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow,
} from '@coreui/react';
import { Autocomplete, TextField } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { postScheduleMapping } from '@/api/dcappointment/DcAppointmentCalls';

import AWSContext from '@/context/AWSContext';

import { hasUndefinedProperty } from '@/utils/helpers';

import { useAppointmentsMappingStore, usePrismicStore } from '@/zustandStore';

const AddMappingModal = () => {
    const { t } = useTranslation();
    const [selectedDCLocation, setSelectedDCLocation] = useState(undefined);
    const [appointmentItems, setAppointmentItems] = useState({});
    const { userToken } = useContext(AWSContext);
    const showAddMappingModal = useAppointmentsMappingStore((state) => state.showAddMappingModal);
    const mapping = useAppointmentsMappingStore((state) => state.mapping);
    const scheduleTypes = useAppointmentsMappingStore((state) => state.scheduleTypes);
    const scheduleMappings = useAppointmentsMappingStore((state) => state.scheduleMappings);
    const locationHubs = usePrismicStore((state) => state.locationHubs);
    const instanceConfigs = usePrismicStore((state) => state.instanceConfigs);
    const appointmentTypes = usePrismicStore((state) => state.appointmentTypes);

    const scheduleTypeOptions = useMemo(
        () =>
            Object.values(scheduleTypes)
                .filter((val) => val?.instance_id === instanceConfigs[selectedDCLocation]?.instance_id[0].text)
                .map((type) => ({
                    label: type.name,
                    value: type.id,
                })),
        [scheduleTypes, instanceConfigs, selectedDCLocation]
    );

    const prismicKeyOptions = useMemo(
        () =>
            Object.keys(appointmentTypes).map((type) => ({
                label: type,
                value: type,
            })),
        [appointmentTypes]
    );

    useEffect(() => {
        if (!mapping || !instanceConfigs) return;

        const location = Object.values(instanceConfigs).find(
            (instance) => instance.instance_id[0]?.text === mapping.dc_instance_id
        )?.hub.id;
        setSelectedDCLocation(location);

        setAppointmentItems({
            [mapping.appointment_type_id]: mapping,
        });
    }, [mapping, instanceConfigs]);

    const addAppointmentItem = () => {
        const id = uuidv4();

        setAppointmentItems({
            ...appointmentItems,
            [id]: {
                appointment_type_id: id,
                dc_schedule_type_id: undefined,
                prismic_key: undefined,
                dc_instance_id: instanceConfigs[selectedDCLocation]?.instance_id[0].text,
                is_for_public_insurance: false,
                is_for_private_insurance: false,
                is_for_self_payers: false,
                is_at_location: false,
                is_online: false,
                is_for_returning_patients: false,
                is_for_new_patients: false,
            },
        });
    };

    const addMappings = async () => {
        const mappings = Object.values(appointmentItems);

        try {
            const response = await Promise.all(mappings.map((item) => postScheduleMapping(userToken, item)));

            let updatedScheduledMappings = [];
            if (mapping) {
                const updatedMapping = response[0].data;
                updatedScheduledMappings = scheduleMappings.map((item) =>
                    item.appointment_type_id === updatedMapping.appointment_type_id ? updatedMapping : item
                );
            } else {
                updatedScheduledMappings = [...scheduleMappings, ...response.map((item) => item.data)];
            }

            useAppointmentsMappingStore.setState({ scheduleMappings: updatedScheduledMappings });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    };

    return (
        <CModal
            scrollable
            visible={showAddMappingModal}
            onClose={() => {
                useAppointmentsMappingStore.setState({ showAddMappingModal: false, mapping: undefined });
                setAppointmentItems({});
                setSelectedDCLocation(undefined);
            }}
        >
            <CModalHeader />
            <CModalBody>
                <CContainer style={{ marginTop: 10, marginBottom: 10 }}>
                    <h3>{mapping ? t('Edit Mapping') : t('Add Mapping')}</h3>
                    <CRow xs={{ gutterY: 0 }}>
                        <CCol>
                            <CForm>
                                <div className="mb-3">
                                    <CCardTitle style={{ fontSize: 14 }}>Location</CCardTitle>
                                    <CFormSelect
                                        options={[
                                            { label: 'Choose Location', value: undefined },
                                            ...locationHubs.map((hub) => {
                                                return {
                                                    label: hub.data.title[0].text,
                                                    value: hub.id,
                                                };
                                            }),
                                        ]}
                                        value={selectedDCLocation}
                                        onChange={(e) => setSelectedDCLocation(e.target.value)}
                                        name="DCLocationSelect"
                                    />
                                </div>
                                <div className="mb-3">
                                    <CCardTitle style={{ fontSize: 14 }}>Appointment Types</CCardTitle>
                                    {Object.values(appointmentItems).map((item) => (
                                        <div
                                            key={item.appointment_type_id}
                                            className="mb-2"
                                            style={{ border: '1px solid #c4c4c4', borderRadius: 4, padding: 6 }}
                                        >
                                            <div className="mb-4 mt-2">
                                                <Autocomplete
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Schedule Type" />
                                                    )}
                                                    value={
                                                        mapping && {
                                                            label: scheduleTypes[
                                                                `${item.dc_instance_id}-${item.dc_schedule_type_id}`
                                                            ]?.name,
                                                            value: item.dc_schedule_type_id,
                                                        }
                                                    }
                                                    onChange={(_, { value }) => {
                                                        setAppointmentItems({
                                                            ...appointmentItems,
                                                            [item.appointment_type_id]: {
                                                                ...item,
                                                                dc_schedule_type_id: value,
                                                            },
                                                        });
                                                    }}
                                                    options={scheduleTypeOptions}
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <Autocomplete
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Appointment Prismic Key" />
                                                    )}
                                                    value={
                                                        mapping && { label: item.prismic_key, value: item.prismic_key }
                                                    }
                                                    options={prismicKeyOptions}
                                                    onChange={(_, { value }) =>
                                                        setAppointmentItems({
                                                            ...appointmentItems,
                                                            [item.appointment_type_id]: {
                                                                ...item,
                                                                prismic_key: value,
                                                            },
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <CFormSwitch
                                                    label="Public Insurance"
                                                    checked={item.is_for_public_insurance}
                                                    onChange={() =>
                                                        setAppointmentItems({
                                                            ...appointmentItems,
                                                            [item.appointment_type_id]: {
                                                                ...item,
                                                                is_for_public_insurance: !item.is_for_public_insurance,
                                                            },
                                                        })
                                                    }
                                                />
                                                <CFormSwitch
                                                    label="Private Insurance"
                                                    checked={item.is_for_private_insurance}
                                                    onChange={() =>
                                                        setAppointmentItems({
                                                            ...appointmentItems,
                                                            [item.appointment_type_id]: {
                                                                ...item,
                                                                is_for_private_insurance:
                                                                    !item.is_for_private_insurance,
                                                            },
                                                        })
                                                    }
                                                />
                                                <CFormSwitch
                                                    label="Self Payer"
                                                    checked={item.is_for_self_payers}
                                                    onChange={() =>
                                                        setAppointmentItems({
                                                            ...appointmentItems,
                                                            [item.appointment_type_id]: {
                                                                ...item,
                                                                is_for_self_payers: !item.is_for_self_payers,
                                                            },
                                                        })
                                                    }
                                                />
                                                <CFormSwitch
                                                    label="At Location"
                                                    checked={item.is_at_location}
                                                    onChange={() =>
                                                        setAppointmentItems({
                                                            ...appointmentItems,
                                                            [item.appointment_type_id]: {
                                                                ...item,
                                                                is_at_location: !item.is_at_location,
                                                            },
                                                        })
                                                    }
                                                />
                                                <CFormSwitch
                                                    label="Online"
                                                    checked={item.is_online}
                                                    onChange={() =>
                                                        setAppointmentItems({
                                                            ...appointmentItems,
                                                            [item.appointment_type_id]: {
                                                                ...item,
                                                                is_online: !item.is_online,
                                                            },
                                                        })
                                                    }
                                                />
                                                <CFormSwitch
                                                    label="For Current Patients"
                                                    checked={item.is_for_returning_patients}
                                                    onChange={() =>
                                                        setAppointmentItems({
                                                            ...appointmentItems,
                                                            [item.appointment_type_id]: {
                                                                ...item,
                                                                is_for_returning_patients:
                                                                    !item.is_for_returning_patients,
                                                            },
                                                        })
                                                    }
                                                />
                                                <CFormSwitch
                                                    label="For New Patients"
                                                    checked={item.is_for_new_patients}
                                                    onChange={() =>
                                                        setAppointmentItems({
                                                            ...appointmentItems,
                                                            [item.appointment_type_id]: {
                                                                ...item,
                                                                is_for_new_patients: !item.is_for_new_patients,
                                                            },
                                                        })
                                                    }
                                                />
                                            </div>
                                            {!mapping && (
                                                <CButton
                                                    color="danger"
                                                    onClick={() => {
                                                        const obj = { ...appointmentItems };
                                                        delete obj[item.appointment_type_id];
                                                        setAppointmentItems(obj);
                                                    }}
                                                >
                                                    {t('Delete')}
                                                </CButton>
                                            )}
                                        </div>
                                    ))}
                                    {!mapping && (
                                        <CButton
                                            onClick={addAppointmentItem}
                                            color="primary"
                                            disabled={!selectedDCLocation}
                                        >
                                            {t('Add appointment type')}
                                        </CButton>
                                    )}
                                </div>
                            </CForm>
                        </CCol>
                    </CRow>
                </CContainer>
            </CModalBody>
            <CModalFooter>
                <CButton
                    color="light"
                    onClick={() => {
                        useAppointmentsMappingStore.setState({ showAddMappingModal: false });
                    }}
                >
                    {t('Close')}
                </CButton>
                <CButton
                    disabled={
                        Object.keys(appointmentItems).length === 0 ||
                        hasUndefinedProperty(Object.values(appointmentItems))
                    }
                    onClick={() => {
                        addMappings();
                        useAppointmentsMappingStore.setState({
                            showAddMappingModal: false,
                            mapping: undefined,
                        });
                        setAppointmentItems({});
                        setSelectedDCLocation(undefined);
                    }}
                    color="secondary"
                >
                    {t('Save Changes')}
                </CButton>
            </CModalFooter>
        </CModal>
    );
};

export default AddMappingModal;
