import { CCardBody, CTableRow } from '@coreui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useProfessionalsStore } from '@/zustandStore';

import { SearchBar, SideList } from './component/PractitionerComponent';

const LeftSideBar = ({ professionals }) => {
    const [searchBy, setSearchBy] = useState('');
    const { t } = useTranslation(['common']);
    const childData = useProfessionalsStore((state) => state.childData);
    const finalRecords = useProfessionalsStore((state) => state.finalRecords);

    return (
        <>
            <SearchBar setSearchBy={setSearchBy} placeholder={t('Search for practitioners')} />
            {professionals?.length === 0 &&
                (finalRecords.length === 0 ? (
                    <CCardBody>
                        <div className="text-center">
                            <div className="spinner-grow text-info" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </CCardBody>
                ) : (
                    <CCardBody>
                        <div className="text-center">
                            <h3>{t('No Data Found')}</h3>
                        </div>
                    </CCardBody>
                ))}
            {professionals?.length > 0 &&
                professionals
                    ?.filter((item) => {
                        return searchBy === ''
                            ? item
                            : item?.data?.display_name[0]?.text?.toLowerCase().includes(searchBy?.toLowerCase());
                    })
                    .map((item) => (
                        <CTableRow
                            v-for="item in tableItems"
                            className="box-view"
                            style={{
                                background: childData?.id === item.id ? '#eee' : 'transparent',
                                transition: 'background 0.3s ease',
                            }}
                            key={item?.id}
                            onClick={() => {
                                useProfessionalsStore.setState({ childData: item });
                            }}
                        >
                            <SideList
                                src={item?.data?.profile_picture?.url}
                                name={item?.data?.display_name[0]?.text}
                                designation={item?.data?.email?.url}
                            />
                        </CTableRow>
                    ))}
        </>
    );
};

export default LeftSideBar;
