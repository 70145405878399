import {
    CLEAR_ERRORS,
    CLEAR_OBJECTS,
    GET_ALL_DC_CALENDAR_FAIL,
    GET_ALL_DC_CALENDAR_SUCCESS,
    GET_CALENDAR_BY_ID_FAIL,
    GET_CALENDAR_BY_ID_SUCCESS,
    SET_TO_DEFAULT,
} from '@/store/actions';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    switch (action.type) {
        case GET_ALL_DC_CALENDAR_SUCCESS:
            return {
                ...state,
                dcCalendars: action.payload,
                successMessage: action.successMessage,
            };

        case GET_CALENDAR_BY_ID_SUCCESS:
            return {
                ...state,
                singleCalendarRecord: action.payload,
                successMessage: action.successMessage,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                successMessage: null,
            };

        case CLEAR_OBJECTS:
            return {
                ...state,
            };

        case SET_TO_DEFAULT:
            return {
                ...state,
                dcCalendars: [],
                singleCalendarRecord: null,
                error: null,
                successMessage: null,
            };
        case GET_ALL_DC_CALENDAR_FAIL:
        case GET_CALENDAR_BY_ID_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};
