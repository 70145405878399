import { useTheme } from '@/context/theme/ThemeContext';

const Icon = ({ src, description, width = 50, height = 50, aspectRatio }) => {
    const { colors } = useTheme();
    const style = {
        width: width,
        height: height,
        backgroundColor: colors.secondary,
        maskImage: `url(${src})`,
        WebkitMaskImage: `url(${src})`,
        maskPosition: 'center',
        maskSize: 'contain',
        maskRepeat: 'no-repeat',
        display: 'inline-block',
        aspectRatio: aspectRatio,
    };

    return <span style={style} role="img" aria-label={description} />;
};

export default Icon;
