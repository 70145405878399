import { useTheme } from '@/context/theme/ThemeContext';

import { hexToRgb } from '@/utils/colors';

import { BasicStatus } from './BasicStatusTag';

const StatusLight = ({ status, style }) => {
    const { colors } = useTheme();

    let color;
    switch (status) {
        case BasicStatus.EGK_READ:
            color = colors.secondary;
            break;
        case BasicStatus.EGK_NOT_READ:
            color = '#e55353';
            break;
        case BasicStatus.NOT_RELEVANT:
        default:
            color = '#ABABAB';
            break;
    }

    const [r, g, b] = hexToRgb(color);

    return (
        <div
            style={{
                background: color,
                width: '1rem',
                height: '1rem',
                borderRadius: '50%',
                boxShadow: `0 0 5px 2px rgba(${r}, ${g}, ${b}, 0.2)`,
                ...style,
            }}
        />
    );
};

export default StatusLight;
