import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from '@coreui/react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AWSContext from '@/context/AWSContext';
import DcPatientContext from '@/context/dcpatient/DcPatientContext';
import PatientContext from '@/context/patient/PatientContext';

const PatientLinkDataModal = ({ email }) => {
    const { t } = useTranslation(['common']);
    const [link, setLink] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [filterBy, setFilterBy] = useState('Email');
    const patientContext = useContext(PatientContext);
    const dcPatientContext = useContext(DcPatientContext);
    const awsContext = useContext(AWSContext);
    const { patientHealthInsuranceDetails, setToDefault } = patientContext;
    const { dcPatients, getDcPatients } = dcPatientContext;
    const { userToken } = awsContext;
    const [checked, setChecked] = useState([]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked[0] = value; // for shifting blue tick
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    useEffect(() => {
        return () => {
            setToDefault();
        };
    }, []);

    return (
        <>
            <CButton
                onClick={() => {
                    // setConfirm(true)
                    setUserEmail(email);
                    getDcPatients(userToken);
                    setLink(true);
                }}
                color="secondary"
            >
                {t('Link Data')}
            </CButton>
            <CModal visible={link} onClose={() => setLink(false)}>
                <CModalHeader onClose={() => setLink(false)}>
                    <CModalTitle>{t('Dc Patients')}</CModalTitle>
                </CModalHeader>
                {dcPatients.length !== 0 ? (
                    <CModalBody>
                        <h6>{t('Filter By')} : </h6>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox checked={filterBy === 'All Show'} />}
                                label={`${t('All Show')}`}
                                name="All Showw"
                                onChange={() => setFilterBy('All Show')}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={filterBy === 'Email'} />}
                                label={`${t('Email')}`}
                                name="Email"
                                onChange={() => setFilterBy('Email')}
                            />

                            <FormControlLabel
                                control={<Checkbox checked={filterBy === 'Insurance'} />}
                                label={`${t('Insurance')}`}
                                name="Insurance"
                                onChange={() => setFilterBy('Insurance')}
                            />
                        </FormGroup>
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {dcPatients
                                .filter((item) => {
                                    // eslint-disable-next-line no-nested-ternary
                                    return filterBy === 'Email'
                                        ? (item.primary_email !== undefined &&
                                              item.primary_email.includes(userEmail)) === true
                                            ? item
                                            : ''
                                        : // eslint-disable-next-line no-nested-ternary
                                          filterBy === 'All Show'
                                          ? item
                                          : // eslint-disable-next-line no-nested-ternary
                                            filterBy === 'Insurance' && item.insurances.length !== 0
                                            ? patientHealthInsuranceDetails !== null
                                                ? item.insurances[0].company_id ===
                                                      patientHealthInsuranceDetails.insurance_company_id &&
                                                  item.insurances[0].type ===
                                                      patientHealthInsuranceDetails.insurance_type &&
                                                  item
                                                : `${t('No Insurance Record Found')}`
                                            : `${t('No Records')}`;
                                })
                                .map((value) => {
                                    const labelId = `checkbox-list-label-${value}`;

                                    return (
                                        <ListItem key={value.user_id} disablePadding>
                                            <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={checked.indexOf(value) !== -1}
                                                        /* eslint-disable-next-line jsx-a11y/tabindex-no-positive */
                                                        tabIndex={1}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    id={labelId}
                                                    primary={`${value.name.salutation} ${value.name.first_name} ${value.name.last_name}`}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                        </List>
                    </CModalBody>
                ) : (
                    <CCol lg={12} md={12} sm={12} xs={12}>
                        <CCard>
                            <CCardBody>
                                <div className="text-center">
                                    <div className="spinner-grow text-info" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </CCardBody>
                        </CCard>
                    </CCol>
                )}
                <CModalFooter>
                    <CButton
                        color="secondary"
                        onClick={() => {
                            setLink(false);
                        }}
                    >
                        {t('Close')}
                    </CButton>
                    <CButton
                        color="secondary"
                        onClick={() => {
                            setLink(false);
                        }}
                    >
                        {t('Submit')}
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    );
};

export default PatientLinkDataModal;
