import { cilMenu } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CContainer, CHeader, CHeaderBrand, CHeaderNav, CHeaderToggler } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { logo } from '@/assets/brand/logo';

import { useTheme } from '@/context/theme/ThemeContext';

import { headContainerStyle, logoStyle, patientTabTitleStyle } from '../styles';
import AppBreadcrumb from './AppBreadcrumb';
import { AppHeaderDropdown } from './header/index';

const AppHeader = () => {
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state.sidebarShow);
    const location = useLocation();
    const { logos } = useTheme();

    return (
        <CHeader position="sticky" className="mb-4">
            <CContainer fluid>
                <div style={headContainerStyle}>
                    <CHeaderToggler
                        className="ps-1"
                        onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
                    >
                        <CIcon icon={cilMenu} size="lg" />
                    </CHeaderToggler>

                    <div style={patientTabTitleStyle}>
                        {!sidebarShow && <img src={logos.small} style={logoStyle} alt="" />}
                        <CHeaderBrand style={{ width: '10rem' }}>
                            {location.pathname === '/patients' ? 'Eterno Care Cloud' : 'Eterno FrontDesk'}
                        </CHeaderBrand>
                    </div>
                    <AppBreadcrumb />
                </div>
                <CHeaderBrand className="mx-auto d-md-none" to="/">
                    <CIcon icon={logo} height={48} alt="Logo" />
                </CHeaderBrand>
                <CHeaderNav className="ms-3 d-flex gap-4">
                    <AppHeaderDropdown />
                </CHeaderNav>
            </CContainer>
        </CHeader>
    );
};

export default AppHeader;
