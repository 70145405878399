import { useMemo, useReducer } from 'react';

import { getCalendars } from '@/api/dccalendar/DcCalendarCalls';

import { CLEAR_ERRORS, GET_ALL_DC_CALENDAR_FAIL, GET_ALL_DC_CALENDAR_SUCCESS, SET_TO_DEFAULT } from '@/store/actions';

import DcCalendarContext from './DcCalendarContext';
import DcCalendarReducer from './DcCalendarReducer';

const DcCalendarState = ({ children }) => {
    const initialState = {
        dcCalendars: [],
        singleCalendarRecord: null,
        error: null,
        successMessage: null,
    };
    const [state, dispatch] = useReducer(DcCalendarReducer, initialState);

    // getAllDcCalendars
    const getAllDcCalendars = async (userToken) => {
        try {
            const response = await getCalendars(userToken);
            dispatch({
                type: GET_ALL_DC_CALENDAR_SUCCESS,
                payload: response.data.data,
                successMessage: 'Record Found!',
            });
        } catch (error) {
            dispatch({
                type: GET_ALL_DC_CALENDAR_FAIL,
                payload: error.message,
            });
        }
    };

    const clearError = () => {
        dispatch({ type: CLEAR_ERRORS });
    };

    const setToDefault = () => {
        dispatch({ type: SET_TO_DEFAULT });
    };

    const value = useMemo(
        () => ({
            dcCalendars: state.dcCalendars,
            singleCalendarRecord: state.singleCalendarRecord,
            error: state.error,
            successMessage: state.successMessage,
            getAllDcCalendars: getAllDcCalendars,
            clearError: clearError,
            setToDefault: setToDefault,
        }),
        [state]
    );

    return <DcCalendarContext.Provider value={value}>{children}</DcCalendarContext.Provider>;
};
export default DcCalendarState;
