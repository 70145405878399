import { DocumentType } from '@/models/Document';
import { MedicalRecordsDocumentDataTypeOfDocResponseItem } from '@/models/prismic-types';

export const downloadUrl = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
};
export const getDocumentTypeTranslation = (
    translations: MedicalRecordsDocumentDataTypeOfDocResponseItem[] | undefined,
    documentType: DocumentType
) => {
    const foundTranslation: MedicalRecordsDocumentDataTypeOfDocResponseItem | undefined = translations?.find(
        (translation) => translation.type_of_doc_responses === documentType
    );
    const typeOfDocument = foundTranslation?.type_of_document[0];

    return typeOfDocument?.text ?? 'Unbekannter Dokumenttyp';
};
