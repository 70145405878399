import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm } from '@coreui/react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { t } from 'i18next';
import { useContext, useEffect, useMemo } from 'react';

import { getCalendars } from '@/api/dccalendar/DcCalendarCalls';
import { getPractitionerMappings, getUsers } from '@/api/dcprofessional/DcProfessionalCalls';

import AddMappingModal from '@/components/PractitionersMapping/AddMappingModal';

import AWSContext from '@/context/AWSContext';

import { useLocationStore, usePractitionersMappingStore, usePrismicStore } from '@/zustandStore';

const PractitionersMapping = () => {
    const professionalMappings = usePractitionersMappingStore((state) => state.professionalMappings);
    const professionals = usePractitionersMappingStore((state) => state.professionals);
    const calendars = usePractitionersMappingStore((state) => state.calendars);
    const showAddMappingModal = usePractitionersMappingStore((state) => state.showAddMappingModal);
    const prismicProfessionals = usePrismicStore((state) => state.professionals);
    const locationHubs = usePrismicStore((state) => state.locationHubs);
    const selectedLocation = useLocationStore((state) => state.selectedLocation);
    const { userToken } = useContext(AWSContext);
    const locationMapping = useMemo(
        () =>
            locationHubs.reduce(
                (acc, hub) => ({
                    ...acc,
                    [hub.id]: hub.data,
                }),
                {}
            ),
        [locationHubs]
    );

    const loadProfessionalMappings = async () => {
        const response = await getPractitionerMappings(userToken);
        if (!response.data?.data) {
            return;
        }
        usePractitionersMappingStore.setState({ professionalMappings: response.data.data });
    };

    const loadCalendars = async () => {
        const response = await getCalendars(userToken, selectedLocation.instanceId);
        if (!response.data?.data) {
            return;
        }
        usePractitionersMappingStore.setState({ calendars: response.data.data });
    };

    const loadProfessionals = async () => {
        const response = await getUsers(userToken, selectedLocation.instanceId);
        if (!response.data?.data) {
            return;
        }
        usePractitionersMappingStore.setState({ professionals: response.data.data });
    };

    const editMapping = (mapping) => {
        usePractitionersMappingStore.setState({ mapping: { ...mapping } });
    };

    const getCalendarName = (id) => {
        return calendars?.find((val) => val.id === id)?.name || 'Calendar no longer exists';
    };

    const showPractitionerName = (id) => {
        const pro = professionals?.find((val) => val.user_id === id);
        return pro ? `${pro.name.first_name} ${pro.name.middle_name} ${pro.name.last_name}` : '';
    };

    useEffect(() => {
        loadProfessionalMappings();
        if (!calendars) loadCalendars();
        if (!professionals) loadProfessionals();
    }, [calendars, professionals]);

    const clearMapping = () => {
        usePractitionersMappingStore.setState({
            mapping: {
                user_id: undefined,
                dc_user_id: '',
                prismic_key: '',
                dc_instance_id: '',
                dc_calendars_data: [],
            },
        });
    };

    useEffect(() => {
        if (!showAddMappingModal) clearMapping();
    }, [showAddMappingModal]);

    return (
        <>
            <CForm>
                <CButton
                    onClick={() => {
                        clearMapping();
                        usePractitionersMappingStore.setState({ showAddMappingModal: true });
                    }}
                    color="secondary"
                >
                    {t('Add Mapping')}
                </CButton>
            </CForm>
            <TableContainer
                component={Paper}
                style={{
                    width: '90%',
                    borderRight: '1px solid #ccc',
                }}
            >
                <Table aria-label="collapsible table">
                    <TableHead
                        style={{
                            position: 'sticky',
                            top: 0,
                            background: 'white',
                        }}
                    >
                        <TableRow>
                            <TableCell>
                                <b>{t('DC User ID')}</b>
                            </TableCell>
                            <TableCell style={{ minWidth: '11rem' }}>
                                <b>{t('Prismic key')}</b>
                            </TableCell>
                            <TableCell>
                                <b>{t('Location')}</b>
                            </TableCell>
                            <TableCell>
                                <b>{t('Calendars')}</b>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {professionalMappings?.map((val) => {
                            return (
                                <TableRow key={val.user_id}>
                                    <TableCell>{showPractitionerName(val.dc_user_id)}</TableCell>
                                    <TableCell style={{ minWidth: '11rem' }}>{val.prismic_key}</TableCell>
                                    <TableCell>
                                        {
                                            locationMapping[prismicProfessionals[val.prismic_key]?.location_hub.id]
                                                ?.title[0].text
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {val.dc_calendars_data?.length !== 0 && (
                                            <CDropdown>
                                                <CDropdownToggle color="secondary">Show Calendars</CDropdownToggle>
                                                <CDropdownMenu>
                                                    {val.dc_calendars_data?.map((cal) => {
                                                        return (
                                                            <CDropdownItem key={cal.dc_calendar_id}>
                                                                {getCalendarName(cal.dc_calendar_id)}
                                                            </CDropdownItem>
                                                        );
                                                    })}
                                                </CDropdownMenu>
                                            </CDropdown>
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        <CButton
                                            onClick={() => {
                                                editMapping(val);
                                                usePractitionersMappingStore.setState({ showAddMappingModal: true });
                                            }}
                                        >
                                            Edit
                                        </CButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <AddMappingModal showPractitionerName={showPractitionerName} reloadMappings={loadProfessionalMappings} />
        </>
    );
};

export default PractitionersMapping;
