import { CButton, CContainer, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react';
import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import ButtonWithLoadingSpinner from '@/components/ButtonWithLoadingSpinner';
import { CodeInput } from '@/components/TodaysAppointments/CodeInput';

import useAuth from '@/hooks/useAuth';

import { ErrorResponseDto } from '@/models/document-error-dto';
import { MedicalRecordsDocument } from '@/models/prismic-types';

import {
    generateDocumentDownloadCode,
    getErrorMessageFromPrismicContent,
    validateDocumentDownloadCode,
} from '@/utils/helpers';
import { getTextFromRichTextField } from '@/utils/prismicHelpers';

import { usePrismicStore } from '@/zustandStore';

interface Props {
    visible: boolean;
    setVisible: (isVisible: boolean) => void;
    onInputCodeSuccess: () => void;
}

enum PopupStep {
    OTP_INTRO_TEXT,
    OTP_INPUT,
}

const TIME_TO_RESEND_SECONDS = 60;

const DocumentCodePopup: FC<Props> = ({ visible, setVisible, onInputCodeSuccess }) => {
    const { userToken, user } = useAuth();
    const { t } = useTranslation();
    const [popupStep, setPopupStep] = useState<PopupStep>(PopupStep.OTP_INTRO_TEXT);
    const [isLoading, setIsLoading] = useState(false);
    const [isResendLoading, setIsResendLoading] = useState(false);
    const [code, setCode] = useState('');
    const [resendTimer, setResendTimer] = useState(TIME_TO_RESEND_SECONDS);
    const canResendCode = resendTimer < 0;

    const medicalRecords: MedicalRecordsDocument['data'] | undefined = usePrismicStore((state) => state.medicalRecords);

    useEffect(() => {
        const interval = setInterval(() => {
            if (resendTimer > 0) setResendTimer((timer) => timer - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const close = () => {
        setVisible(false);
    };

    const sendCode = async () => {
        const [success, errorResponse] = (await generateDocumentDownloadCode(
            userToken,
            user.sub,
            user.email
        )) as unknown as [boolean, AxiosError | undefined];

        if (success) {
            setResendTimer(TIME_TO_RESEND_SECONDS);
            setPopupStep(PopupStep.OTP_INPUT);
        } else {
            const errorData = errorResponse?.response?.data as ErrorResponseDto | undefined;
            if (errorData?.error_code) {
                toast.error(getErrorMessageFromPrismicContent(errorData.error_code, medicalRecords));
            } else {
                console.debug({ axiosErrorResponse: errorResponse });
                toast.error(`${t('unexpected_error_sending_email')} ${errorResponse?.message}`);
            }
        }
    };

    const handleResendCode = async () => {
        setIsResendLoading(true);
        await sendCode();
        setIsResendLoading(false);
    };

    const validateCode = async () => {
        const [success, axiosError]: [boolean, AxiosError['response'] | undefined] =
            (await validateDocumentDownloadCode(userToken, user.sub, code)) as [
                boolean,
                AxiosError['response'] | undefined,
            ];

        if (success) {
            setVisible(false);
            onInputCodeSuccess();
        } else if (axiosError) {
            const errorData = axiosError.data as ErrorResponseDto;
            if (errorData.error_code) {
                toast.error(getErrorMessageFromPrismicContent(errorData.error_code, medicalRecords));
            } else {
                toast.error(`${t('An error occurred while sending the email')} ${t(axiosError.statusText ?? '')}`);
            }
        } else {
            toast.error(`${t('An error occurred while sending the email')})}`);
        }
    };

    const handleConfirm = async () => {
        setIsLoading(true);

        if (popupStep === PopupStep.OTP_INTRO_TEXT) {
            await sendCode();
        } else {
            await validateCode();
        }

        setIsLoading(false);
    };

    return (
        <CModal scrollable visible={visible} onClose={close} size="lg">
            <CModalHeader>{medicalRecords?.title[0]?.text}</CModalHeader>

            <CModalBody
                style={{ height: 'auto', maxHeight: '60vh' }}
                className="overflow-scroll d-flex flex-column gap-2"
            >
                <CContainer style={{ marginTop: 10, marginBottom: 10 }}>
                    {popupStep === PopupStep.OTP_INTRO_TEXT ? (
                        <p>{getTextFromRichTextField(medicalRecords?.otp_description[0])}</p>
                    ) : (
                        <>
                            {medicalRecords?.input_description[0]?.text}
                            <CodeInput code={code} setCode={setCode} />
                            <ButtonWithLoadingSpinner
                                color="light"
                                onClick={handleResendCode}
                                disabled={!canResendCode}
                                isLoading={isResendLoading}
                            >
                                {canResendCode
                                    ? medicalRecords?.resend_otp[0]?.text
                                    : medicalRecords?.disabled_resend_button[0]?.text.replace(
                                          'XX',
                                          resendTimer.toString()
                                      )}
                            </ButtonWithLoadingSpinner>
                        </>
                    )}
                </CContainer>
            </CModalBody>

            <CModalFooter>
                <CButton color="light" onClick={close}>
                    {medicalRecords?.cancel_cta[0]?.text}
                </CButton>
                <ButtonWithLoadingSpinner
                    onClick={handleConfirm}
                    color="secondary"
                    disabled={popupStep !== PopupStep.OTP_INTRO_TEXT && code.length < 6}
                    isLoading={isLoading}
                >
                    {medicalRecords?.request_otp_button[0]?.text}
                </ButtonWithLoadingSpinner>
            </CModalFooter>
        </CModal>
    );
};

export default DocumentCodePopup;
