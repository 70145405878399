import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import rightArrowIcon from '@/assets/right_arrow.svg';

import { useTheme } from '@/context/theme/ThemeContext';

import { UserStatus } from '@/utils/constants';
import { InsuranceType } from '@/utils/helpers';

import { useTodaysAppointmentsStore } from '@/zustandStore';

import AppointmentStatusTag, { AppointmentStatus } from './AppointmentStatusTag';
import { BasicStatus } from './BasicStatusTag';
import StatusLight from './StatusLight';

const AppointmentEntry = ({
    appointment,
    selectedAppointment,
    updateSelectedAppointment,
    detailsLoading,
    professionalName,
    patientName,
}) => {
    const { t } = useTranslation();
    const checkinData = useTodaysAppointmentsStore((state) => state.checkinData);
    const isPatientDataLoading = useTodaysAppointmentsStore((state) => state.isPatientDataLoading);
    const { colors } = useTheme();

    let backgroundColor;
    if (appointment.professional?.key) {
        backgroundColor = selectedAppointment?.id === appointment.id ? 'rgba(76,114,109,0.2)' : 'transparent';
    } else {
        backgroundColor = selectedAppointment?.id === appointment.id ? 'rgba(249,177,21,0.2)' : 'rgba(249,177,21,0.1)';
    }
    if (appointment.inactive) {
        backgroundColor = selectedAppointment?.id === appointment.id ? 'rgba(76,114,109,0.2)' : '#f8f8f8';
    }
    if (appointment.user_status === UserStatus.UNKNOWN) {
        backgroundColor = selectedAppointment?.id === appointment.id ? 'rgba(76,114,109,0.2)' : 'transparent';
    }

    let borderColor;
    if (!appointment.is_external && appointment.user_status !== UserStatus.UNKNOWN) {
        borderColor = colors.primary;
    } else if (appointment.user_status !== UserStatus.UNKNOWN && !appointment.data?.generated_id) {
        borderColor = '#ccc';
    } else {
        borderColor = selectedAppointment?.id === appointment.id ? colors.secondary : '#fff';
    }

    const appointmentInsuranceCardStatus = useMemo(() => {
        if (
            appointment.user_status === UserStatus.UNKNOWN ||
            appointment.data?.insurance_coverage_type.toUpperCase() !== InsuranceType.PUBLIC
        ) {
            return BasicStatus.NOT_RELEVANT;
        }

        if (appointment.data?.is_insurance_card_active) return BasicStatus.EGK_READ;

        return BasicStatus.EGK_NOT_READ;
    }, [appointment]);

    const appointmentStatusTag = useMemo(() => {
        if (appointment.status === 'CANCELLED') {
            return <AppointmentStatusTag status={AppointmentStatus.CANCELLED} />;
        }

        if (!checkinData[appointment.id]) {
            return <AppointmentStatusTag status={AppointmentStatus.PENDING} />;
        }

        return (
            <AppointmentStatusTag
                status={checkinData[appointment.id].appointment?.checkin_status}
                checkinTime={checkinData[appointment.id].appointment?.checkin_time}
                ticketNumber={checkinData[appointment.id].number}
            />
        );
    }, [checkinData, appointment]);

    return (
        <TableBody
            key={appointment.id}
            style={{
                cursor: !detailsLoading ? 'pointer' : 'not-allowed',
                userSelect: 'none',
                opacity: appointment.status !== 'CANCELLED' || selectedAppointment?.id === appointment.id ? 1 : 0.4,
                background: backgroundColor,
                borderLeft: `4px solid ${borderColor}`,
            }}
        >
            <TableRow
                sx={{ '& > *': { borderBottom: 'unset', paddingBottom: 'inherit' } }}
                onClick={() => updateSelectedAppointment(appointment)}
            >
                <TableCell>
                    {(appointment.start &&
                        new Date(appointment.start).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                        })) ||
                        (appointment.appointment?.checkin_time &&
                            new Date(appointment.appointment.checkin_time).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                            })) ||
                        '-'}
                </TableCell>
                <TableCell style={{ position: 'relative' }}>
                    <StatusLight
                        style={{
                            position: 'absolute',
                            left: '0rem',
                            top: '50%',
                            transform: 'translate(-150%, -50%)',
                            width: '0.5rem',
                            height: '0.5rem',
                        }}
                        status={appointmentInsuranceCardStatus}
                    />
                    {appointment.data?.patient_name ||
                        checkinData[appointment.id]?.name ||
                        (!isPatientDataLoading
                            ? (patientName?.first && `${patientName.first} ${patientName.last}`) || t('Not Specified')
                            : 'Wird geladen...')}
                </TableCell>
                <TableCell>{professionalName || '-'}</TableCell>
                <TableCell align="right">{appointmentStatusTag}</TableCell>
                <TableCell>
                    <img src={rightArrowIcon} alt="" />
                </TableCell>
            </TableRow>
        </TableBody>
    );
};

export default AppointmentEntry;
