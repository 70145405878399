import { useMemo, useReducer } from 'react';

import { getById } from '@/api/patuserhealthprofile/PatUserHealthProfileCall';

import {
    CLEAR_ERRORS,
    CLEAR_OBJECTS,
    GET_PAT_USER_BY_ID_HEALTH_PROFILE_FAIL,
    GET_PAT_USER_BY_ID_HEALTH_PROFILE_SUCCESS,
    SET_TO_DEFAULT,
} from '@/store/actions';

import PatUserHealthProfileContext from './PatUserHealthProfileContext';
import PatUserHealthProfileReducer from './PatUserHealthProfileReducer';

const PatUserHealthProfileState = ({ children }) => {
    const initialState = {
        singleUserHealthProfile: null,
        error: null,
        successMessage: null,
    };
    const [state, dispatch] = useReducer(PatUserHealthProfileReducer, initialState);
    // getDcPatientById
    const getPatientUserHealthProfileById = async (userToken, id) => {
        console.debug('cheking api');
        try {
            const response = await getById(userToken, id);
            console.debug(response);
            dispatch({
                type: GET_PAT_USER_BY_ID_HEALTH_PROFILE_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_PAT_USER_BY_ID_HEALTH_PROFILE_FAIL,
                payload: error?.message,
            });
        }
    };

    const clearSinglePatientProfileRecord = () => {
        dispatch({ type: CLEAR_OBJECTS });
    };

    const clearError = () => {
        dispatch({ type: CLEAR_ERRORS });
    };

    const setToDefault = () => {
        dispatch({ type: SET_TO_DEFAULT });
    };

    const value = useMemo(
        () => ({
            singleUserHealthProfile: state.singleUserHealthProfile,
            successMessage: state.successMessage,
            error: state.error,
            getPatientUserHealthProfileById: getPatientUserHealthProfileById,
            clearSinglePatientProfileRecord: clearSinglePatientProfileRecord,
            clearError: clearError,
            setToDefault: setToDefault,
        }),
        [state]
    );

    return <PatUserHealthProfileContext.Provider value={value}>{children}</PatUserHealthProfileContext.Provider>;
};
export default PatUserHealthProfileState;
