import { useMemo, useReducer } from 'react';

import {
    getPatients,
    getUserAccountAddressesById,
    getUserAccountById,
    getUserAccountHealthInsuranceById,
    getUserById,
    syncPatients,
    updateUserAccountAddressesById,
    updateUserAccountById,
    updateUserAccountHealthInsuranceById,
    updateUserById,
} from '@/api/patient/PatientCalls';

import {
    CLEAR_ERRORS,
    CLEAR_OBJECTS,
    GET_ALL_PATIENT_FAIL,
    GET_ALL_PATIENT_SUCCESS,
    GET_PATIENT_ACCOUNT_DETAILS_FAIL,
    GET_PATIENT_ACCOUNT_DETAILS_SUCCESS,
    GET_PATIENT_ADDRESS_DETAILS_FAIL,
    GET_PATIENT_ADDRESS_DETAILS_SUCCESS,
    GET_PATIENT_BY_ID_FAIL,
    GET_PATIENT_BY_ID_SUCCESS,
    GET_PATIENT_HEALTH_PROFILE_DETAILS_FAIL,
    GET_PATIENT_HEALTH_PROFILE_DETAILS_SUCCESS,
    SET_TO_DEFAULT,
    SYNC_PATIENT_DATA_FAIL,
    SYNC_PATIENT_DATA_SUCCESS,
    UPDATE_PATIENT_ACCOUNT_INFORMATION_FAIL,
    UPDATE_PATIENT_ACCOUNT_INFORMATION_SUCCESS,
    UPDATE_PATIENT_ADDRESS_INFO_FAIL,
    UPDATE_PATIENT_ADDRESS_INFO_SUCCESS,
    UPDATE_PATIENT_BASIC_INFO_FAIL,
    UPDATE_PATIENT_BASIC_INFO_SUCCESS,
    UPDATE_PATIENT_HEALTH_INFO_FAIL,
    UPDATE_PATIENT_HEALTH_INFO_SUCCESS,
} from '@/store/actions';

import PatientContext from './PatientContext';
import PatientReducer from './PatientReducer';

const PatientState = ({ children }) => {
    const initialState = {
        patients: [],
        singlePatientRecord: null,
        patientAccountDetails: null,
        patientAppointmentDetails: null,
        patientHealthInsuranceDetails: null,
        patientAddressDetails: null,
        successMessage: null,
        error: null,
    };
    const [state, dispatch] = useReducer(PatientReducer, initialState);

    // getPatientsCall
    const getAllPatients = async (userToken) => {
        try {
            const response = await getPatients(userToken);
            dispatch({
                type: GET_ALL_PATIENT_SUCCESS,
                payload: response.data.data,
                successMessage: response.data.data.length !== 0 ? 'Record Found!' : 'Record Not Found!',
            });
        } catch (error) {
            dispatch({
                type: GET_ALL_PATIENT_FAIL,
                payload: error.message,
            });
        }
    };

    // getSinglePatientRecord
    const getSinglePatientRecord = async (userToken, id) => {
        console.debug('in getSinglePatientRecord');
        console.debug(`id =>  ${id}`);
        try {
            const response = await getUserById(userToken, id);
            dispatch({
                type: GET_PATIENT_BY_ID_SUCCESS,
                payload: response.data,
                successMessage: 'Record Found!',
            });
        } catch (error) {
            dispatch({
                type: GET_PATIENT_BY_ID_FAIL,
                payload: error.message,
            });
        }
    };

    // updatePatientBasicInfo
    const updatePatientBasicInfo = async (userToken, id, patientBasicInfo) => {
        try {
            const response = await updateUserById(userToken, id, patientBasicInfo);
            dispatch({
                type: UPDATE_PATIENT_BASIC_INFO_SUCCESS,
                payload: response.data.data,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_PATIENT_BASIC_INFO_FAIL,
                payload: error.message,
            });
        }
    };

    // getAccountDetailsCall
    const getAccountDetails = async (userToken, id) => {
        try {
            const response = await getUserAccountById(userToken, id);
            dispatch({
                type: GET_PATIENT_ACCOUNT_DETAILS_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_PATIENT_ACCOUNT_DETAILS_FAIL,
                payload: error.message,
            });
        }
    };

    // updatePatientAccountInfoCall
    const updatePatientAccountInfo = async (userToken, id, patientAccountInfo) => {
        try {
            const response = await updateUserAccountById(userToken, id, patientAccountInfo);
            dispatch({
                type: UPDATE_PATIENT_ACCOUNT_INFORMATION_SUCCESS,
                payload: response.data.data,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_PATIENT_ACCOUNT_INFORMATION_FAIL,
                payload: error.message,
            });
        }
    };

    // getPatientAddressById
    const getPatientAddressById = async (userToken, id) => {
        try {
            const response = await getUserAccountAddressesById(userToken, id);
            dispatch({
                type: GET_PATIENT_ADDRESS_DETAILS_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_PATIENT_ADDRESS_DETAILS_FAIL,
                payload: error.message,
            });
        }
    };

    // updateUserAccountAddressesById
    const updatePatientAddressesById = async (userToken, id, patientAddressInfo) => {
        try {
            const response = await updateUserAccountAddressesById(userToken, id, patientAddressInfo);
            dispatch({
                type: UPDATE_PATIENT_ADDRESS_INFO_SUCCESS,
                payload: response.data.data,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_PATIENT_ADDRESS_INFO_FAIL,
                payload: error.message,
            });
        }
    };

    // getPatientHealthProfileById
    const getPatientHealthInsuranceById = async (userToken, id) => {
        try {
            const response = await getUserAccountHealthInsuranceById(userToken, id);
            dispatch({
                type: GET_PATIENT_HEALTH_PROFILE_DETAILS_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_PATIENT_HEALTH_PROFILE_DETAILS_FAIL,
                payload: error.message,
            });
        }
    };

    // updatePatientHealthInsuranceInfo
    const updatePatientHealthInsuranceInfo = async (userToken, id, patientHealthInsuranceInfo) => {
        try {
            const response = await updateUserAccountHealthInsuranceById(userToken, id, patientHealthInsuranceInfo);

            dispatch({
                type: UPDATE_PATIENT_HEALTH_INFO_SUCCESS,
                payload: response.data.data,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_PATIENT_HEALTH_INFO_FAIL,
                payload: error.message,
            });
        }
    };

    // syncPatientData
    const syncPatientData = async (userToken, id) => {
        try {
            const response = await syncPatients(userToken, id);
            dispatch({
                type: SYNC_PATIENT_DATA_SUCCESS,
                payload: response.data.data,
            });
        } catch (error) {
            dispatch({
                type: SYNC_PATIENT_DATA_FAIL,
                payload: error.message,
            });
        }
    };

    const clearRecords = () => {
        dispatch({ type: CLEAR_OBJECTS });
    };

    const clearError = () => {
        dispatch({ type: CLEAR_ERRORS });
    };

    const setToDefault = () => {
        dispatch({ type: SET_TO_DEFAULT });
    };

    const value = useMemo(
        () => ({
            patients: state.patients,
            singlePatientRecord: state.singlePatientRecord,
            patientAccountDetails: state.patientAccountDetails,
            patientAppointmentDetails: state.patientAppointmentDetails,
            patientHealthInsuranceDetails: state.patientHealthInsuranceDetails,
            patientAddressDetails: state.patientAddressDetails,
            successMessage: state.successMessage,
            error: state.error,
            getAllPatients: getAllPatients,
            getSinglePatientRecord: getSinglePatientRecord,
            getAccountDetails: getAccountDetails,
            updatePatientAccountInfo: updatePatientAccountInfo,
            getPatientHealthInsuranceById: getPatientHealthInsuranceById,
            clearRecords: clearRecords,
            getPatientAddressById: getPatientAddressById,
            updatePatientBasicInfo: updatePatientBasicInfo,
            updatePatientAddressesById: updatePatientAddressesById,
            updatePatientHealthInsuranceInfo: updatePatientHealthInsuranceInfo,
            syncPatientData: syncPatientData,
            clearError: clearError,
            setToDefault: setToDefault,
        }),
        [state]
    );

    return <PatientContext.Provider value={value}>{children}</PatientContext.Provider>;
};
export default PatientState;
