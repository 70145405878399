import { cilReload } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react';
import { useTranslation } from 'react-i18next';

const FailedFallback = ({ reloadData }) => {
    const { t } = useTranslation();

    return (
        <div
            style={{ height: '70vh' }}
            className="d-flex flex-column align-items-center justify-content-center gap-4 text-center"
        >
            <h4 className="w-50" style={{ lineHeight: '2.5rem' }}>
                {t('FailedCallTitle')}
            </h4>
            <p className="w-50">{t('FailedCallInfo')}</p>
            <CButton color="secondary" className="px-3 d-flex gap-2 align-items-center" onClick={reloadData}>
                {t('Reload')}
                <CIcon icon={cilReload} />
            </CButton>
        </div>
    );
};

export default FailedFallback;
