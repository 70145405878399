/* eslint-disable import/no-anonymous-default-export */
import {
    CLEAR_ERRORS,
    CLEAR_OBJECTS,
    GET_ALL_PROFESSIONALS_FAIL,
    GET_ALL_PROFESSIONALS_REQUEST,
    GET_ALL_PROFESSIONALS_SUCCESS,
    GET_ONBOARDING_DATA_FAIL,
    GET_ONBOARDING_DATA_SUCCESS,
    GET_PROFESSIONAL_BY_ID_FAIL,
    GET_PROFESSIONAL_BY_ID_SUCCESS,
    GET_PROFESSIONAL_TEMPLATE_ANA_SUCCESS,
    GET_PROFESSIONAL_TEMPLATE_FAIL,
    GET_PROFESSIONAL_TEMPLATE_REQUEST,
    GET_PROFESSIONAL_TEMPLATE_SUCCESS,
    SET_TO_DEFAULT,
    UPDATE_PROFESSIONAL_BY_ID_FAIL,
    UPDATE_PROFESSIONAL_BY_ID_SUCCESS,
} from '@/store/actions';

export default (state, action) => {
    switch (action.type) {
        case GET_ALL_PROFESSIONALS_SUCCESS:
            return {
                ...state,
                professionals: action.payload,
                successMessage: action.successMessage,
                loading: false,
            };
        case GET_ALL_PROFESSIONALS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_PROFESSIONAL_TEMPLATE_SUCCESS:
            return {
                ...state,
                formsTemplates: action.payload,
                successMessage: action.successMessage,
                loading: false,
            };
        case GET_PROFESSIONAL_TEMPLATE_ANA_SUCCESS: {
            const obj = [
                {
                    id: 'ANAMNESIS',
                    template_key: 'ANAMNESIS',
                    content: {
                        name: 'ANAMNESIS',
                    },
                    configuration: {
                        appointment_types: [
                            {
                                key: 'ANAMNESIS',
                                id: 'ANAMNESIS',
                            },
                        ],
                    },
                },
            ];
            return {
                ...state,
                formsTemplates: [...obj, ...action.payload],
                successMessage: action.successMessage,
                loading: false,
            };
        }
        case GET_PROFESSIONAL_TEMPLATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_ONBOARDING_DATA_SUCCESS:
            return {
                ...state,
                onBoardingDetails: action.payload,
            };
        case GET_PROFESSIONAL_BY_ID_SUCCESS:
            return {
                ...state,
                singleProfessionalRecord: action.payload,
                successMessage: action.successMessage,
            };
        case UPDATE_PROFESSIONAL_BY_ID_SUCCESS:
            return {
                ...state,
            };
        case CLEAR_OBJECTS:
            return {
                ...state,
                onBoardingDetails: null,
                singleProfessionalRecord: null,
            };
        case SET_TO_DEFAULT:
            return {
                ...state,
                professionals: [],
                formsTemplates: [],
                onBoardingDetails: null,
                singleProfessionalRecord: null,
                error: null,
                successMessage: null,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                successMessage: null,
            };
        case GET_ALL_PROFESSIONALS_FAIL:
        case GET_ONBOARDING_DATA_FAIL:
        case GET_PROFESSIONAL_BY_ID_FAIL:
        case UPDATE_PROFESSIONAL_BY_ID_FAIL:
        case GET_PROFESSIONAL_TEMPLATE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
