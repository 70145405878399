import { CButton, CCard, CCardBody, CCardText, CCardTitle, CFormCheck, CTooltip } from '@coreui/react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAdminUsersStore } from '@/zustandStore';

import EternoSpinner from '../EternoLogoSpinner/EternoSpinner';

export const cardTitleStyle = {
    fontSize: 13,
    position: 'absolute',
    top: '-10px',
    background: 'white',
    padding: '0 0.2rem',
};

export const cardStyle = {
    height: '4rem',
    display: 'flex',
    alignItems: 'center',
};

export const cardContentStyle = {
    fontSize: 11,
    margin: 0,
    wordBreak: 'break-all',
};

const UserEntry = ({ user, selectedUsers, setSelectedUsers, setShowEditUserPopup }, ref) => {
    const { t } = useTranslation(['common']);
    const currentlyUpdatingUsers = useAdminUsersStore((state) => state.currentlyUpdatingUsers);

    const updateSelectedUsers = () => {
        const newSelectedUsers = { ...selectedUsers };

        if (newSelectedUsers[user.id]) delete newSelectedUsers[user.id];
        else newSelectedUsers[user.id] = true;

        setSelectedUsers(newSelectedUsers);
    };

    const editUser = (event) => {
        event.stopPropagation();
        setSelectedUsers({
            [user.id]: true,
        });
        setShowEditUserPopup(true);
    };

    return (
        <div ref={ref}>
            <CTooltip key={user.id} content={`ID: ${user.id}`}>
                <div style={{ position: 'relative', cursor: 'pointer' }} onClick={updateSelectedUsers}>
                    {currentlyUpdatingUsers[user.id] && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <EternoSpinner />
                        </div>
                    )}
                    <CCard
                        className="px-4 py-4 d-flex flex-row align-items-center justify-content-between"
                        style={{ opacity: currentlyUpdatingUsers[user.id] ? 0.4 : 1 }}
                    >
                        <CFormCheck
                            checked={selectedUsers[user.id]}
                            onChange={(event) => {
                                event.stopPropagation();
                                updateSelectedUsers();
                            }}
                            onClick={(event) => event.stopPropagation()}
                            style={{ marginRight: '2rem' }}
                        />
                        <div className="d-flex align-items-center gap-4" style={{ flex: 1 }}>
                            <CCard style={{ width: '16rem' }}>
                                <CCardBody style={cardStyle}>
                                    <CCardText style={cardTitleStyle}>{t('Username')}</CCardText>
                                    <CCardTitle style={cardContentStyle}>{user.username}</CCardTitle>
                                </CCardBody>
                            </CCard>
                            <CCard style={{ width: '16rem' }}>
                                <CCardBody style={cardStyle}>
                                    <CCardText style={cardTitleStyle}>{t('Email')}</CCardText>
                                    <CCardTitle style={cardContentStyle}>{user.email}</CCardTitle>
                                </CCardBody>
                            </CCard>
                        </div>
                        <div className="d-flex gap-3">
                            <CButton disabled={currentlyUpdatingUsers[user.id]} color="secondary" onClick={editUser}>
                                {t('Edit User')}
                            </CButton>
                        </div>
                    </CCard>
                </div>
            </CTooltip>
        </div>
    );
};

export default forwardRef(UserEntry);
