import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CTooltip } from '@coreui/react';
import { Tab, Tabs } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import useAuth from '@/hooks/useAuth';

import { addGroup, removeGroup, saveCustomers, saveScopes } from '@/utils/helpers';

import { useAdminUsersStore } from '@/zustandStore';

import EditCustomers from './EditCustomers';
import EditGroups from './EditGroups';
import EditScopes from './EditScopes';

const ModalTabs = {
    SCOPES: 'Scopes',
    GROUPS: 'Groups',
    CUSTOMERS: 'Customers',
};

const EditUserPopup = ({
    visible,
    setVisible,
    selectedUsers,
    setSelectedUsers,
    userGroups,
    setUpdatingUserStatus,
    userScopes,
    customers,
}) => {
    const { t } = useTranslation();
    const { userToken } = useAuth();
    const [confirmChanges, setConfirmChanges] = useState(false);
    const [search, setSearch] = useState('');
    const [currentTab, setCurrentTab] = useState(ModalTabs.SCOPES);
    const [selectedScopes, setSelectedScopes] = useState({});
    const [allUserScopes, setAllUserScopes] = useState({});
    const [initialSelectedScopes, setInitialSelectedScopes] = useState({});
    const [scopesToBeAdded, setScopesToBeAdded] = useState([]);
    const [scopesToBeRemoved, setScopesToBeRemoved] = useState([]);
    const [allUserGroups, setAllUserGroups] = useState({});
    const [selectedGroups, setSelectedGroups] = useState({});
    const [initialSelectedGroups, setInitialSelectedGroups] = useState({});
    const [groupsToBeAdded, setGroupsToBeAdded] = useState([]);
    const [groupsToBeRemoved, setGroupsToBeRemoved] = useState([]);
    const [allUserCustomers, setAllUserCustomers] = useState({});
    const [selectedCustomers, setSelectedCustomers] = useState({});
    const [initialSelectedCustomers, setInitialSelectedCustomers] = useState({});
    const [customersToBeAdded, setCustomersToBeAdded] = useState([]);
    const [customersToBeRemoved, setCustomersToBeRemoved] = useState([]);
    const users = useAdminUsersStore((state) => state.users);

    const userKeys = useMemo(() => Object.keys(selectedUsers), [selectedUsers]);
    const userArray = useMemo(
        () => Object.keys(selectedUsers).map((userId) => users[userId]),
        [selectedUsers, visible, users]
    );

    const setScopes = () => {
        const allScopes = userScopes.reduce((obj, scope) => {
            // eslint-disable-next-line no-param-reassign
            obj[scope.scope[0].text] = {
                active: false,
                description: scope.description[0].text,
            };
            return obj;
        }, {});

        setAllUserScopes(allScopes);

        const selectedScopesObj = {};
        userArray.forEach((user) =>
            user.scopes.forEach((scope) => {
                selectedScopesObj[scope] = {
                    active: true,
                    description: allScopes[scope]?.description ?? '',
                    activeUsers: {
                        ...selectedScopesObj[scope]?.activeUsers,
                        [user.email]: true,
                    },
                };
            })
        );

        setSelectedScopes(selectedScopesObj);
        setInitialSelectedScopes(selectedScopesObj);
    };

    const setGroups = () => {
        const allGroups = Object.keys(userGroups).reduce((obj, group) => {
            // eslint-disable-next-line no-param-reassign
            obj[group] = {
                active: false,
                disabled: group.toLowerCase().includes('admin'),
            };
            return obj;
        }, {});

        setAllUserGroups(allGroups);

        const selectedGroupsObj = {};
        userArray.forEach((user) =>
            user.groups?.forEach((group) => {
                selectedGroupsObj[group] = {
                    active: true,
                    disabled: !allGroups[group],
                    activeUsers: {
                        ...selectedGroupsObj[group]?.activeUsers,
                        [user.email]: true,
                    },
                };
            })
        );

        setSelectedGroups(selectedGroupsObj);
        setInitialSelectedGroups(selectedGroupsObj);
    };

    const setCustomers = () => {
        const allCustomers = Object.keys(customers).reduce((obj, key) => {
            const { customer_id, customer_name, instanceIds } = customers[key];
            return {
                ...obj,
                [customer_id[0].text]: {
                    active: false,
                    name: customer_name[0].text,
                    instanceIds: instanceIds,
                },
            };
        }, {});

        setAllUserCustomers(allCustomers);

        const selectedCustomersObj = {};
        userArray.forEach((user) => {
            if (!user.customer_ids) return;
            user.customer_ids.forEach((customerId) => {
                selectedCustomersObj[customerId] = {
                    active: true,
                    name: allCustomers[customerId]?.name,
                    activeUsers: {
                        ...selectedCustomersObj[customerId]?.activeUsers,
                        [user.email]: true,
                    },
                };
            });
        });

        setSelectedCustomers(selectedCustomersObj);
        setInitialSelectedCustomers(selectedCustomersObj);
    };

    useEffect(() => {
        if (userKeys.length === 0 || !visible || !customers) return;
        setScopes();
        setGroups();
        setCustomers();
        setCurrentTab(ModalTabs.SCOPES);
        setConfirmChanges(false);
    }, [userKeys, visible, customers]);

    useEffect(() => {
        document.body.style.overflow = visible ? 'hidden' : 'unset';
    }, [visible]);

    const showConfirmChanges = () => {
        // Scopes
        setScopesToBeAdded(Object.keys(selectedScopes).filter((scope) => selectedScopes[scope].setForAllUsers));
        setScopesToBeRemoved(
            Object.keys(allUserScopes).filter(
                (scope) => !selectedScopes[scope] && initialSelectedScopes[scope] && allUserScopes[scope]
            )
        );

        // Groups
        setGroupsToBeAdded(Object.keys(selectedGroups).filter((group) => selectedGroups[group].setForAllUsers));
        setGroupsToBeRemoved(
            Object.keys(allUserGroups).filter(
                (group) => !selectedGroups[group] && initialSelectedGroups[group] && allUserGroups[group]
            )
        );

        // Customers
        setCustomersToBeAdded(
            Object.keys(selectedCustomers).filter((customer) => selectedCustomers[customer].setForAllUsers)
        );
        setCustomersToBeRemoved(
            Object.keys(allUserCustomers).filter(
                (customer) =>
                    !selectedCustomers[customer] && initialSelectedCustomers[customer] && allUserCustomers[customer]
            )
        );

        setConfirmChanges(true);
    };

    const getPromisesForUser = (user) => {
        const promises = [];

        const newUserScopes = user.scopes.reduce((obj, scope) => {
            // eslint-disable-next-line no-param-reassign
            obj[scope] = true;
            return obj;
        }, {});
        const newUserGroups = user.groups.reduce((obj, group) => {
            // eslint-disable-next-line no-param-reassign
            obj[group] = true;
            return obj;
        }, {});
        const userCustomers = user.customer_ids.reduce((obj, customer) => {
            // eslint-disable-next-line no-param-reassign
            obj[customer] = true;
            return obj;
        }, {});

        scopesToBeAdded.forEach((scope) => {
            newUserScopes[scope] = true;
        });
        scopesToBeRemoved.forEach((scope) => {
            delete newUserScopes[scope];
        });

        promises.push(saveScopes(userToken, Object.keys(newUserScopes), user));

        customersToBeAdded.forEach((customer) => {
            userCustomers[customer] = true;
        });
        customersToBeRemoved.forEach((customer) => {
            delete userCustomers[customer];
        });

        const instanceIds = Object.keys(userCustomers).reduce((obj, customerId) => {
            const customer = allUserCustomers[customerId];
            return {
                ...obj,
                ...customer?.instanceIds,
            };
        }, {});

        promises.push(saveCustomers(userToken, Object.keys(userCustomers), Object.keys(instanceIds), user));

        groupsToBeAdded.forEach((group) => {
            if (!newUserGroups[group]) {
                newUserGroups[group] = true;
                promises.push(addGroup(userToken, group, user));
            }
        });
        groupsToBeRemoved.forEach((group) => {
            if (newUserGroups[group]) {
                delete newUserGroups[group];
                promises.push(removeGroup(userToken, group, user));
            }
        });

        return [promises, Object.keys(newUserScopes), Object.keys(newUserGroups), Object.keys(userCustomers)];
    };

    // TODO add proper error handling and status display
    const processUser = async (user) => {
        setUpdatingUserStatus(user.id, true);
        const [promises, newUserScopes, newUserGroups, newUserCustomers] = getPromisesForUser(user);
        console.debug(`${promises.length} promises for user(${user.email})`);
        await Promise.all(promises);
        setUpdatingUserStatus(user.id, false);

        return {
            userId: user.id,
            newUserScopes: newUserScopes,
            newUserGroups: newUserGroups,
            newUserCustomers: newUserCustomers,
        };
    };

    const updateUserEntries = (data) => {
        const newUsers = { ...useAdminUsersStore.getState().users };

        data.forEach(({ userId, newUserScopes, newUserGroups, newUserCustomers }) => {
            newUsers[userId] = {
                ...newUsers[userId],
                scopes: newUserScopes,
                groups: newUserGroups,
                customer_ids: newUserCustomers,
            };
        });

        useAdminUsersStore.setState({ users: newUsers });
    };

    const save = async () => {
        setVisible(false);
        setSelectedUsers({});

        const userPromises = userArray.map((user) => processUser(user));

        const response = await Promise.all(userPromises);

        updateUserEntries(response);

        toast.success(userKeys.length > 1 ? `${userKeys.length} Users updated` : `${userArray[0]?.email} updated`, {
            iconTheme: {
                primary: '#4C726D',
                secondary: '#fff',
            },
            style: {
                textAlign: 'center',
            },
        });
    };

    const renderTab = useCallback(() => {
        switch (currentTab) {
            case ModalTabs.SCOPES:
                return (
                    <EditScopes
                        allUserScopes={allUserScopes}
                        initialSelectedScopes={initialSelectedScopes}
                        search={search}
                        setSearch={setSearch}
                        selectedScopes={selectedScopes}
                        setSelectedScopes={setSelectedScopes}
                        userArray={userArray}
                    />
                );
            case ModalTabs.GROUPS:
                return (
                    <EditGroups
                        search={search}
                        setSearch={setSearch}
                        allUserGroups={allUserGroups}
                        initialSelectedGroups={initialSelectedGroups}
                        selectedGroups={selectedGroups}
                        setSelectedGroups={setSelectedGroups}
                        userArray={userArray}
                    />
                );
            case ModalTabs.CUSTOMERS:
                return (
                    <EditCustomers
                        search={search}
                        setSearch={setSearch}
                        allUserCustomers={allUserCustomers}
                        initialSelectedCustomers={initialSelectedCustomers}
                        setSelectedCustomers={setSelectedCustomers}
                        selectedCustomers={selectedCustomers}
                        userArray={userArray}
                    />
                );
            default:
                return null;
        }
    }, [
        currentTab,
        search,
        allUserScopes,
        initialSelectedScopes,
        selectedScopes,
        allUserGroups,
        initialSelectedGroups,
        selectedGroups,
        allUserCustomers,
        initialSelectedCustomers,
        selectedCustomers,
    ]);

    return (
        <CModal
            scrollable
            visible={visible}
            onClose={() => {
                setVisible(false);
            }}
            size={confirmChanges ? 'md' : 'lg'}
        >
            {userKeys.length > 1 ? (
                <CModalHeader>
                    <CTooltip placement="top" content={userArray.map((user) => user?.email).join(',')}>
                        <p className="p-0 m-0 position-relative">
                            {!confirmChanges ? `${userKeys.length} Users` : t('Confirm Changes')}
                        </p>
                    </CTooltip>
                </CModalHeader>
            ) : (
                <CModalHeader>{!confirmChanges ? `${userArray[0]?.email}` : t('Confirm Changes')}</CModalHeader>
            )}
            <CModalBody
                style={{ height: !confirmChanges ? '60vh' : 'auto', maxHeight: '60vh', padding: '0 1rem' }}
                className="overflow-scroll d-flex flex-column gap-2"
                lg={12}
                md={12}
                sm={12}
                xs={12}
            >
                {!confirmChanges && (
                    <Tabs
                        value={currentTab}
                        onChange={(event, newValue) => setCurrentTab(newValue)}
                        TabIndicatorProps={{
                            style: {
                                background: '#4C726D',
                            },
                        }}
                    >
                        {Object.values(ModalTabs).map((modalTab) => (
                            <Tab
                                key={modalTab}
                                disableRipple
                                value={modalTab}
                                label={modalTab}
                                style={{ color: currentTab === modalTab ? '#000' : '#a9a9a9' }}
                            />
                        ))}
                    </Tabs>
                )}
                {!confirmChanges ? (
                    renderTab()
                ) : (
                    <div className="d-flex flex-column align-items-center gap-3 py-3">
                        <div className="d-flex flex-column align-items-center ">
                            <h3 className="p-0 m-0 mb-1">Scopes</h3>
                            {scopesToBeAdded.length > 0 && <h6 className="text-center">{t('Activated')}</h6>}
                            {scopesToBeAdded.map((scope) => (
                                <p className="p-0 m-0" style={{ fontStyle: 'italic', fontSize: '0.9rem' }} key={scope}>
                                    {scope}
                                </p>
                            ))}
                            {scopesToBeRemoved.length > 0 && (
                                <h6
                                    className={`text-center w-100 ${scopesToBeAdded.length > 0 ? 'mt-2 pt-3' : ''}`}
                                    style={{ borderTop: scopesToBeAdded.length > 0 ? '1px solid #181818' : 'none' }}
                                >
                                    {t('Deactivated')}
                                </h6>
                            )}
                            {scopesToBeRemoved.map((scope) => (
                                <p className="p-0 m-0" style={{ fontStyle: 'italic', fontSize: '0.9rem' }} key={scope}>
                                    {scope}
                                </p>
                            ))}
                        </div>
                        <div className="d-flex flex-column align-items-center ">
                            <h3 className="p-0 m-0 mb-1">Groups</h3>
                            {groupsToBeAdded.length > 0 && <h6 className="text-center">{t('Activated')}</h6>}
                            {groupsToBeAdded.map((scope) => (
                                <p className="p-0 m-0" style={{ fontStyle: 'italic', fontSize: '0.9rem' }} key={scope}>
                                    {scope}
                                </p>
                            ))}
                            {groupsToBeRemoved.length > 0 && (
                                <h6
                                    className={`text-center w-100 ${groupsToBeAdded.length > 0 ? 'mt-2 pt-3' : ''}`}
                                    style={{ borderTop: groupsToBeAdded.length > 0 ? '1px solid #181818' : 'none' }}
                                >
                                    {t('Deactivated')}
                                </h6>
                            )}
                            {groupsToBeRemoved.map((scope) => (
                                <p className="p-0 m-0" style={{ fontStyle: 'italic', fontSize: '0.9rem' }} key={scope}>
                                    {scope}
                                </p>
                            ))}
                        </div>
                        <div className="d-flex flex-column align-items-center ">
                            <h3 className="p-0 m-0 mb-1">Customers</h3>
                            {customersToBeAdded.length > 0 && <h6 className="text-center">{t('Activated')}</h6>}
                            {customersToBeAdded.map((customer) => (
                                <p
                                    className="p-0 m-0"
                                    style={{ fontStyle: 'italic', fontSize: '0.9rem' }}
                                    key={customer}
                                >
                                    {allUserCustomers[customer].name}
                                </p>
                            ))}
                            {customersToBeRemoved.length > 0 && (
                                <h6
                                    className={`text-center w-100 ${customersToBeAdded.length > 0 ? 'mt-2 pt-3' : ''}`}
                                    style={{
                                        borderTop: customersToBeAdded.length > 0 ? '1px solid #181818' : 'none',
                                    }}
                                >
                                    {t('Deactivated')}
                                </h6>
                            )}
                            {customersToBeRemoved.map((customer) => (
                                <p
                                    className="p-0 m-0"
                                    style={{ fontStyle: 'italic', fontSize: '0.9rem' }}
                                    key={customer}
                                >
                                    {allUserCustomers[customer].name}
                                </p>
                            ))}
                        </div>
                    </div>
                )}
            </CModalBody>

            <CModalFooter>
                <CButton
                    color="light"
                    onClick={() => {
                        setVisible(false);
                    }}
                >
                    {t('Cancel')}
                </CButton>
                <CButton onClick={confirmChanges ? save : showConfirmChanges} color="secondary">
                    {!confirmChanges ? t('Save') : t('Save Changes')}
                </CButton>
            </CModalFooter>
        </CModal>
    );
};

export default EditUserPopup;
