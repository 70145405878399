import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getDataAssetsData } from '@/api/dcpatient/DcPatientCalls';

import Chart from '@/components/DataAssets/Chart';
import Kpi from '@/components/DataAssets/Kpi';

import AWSContext from '@/context/AWSContext';

import { usePrismicStore } from '@/zustandStore';

import FailedFallback from '../pages/failed/FailedFallback';
import LoadingPage from '../pages/loading/LoadingPage';

const barChartMockData = {
    labels: [
        '0 - 9',
        '10 - 19',
        '20 - 29',
        '30 - 39',
        '40 - 49',
        '50 - 59',
        '60 - 69',
        '70 - 79',
        '80 - 89',
        '90 - 100+',
    ],
    label: 'Patienten',
};

const DataAssets = () => {
    const { userToken } = useContext(AWSContext);
    const { t } = useTranslation(['common']);
    const [loading, setLoading] = useState(false);
    const [hasFailed, setHasFailed] = useState(false);
    const labels = usePrismicStore((state) => state.labels);

    const [data, setData] = useState('');
    const currentCustomer = usePrismicStore((state) => state.currentCustomer);
    const getDataAssets = async () => {
        setLoading(true);
        const [assetsLoaded, assets] = await getDataAssetsData(userToken, import.meta.env.VITE_DC_INSTANCE0_ID);
        if (assets?.length > 0) {
            const convertedData = assets.reduce((acc, current) => {
                acc[current.name] = current.data;
                return acc;
            }, {});
            setData(convertedData);
        }
        setHasFailed(!assetsLoaded);
        setLoading(false);
    };

    useEffect(() => {
        if (currentCustomer) {
            getDataAssets();
        }
    }, [currentCustomer]);

    if (loading) return <LoadingPage />;
    if (hasFailed) return <FailedFallback reloadData={() => {}} />;

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <h1 style={{ fontSize: '1.75rem' }}>{labels?.data?.data_asset_dashboard_header[0]?.text}</h1>
            </div>
            <CCard>
                <CRow className="justify-content-evenly p-5">
                    <CCol xs={3} className="text-center">
                        <Kpi
                            number={data?.global?.patients_last_year ?? 0}
                            text={`(1) ${labels?.data?.annual_patient_contacts[0]?.text}`}
                        />
                    </CCol>
                    <CCol xs={3} className="text-center">
                        <Kpi
                            number={data?.global?.all_patients ?? 0}
                            text={`(2) ${labels?.data?.overall_patients[0]?.text}`}
                        />
                    </CCol>
                    <CCol xs={3} className="text-center">
                        <Kpi
                            number={data?.global?.active_patients ?? 0}
                            text={`(3) ${labels?.data?.overall_active_patients[0]?.text}`}
                        />
                    </CCol>
                    <CCol xs={3} className="text-center">
                        <Kpi
                            number={data?.global?.eterno_profiles ?? 0}
                            text={`(4) ${labels?.data?.patients_with_eterno_profile[0]?.text}`}
                        />
                    </CCol>
                </CRow>
                <CRow className="justify-content-evenly p-5">
                    <CCol xs={8}>
                        <CCard className="mb-4">
                            <CCardHeader style={{ backgroundColor: 'transparent' }}>
                                {`(5) ${labels?.data?.patients_by_age[0]?.text}`}
                            </CCardHeader>
                            <CCardBody>
                                <Chart
                                    chartType="bar"
                                    labels={barChartMockData.labels}
                                    label={barChartMockData.label}
                                    data={[
                                        data?.patients_by_age?.group_0_9,
                                        data?.patients_by_age?.group_10_19,
                                        data?.patients_by_age?.group_20_29,
                                        data?.patients_by_age?.group_30_39,
                                        data?.patients_by_age?.group_40_49,
                                        data?.patients_by_age?.group_50_59,
                                        data?.patients_by_age?.group_60_69,
                                        data?.patients_by_age?.group_70_79,
                                        data?.patients_by_age?.group_80_89,
                                        data?.patients_by_age?.['group_+100'],
                                    ]}
                                    title="Jahre"
                                    subTitle="Patienten"
                                />
                            </CCardBody>
                        </CCard>
                    </CCol>
                    <CCol xs={4}>
                        <CCard className="mb-4">
                            <CCardHeader style={{ backgroundColor: 'transparent' }}>
                                {`(6) ${labels?.data?.patients_by_gender[0]?.text}`}
                            </CCardHeader>
                            <CCardBody>
                                <Chart
                                    chartType="doughnut"
                                    labels={[t('Male'), t('Female'), t('Other')]}
                                    label={barChartMockData.label}
                                    data={[
                                        data?.patients_by_gender?.MALE,
                                        data?.patients_by_gender?.FEMALE,
                                        data?.patients_by_gender?.OTHER,
                                    ]}
                                    backgroundColor={['#41B883', '#E46651', '#00D8FF', '#DD1B16']}
                                />
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
                <CRow className="justify-content-evenly p-5 pt-0">
                    <CCol xs={12}>
                        <CCard className="mb-4">
                            <CCardHeader style={{ backgroundColor: 'transparent' }}>
                                {`(7) ${labels?.data?.patients_by_specialty[0]?.text}`}
                            </CCardHeader>
                            <CCardBody>
                                <Chart
                                    chartType="bar"
                                    labels={data && Object.keys(data?.patients_by_speciality)}
                                    label={barChartMockData.label}
                                    data={
                                        data &&
                                        Object.values(data?.patients_by_speciality)?.filter((value) =>
                                            Number.isInteger(value)
                                        )
                                    }
                                />
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </CCard>
        </div>
    );
};

export default DataAssets;
