import {
    CLEAR_CHECK_IN_SUCCESS,
    CLEAR_ERRORS,
    CLEAR_OBJECTS,
    GET_ALL_APPOINTMENTS_FAIL,
    GET_ALL_APPOINTMENTS_SUCCESS,
    GET_ALL_TODAYS_APPOINTMENTS_FAIL,
    GET_ALL_TODAYS_APPOINTMENTS_SUCCESS,
    SET_TO_DEFAULT,
    UPDATE_USER_BY_NUMBER_FAIL,
    UPDATE_USER_BY_NUMBER_SUCCESS,
} from '@/store/actions';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    switch (action.type) {
        case GET_ALL_TODAYS_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                todaysAppointments: action.payload,
                successMessage: action.successMessage,
            };
        case GET_ALL_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                appointments: action.payload,
                successMessage: action.successMessage,
            };
        case UPDATE_USER_BY_NUMBER_SUCCESS:
            return {
                ...state,
                checkInDetails: action.payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                successMessage: null,
            };
        case CLEAR_CHECK_IN_SUCCESS:
            return {
                ...state,
                checkInDetails: null,
            };

        case SET_TO_DEFAULT:
            return {
                ...state,
                appointments: [],
                todaysAppointments: [],
                checkInDetails: null,
                error: null,
                successMessage: null,
            };
        case CLEAR_OBJECTS:
            return {
                ...state,
                singlePatientRecord: null,
                patientAccountDetails: null,
                patientAppointmentDetails: null,
                patientHealthInsuranceDetails: null,
                patientAddressDetails: null,
            };
        case GET_ALL_TODAYS_APPOINTMENTS_FAIL:
        case GET_ALL_APPOINTMENTS_FAIL:
        case UPDATE_USER_BY_NUMBER_FAIL:
            return {
                ...state,
                error: action.payload,
                loadings: false,
            };
        default:
            return state;
    }
};
