import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react';
import { uniqBy } from 'es-toolkit';
import { useTranslation } from 'react-i18next';

import { useAnamnesisBuilderStore, usePrismicStore } from '@/zustandStore.js';

const AppointmentTypeSelectionModal = ({
    showAppointmentTypeSelection,
    setShowAppointmentTypeSelection,
    setSelectedAppointmentTypes,
    selectedAppointmentTypes,
    selectedProfessional,
}) => {
    const { t } = useTranslation();
    const appointmentTypes = usePrismicStore((state) => state.appointmentTypes);
    const cachedProfessionals = useAnamnesisBuilderStore((state) => state.cachedProfessionals);

    const updateSelectedAppointmentTypes = (prismicKey, dcScheduleTypeId) => {
        if (selectedAppointmentTypes[prismicKey]) {
            const newSelectedAppointmentTypes = { ...selectedAppointmentTypes };
            delete newSelectedAppointmentTypes[prismicKey];
            setSelectedAppointmentTypes(newSelectedAppointmentTypes);
        } else if (
            uniqBy(
                cachedProfessionals[selectedProfessional].dc_schedule_type_mappings.filter(
                    (mapping) =>
                        mapping.prismic_key && mapping.prismic_key !== '-' && appointmentTypes[mapping.prismic_key]
                ),
                (mapping) => mapping.prismic_key
            ).length ===
            Object.keys(selectedAppointmentTypes).length + 1
        ) {
            setSelectedAppointmentTypes({});
        } else {
            setSelectedAppointmentTypes({ ...selectedAppointmentTypes, [prismicKey]: dcScheduleTypeId });
        }
    };

    return (
        <CModal
            scrollable
            visible={showAppointmentTypeSelection}
            onClose={() => setShowAppointmentTypeSelection(false)}
        >
            <CModalHeader>Terminarten auswählen</CModalHeader>
            <CModalBody className="d-flex flex-column gap-2">
                {selectedProfessional &&
                    selectedProfessional !== '-' &&
                    cachedProfessionals[selectedProfessional] &&
                    uniqBy(
                        cachedProfessionals[selectedProfessional].dc_schedule_type_mappings,
                        (mapping) => mapping.prismic_key
                    ).map(
                        (mapping) =>
                            mapping.prismic_key &&
                            mapping.prismic_key !== '-' &&
                            appointmentTypes[mapping.prismic_key] && (
                                <div
                                    style={{
                                        borderRadius: '8px',
                                        border:
                                            selectedAppointmentTypes[mapping.prismic_key] ||
                                            Object.keys(selectedAppointmentTypes).length === 0
                                                ? '2px solid #FFC877'
                                                : '2px solid #D5D5D5',
                                        padding: '1rem',
                                        cursor: 'pointer',
                                        fontSize: '0.9rem',
                                    }}
                                    onClick={() =>
                                        updateSelectedAppointmentTypes(mapping.prismic_key, mapping.dc_schedule_type_id)
                                    }
                                >
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h6
                                            style={{
                                                fontSize: '0.9rem',
                                                maxWidth: '100%',
                                            }}
                                            className="p-0 m-0"
                                        >
                                            {appointmentTypes[mapping.prismic_key].name[0].text || '-'}
                                        </h6>
                                    </div>
                                </div>
                            )
                    )}
            </CModalBody>

            <CModalFooter>
                <CButton onClick={() => setShowAppointmentTypeSelection(false)} color="secondary">
                    {t('Close')}
                </CButton>
            </CModalFooter>
        </CModal>
    );
};

export default AppointmentTypeSelectionModal;
