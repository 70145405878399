import { CButton, CFormSelect } from '@coreui/react';
import { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useSWRImmutable from 'swr/immutable';

import AWSContext from '@/context/AWSContext';

import useAuth from '@/hooks/useAuth';

import { AccessRequestStatus } from '@/utils/constants';
import { getAccessRequest, getPrismicText, isLocalEnvironment, sendAccessRequest } from '@/utils/helpers';

import { useAdminConfigStore, usePrismicStore } from '@/zustandStore';

import LoadingPage from '../loading/LoadingPage';

const NoRoutesFallback = () => {
    const { user } = useAuth();
    const awsContext = useContext(AWSContext);
    const { userToken } = awsContext;
    const { t } = useTranslation();
    const selectRef = useRef();
    const accessRequest = useAdminConfigStore((state) => state.accessRequest);
    const customers = useAdminConfigStore((state) => state.customers);
    const locationHubs = usePrismicStore((state) => state.locationHubs);
    const prismicData = usePrismicStore((state) => state.accessRequests);
    const locationsObj = useMemo(
        () =>
            locationHubs?.reduce(
                (acc, hub) => ({
                    ...acc,
                    [hub.id]: { ...hub.data, prismicId: hub.id },
                }),
                {}
            ) ?? [],
        [locationHubs]
    );

    const isEternoUser = useMemo(
        () =>
            isLocalEnvironment()
                ? user.email.toLowerCase().endsWith('eterno.health') || user.id.toLowerCase().startsWith('google_')
                : user.email.toLowerCase().endsWith('eterno.health') && user.id.toLowerCase().startsWith('google_'),
        [user]
    );

    const isEternoDomain = useMemo(
        () => window.location.hostname.includes(import.meta.env.VITE_ETERNO_DOMAIN) || isLocalEnvironment(),
        [window]
    );

    const locationOptions = useMemo(() => {
        if (!isEternoUser || !locationsObj || !customers) return [];

        const eternoCustomer = Object.values(customers).find((customer) => customer.is_eterno_customer);
        if (!eternoCustomer) return [];

        const options = [];
        eternoCustomer.locations.forEach(({ location }) => {
            if (!location || !locationsObj[location.id]) return;
            options.push(locationsObj[location.id]);
        });

        return options;
    }, [customers, locationsObj]);

    const { isLoading } = useSWRImmutable(
        !accessRequest
            ? {
                  key: 'access-request',
                  userToken: userToken,
              }
            : null,
        async () => getAccessRequest(userToken)
    );

    const requestAccess = async () => {
        const selectedLocation = locationsObj[selectRef.current.value];
        const { instanceConfigs } = usePrismicStore.getState();

        const obj = {
            location_key: selectedLocation.group_key[0].text,
            customer_id: import.meta.env.VITE_ETERNO_CUSTOMER_ID,
            instance_id: instanceConfigs[selectRef.current.value].instance_id[0].text,
        };

        const isSaved = await sendAccessRequest(userToken, obj);

        if (!isSaved) return;

        await getAccessRequest(userToken);
    };

    const accessRequestText = useMemo(() => {
        if (!accessRequest || !prismicData) return null;

        switch (accessRequest.status) {
            case AccessRequestStatus.ACCEPTED:
                return (
                    <>
                        <p className="m-0 p-0">{getPrismicText(prismicData.approved_request)}</p>
                        <p className="m-0 p-0">{getPrismicText(prismicData.activate_access)}</p>
                    </>
                );
            case AccessRequestStatus.DECLINED: {
                const text = getPrismicText(prismicData.contact_support);

                return (
                    <>
                        <p className="m-0 p-0">{getPrismicText(prismicData.denied_request)}</p>
                        <p className="m-0 p-0">
                            {text.split('{email_placeholder}')[0]}
                            <a href="mailto:tech.admin@eterno.health">tech.admin@eterno.health</a>
                            {text.split('{email_placeholder}')[1]}
                        </p>
                    </>
                );
            }
            case AccessRequestStatus.PENDING:
                return (
                    <>
                        <p className="m-0 p-0">{getPrismicText(prismicData.received_request)}</p>
                        <p className="m-0 p-0">{getPrismicText(prismicData.wait_for_processing)}</p>
                    </>
                );
            default:
                return null;
        }
    }, [accessRequest, prismicData]);

    if (isLoading || !prismicData) return <LoadingPage />;

    if (isEternoUser && isEternoDomain && !accessRequest) {
        return (
            <div
                style={{ height: '70vh' }}
                className="d-flex flex-column align-items-center justify-content-center gap-4 text-center"
            >
                <h2 className="m-0 p-0">{getPrismicText(prismicData.welcome_heading)}</h2>
                <p className="m-0 p-0">{getPrismicText(prismicData.request_access)}</p>
                <div style={{ width: '12rem' }}>
                    <CFormSelect
                        ref={selectRef}
                        options={
                            locationOptions?.map((option) => ({
                                label: option.title[0].text,
                                value: option.prismicId,
                            })) ?? []
                        }
                    />
                </div>
                <CButton color="secondary" onClick={requestAccess}>
                    {getPrismicText(prismicData.request_access_cta)}
                </CButton>
            </div>
        );
    }

    if (isEternoUser && isEternoDomain && accessRequest) {
        return (
            <div
                style={{ height: '70vh' }}
                className="d-flex flex-column align-items-center justify-content-center gap-4 text-center"
            >
                <h2 className="m-0 p-0">{getPrismicText(prismicData.welcome_heading)}</h2>
                {accessRequestText}
            </div>
        );
    }

    return (
        <div
            style={{ height: '70vh' }}
            className="d-flex flex-column align-items-center justify-content-center gap-4 text-center"
        >
            <h4 className="w-50" style={{ lineHeight: '2.5rem' }}>
                {t('NoRoutes')}
            </h4>
            <p className="w-50">
                {t('FirstLoginMessage')} <a href="mailto:tech.admin@eterno.health">tech.admin@eterno.health</a>
            </p>
        </div>
    );
};

export default NoRoutesFallback;
