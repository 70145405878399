import {
    CLEAR_ERRORS,
    CREATE_FORM_DATA_FAIL,
    CREATE_FORM_DATA_SUCCESS,
    GET_ALL_CACHED_USER_DATA_FAIL,
    GET_ALL_CACHED_USER_DATA_SUCCESS,
    SET_TO_DEFAULT,
} from '@/store/actions';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    switch (action.type) {
        case GET_ALL_CACHED_USER_DATA_SUCCESS:
            return {
                ...state,
                cachedUserData: action.payload,
                successMessage: action.successMessage,
            };
        case CREATE_FORM_DATA_SUCCESS:
            return {
                ...state,
                formSuccessMessage: action.formSuccessMessage,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                successMessage: null,
                formSuccessMessage: null,
            };
        case SET_TO_DEFAULT:
            return {
                ...state,
                cachedUserData: [],
                checkInDetails: null,
                error: null,
                successMessage: null,
                formSuccessMessage: null,
            };
        case GET_ALL_CACHED_USER_DATA_FAIL:
        case CREATE_FORM_DATA_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};
