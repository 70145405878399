/* eslint-disable import/no-anonymous-default-export */
import {
    CLEAR_ERRORS,
    CLEAR_OBJECTS,
    GET_PAT_USER_BY_ID_HEALTH_PROFILE_FAIL,
    GET_PAT_USER_BY_ID_HEALTH_PROFILE_SUCCESS,
    SET_TO_DEFAULT,
} from '@/store/actions';

export default (state, action) => {
    switch (action.type) {
        case GET_PAT_USER_BY_ID_HEALTH_PROFILE_SUCCESS:
            return {
                ...state,
                singleUserHealthProfile: action.payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                successMessage: null,
            };
        case SET_TO_DEFAULT:
            return {
                ...state,
                dcPatients: [],
                error: null,
                successMessage: null,
            };
        case CLEAR_OBJECTS:
            return {
                singleUserHealthProfile: null,
            };
        case GET_PAT_USER_BY_ID_HEALTH_PROFILE_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};
