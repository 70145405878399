import {
    CButton,
    CCardTitle,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow,
} from '@coreui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocationStore, useTodaysAppointmentsStore } from '@/zustandStore';

const LegacyManualCheckinModal = ({
    selectedAppointment,
    showLegacyManualCheckinModal,
    manualCheckinPatientName,
    setManualCheckinPatientName,
    startCheckin,
    appointment,
}) => {
    const { t } = useTranslation();
    const patients = useTodaysAppointmentsStore((state) => state.patients);
    const appointmentTicketNumber = useTodaysAppointmentsStore((state) => state.appointmentTicketNumber);
    const selectedLocation = useLocationStore((state) => state.selectedLocation);

    useEffect(() => {
        if (showLegacyManualCheckinModal) {
            const patientName = selectedAppointment.user_id ? patients[selectedAppointment.user_id]?.name : '';

            setManualCheckinPatientName(
                selectedAppointment.data.patient_name ||
                    (patientName?.first ? `${patientName.first} ${patientName.last}` : '')
            );
        }
    }, [showLegacyManualCheckinModal]);

    return (
        <CModal
            scrollable
            visible={showLegacyManualCheckinModal}
            onClose={() => useTodaysAppointmentsStore.setState({ showLegacyManualCheckinModal: false })}
        >
            <CModalHeader />
            <CModalBody lg={12} md={12} sm={12} xs={12}>
                <CContainer style={{ marginTop: 10, marginBottom: 10 }}>
                    <h3>{t('Manual Checkin')}</h3>
                    <CRow xs={{ gutterY: 0 }}>
                        <CCol lg={12} md={12} sm={12} xs={12}>
                            <CCardTitle style={{ fontSize: 14 }}> {t('Please enter the patient`s name')} </CCardTitle>
                        </CCol>
                        <CCol lg={12} md={12} sm={12} xs={12}>
                            <CForm>
                                <div className="mb-3">
                                    <CFormInput
                                        type="text"
                                        value={manualCheckinPatientName}
                                        placeholder={`${t('Patient name')}`}
                                        name="name"
                                        onChange={(e) => setManualCheckinPatientName(e.target.value)}
                                    />
                                </div>
                            </CForm>
                        </CCol>
                        {selectedLocation?.config.is_patient_call_system_active && (
                            <>
                                <CCol lg={12} md={12} sm={12} xs={12}>
                                    <CCardTitle style={{ fontSize: 14 }}>
                                        {' '}
                                        {t('Assign the visitor a unique code to check-in')}{' '}
                                    </CCardTitle>
                                </CCol>
                                <CCol lg={12} md={12} sm={12} xs={12}>
                                    <CForm>
                                        <div className="mb-3">
                                            <CFormInput
                                                type="text"
                                                value={appointmentTicketNumber[appointment?.id]}
                                                placeholder={`${t('Call number')}`}
                                                name="number"
                                                onChange={(e) =>
                                                    useTodaysAppointmentsStore.setState({
                                                        appointmentTicketNumber: {
                                                            ...appointmentTicketNumber,
                                                            [appointment?.id]: e.target.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                    </CForm>
                                </CCol>
                            </>
                        )}
                    </CRow>
                </CContainer>
            </CModalBody>

            <CModalFooter>
                <CButton
                    color="light"
                    onClick={() => useTodaysAppointmentsStore.setState({ showLegacyManualCheckinModal: false })}
                >
                    {t('Close')}
                </CButton>
                <CButton
                    disabled={
                        (selectedLocation?.config.is_patient_call_system_active &&
                            !appointmentTicketNumber[appointment?.id]) ||
                        !manualCheckinPatientName
                    }
                    onClick={() => {
                        startCheckin();
                        useTodaysAppointmentsStore.setState({ showLegacyManualCheckinModal: false });
                    }}
                    color="secondary"
                >
                    {t('Save Changes')}
                </CButton>
            </CModalFooter>
        </CModal>
    );
};

export default LegacyManualCheckinModal;
