import { CButton, CCardTitle, CCol, CContainer, CModal, CModalBody, CModalFooter, CRow } from '@coreui/react';
import { useTranslation } from 'react-i18next';

import { useTodaysAppointmentsStore } from '@/zustandStore';

const AppointmentCodeModal = () => {
    const { t } = useTranslation();
    const code = useTodaysAppointmentsStore((state) => state.code);

    return (
        <CModal scrollable visible={!!code} onClose={() => useTodaysAppointmentsStore.setState({ code: null })}>
            <CModalBody lg={12} md={12} sm={12} xs={12}>
                <CContainer style={{ marginTop: 10, marginBottom: 10 }}>
                    <h3 className="modal-heading">Zugangscode</h3>

                    <CRow xs={{ gutterY: 0 }}>
                        <CCol lg={12} md={12} sm={12} xs={12}>
                            <CCardTitle className="modal-subheading">
                                Bitte gib diesen Code auf dem ipad ein, um die Formulare für diesen Patienten zu öffnen.
                            </CCardTitle>
                        </CCol>

                        <CCol lg={12} md={12} sm={12} xs={12}>
                            <CCardTitle className="modal-code"> {code}</CCardTitle>
                        </CCol>
                    </CRow>
                </CContainer>
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => useTodaysAppointmentsStore.setState({ code: null })}>
                    {t('Close')}
                </CButton>
            </CModalFooter>
        </CModal>
    );
};

export default AppointmentCodeModal;
