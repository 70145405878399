import { CCard, CCardBody, CCardTitle, CCol, CForm, CFormInput, CFormSelect } from '@coreui/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PatientContext from '@/context/patient/PatientContext';

const PatientBasicInfoModal = ({ func }) => {
    const { t } = useTranslation(['common']);
    const patientContext = useContext(PatientContext);
    const { singlePatientRecord, successMessage, error } = patientContext;

    const [patientBasicInfo, setPatientBasicInfo] = useState({
        name: {
            first: '',
            last: '',
        },
        gender: '',
        date_of_birth: '',
    });
    func(patientBasicInfo);
    const patientBasicOnChange = (e) => {
        setPatientBasicInfo({
            ...patientBasicInfo,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (singlePatientRecord !== null) {
            setPatientBasicInfo(singlePatientRecord);
        }
    }, [singlePatientRecord, successMessage, error]);

    return patientBasicInfo !== null &&
        (JSON.stringify(patientBasicInfo) === '{}') !== true &&
        singlePatientRecord !== null ? (
        <>
            <CCol lg={12} md={12} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}> {t('Basic Profile Data')} </CCardTitle>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}> {t('First Name')} </CCardTitle>
                        <CForm>
                            <div className="mb-3">
                                <CFormInput
                                    type="text"
                                    value={patientBasicInfo.name.first}
                                    name="first"
                                    onChange={(event) => {
                                        setPatientBasicInfo((prevState) => ({
                                            ...prevState,
                                            name: { ...prevState.name, first: event.target.value },
                                        }));
                                    }}
                                />
                            </div>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}> {t('Last Name')} </CCardTitle>
                        <CForm>
                            <div className="mb-3">
                                <CFormInput
                                    type="text"
                                    value={patientBasicInfo.name.last}
                                    name="last"
                                    onChange={(event) => {
                                        setPatientBasicInfo((prevState) => ({
                                            ...prevState,
                                            name: { ...prevState.name, last: event.target.value },
                                        }));
                                    }}
                                />
                            </div>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}>{t('Gender')}</CCardTitle>
                        <CFormSelect
                            aria-label="Search Dropdown"
                            options={[
                                `${t('Status')}`,
                                { label: `${t('Male')}`, value: `${t('Male')}` },
                                { label: `${t('Female')}`, value: `${t('Female')}` },
                                { label: `${t('Other')}`, value: `${t('Other')}` },
                            ]}
                            name="gender"
                            value={patientBasicInfo.gender}
                            onChange={patientBasicOnChange}
                        />
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg={6} md={6} sm={12} xs={12}>
                <CCard>
                    <CCardBody>
                        <CCardTitle style={{ fontSize: 16 }}> {t('Date Of Birth')} </CCardTitle>
                        <CForm>
                            <div className="mb-3" />
                        </CForm>
                        <div data-coreui-locale="en-US" data-coreui-toggle="date-picker" id="datePicker1" />
                    </CCardBody>
                </CCard>
            </CCol>
        </>
    ) : (
        ''
    );
};

export default PatientBasicInfoModal;
