import { cilPencil, cilWarning } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CAccordion, CButton, CCard, CFormInput } from '@coreui/react';
import { useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';
import { v4 as uuidv4 } from 'uuid';

import { getFormTemplates } from '@/api/professional/ProfessionalCalls';

import AppointmentTypeSelectionModal from '@/components/AnamnesisBuilder/AppointmentTypeSelectionModal';
import { Category } from '@/components/AnamnesisBuilder/Category';
import CustomQuestionsCheckModal from '@/components/AnamnesisBuilder/CustomQuestionsCheckModal';
import QuestionSelectionModal from '@/components/AnamnesisBuilder/QuestionSelectionModal';
import ProfessionalSelection from '@/components/ProfessionalSelection';

import AWSContext from '@/context/AWSContext';

import { QuestionType } from '@/utils/constants';
import { InsuranceType, getCachedUserData, updateForm, useQuery } from '@/utils/helpers';

import { useAnamnesisBuilderStore, usePrismicStore, useTodaysAppointmentsStore } from '@/zustandStore';

import FailedFallback from '../pages/failed/FailedFallback';
import LoadingPage from '../pages/loading/LoadingPage';

const personalInfoCategoryData = {
    id: 'personal-info',
    germanName: 'Persönliche Informationen',
    englishName: 'Personal Information',
    questions: [
        {
            key: 'first-name',
            is_required: true,
            title: [
                {
                    text: 'Vorname',
                },
            ],
            question_type: QuestionType.TEXTFIELD,
        },
        {
            key: 'last-name',
            is_required: true,
            title: [
                {
                    text: 'Nachname',
                },
            ],
            question_type: QuestionType.TEXTFIELD,
        },
        {
            key: 'dob',
            is_required: true,
            title: [
                {
                    text: 'Geburtsdatum',
                },
            ],
            question_type: QuestionType.DATE_FIELD,
        },
        {
            key: 'email',
            is_required: true,
            title: [
                {
                    text: 'Email-Adresse',
                },
            ],
            question_type: QuestionType.TEXTFIELD,
        },
    ],
};

const AnamnesisBuilder = () => {
    const { userToken } = useContext(AWSContext);
    const query = useQuery();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [isTemplateLoaded, setIsTemplateLoaded] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [hasFailed, setHasFailed] = useState(false);
    const [germanFormName, setGermanFormName] = useState('');
    const [englishFormName, setEnglishFormName] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showQuestionSelection, setShowQuestionSelection] = useState(false);
    const [showCustomQuestionsCheck, setShowCustomQuestionsCheck] = useState(false);
    const [selectedProfessional, setSelectedProfessional] = useState(null);
    const [selectedAppointmentTypes, setSelectedAppointmentTypes] = useState({});
    const [showAppointmentTypeSelection, setShowAppointmentTypeSelection] = useState(false);
    const professionalMappings = useTodaysAppointmentsStore((state) => state.professionalMappings);
    const problems = useAnamnesisBuilderStore((state) => state.problems);
    const appointmentTypes = usePrismicStore((state) => state.appointmentTypes);
    const currentCustomer = usePrismicStore((state) => state.currentCustomer);
    const cachedProfessionals = useAnamnesisBuilderStore((state) => state.cachedProfessionals);
    const invalidQuestions = useAnamnesisBuilderStore((state) => state.invalidQuestions);
    const questions = usePrismicStore((state) => state.questions);

    const containsInvalidQuestions = useMemo(
        () => Object.values(invalidQuestions).some((category = {}) => Object.keys(category).length > 0),
        [invalidQuestions]
    );

    const isSaveButtonDisabled = useMemo(
        () =>
            !germanFormName ||
            !englishFormName ||
            !selectedProfessional ||
            !professionalMappings?.[selectedProfessional] ||
            categories.length === 0 ||
            !categories.every(
                (category) => category.germanName && category.englishName && category.questions.length > 0
            ) ||
            containsInvalidQuestions,
        [
            germanFormName,
            englishFormName,
            selectedProfessional,
            professionalMappings,
            categories,
            containsInvalidQuestions,
        ]
    );

    const loadProfessionalData = async () => {
        const response = await getCachedUserData(userToken);

        if (!response.data?.data) return;

        const { professionalMappingsObj, cachedProfessionalsObj } = response.data.data.linked_users.reduce(
            (obj, cachedUser) => {
                if (!cachedUser.dc_user_mapping.prismic_key) return obj;
                // eslint-disable-next-line no-param-reassign
                obj.professionalMappingsObj[cachedUser.dc_user_mapping.prismic_key] = cachedUser.dc_user_mapping;
                // eslint-disable-next-line no-param-reassign
                obj.cachedProfessionalsObj[cachedUser.dc_user_mapping.prismic_key] = cachedUser;

                return obj;
            },
            { professionalMappingsObj: {}, cachedProfessionalsObj: {} }
        );

        useTodaysAppointmentsStore.setState({
            professionalMappings: professionalMappingsObj,
        });
        useAnamnesisBuilderStore.setState({
            cachedProfessionals: cachedProfessionalsObj,
        });
    };

    useSWRImmutable(
        !cachedProfessionals || currentCustomer?.customer_id?.[0]?.text
            ? ['anamnesis-builder', currentCustomer?.customer_id?.[0]?.text]
            : null,
        loadProfessionalData
    );

    const setInvalidQuestions = (categoriesArray) => {
        const newInvalidQuestions = {};

        // TODO use reduce
        categoriesArray.forEach((category) => {
            category.questions.forEach((question) => {
                if (questions[question.key]) return;

                newInvalidQuestions[category.position] = {
                    ...newInvalidQuestions[category.position],
                    [question.key]: true,
                };
            });
        });

        useAnamnesisBuilderStore.setState({ invalidQuestions: newInvalidQuestions });
    };

    const loadTemplate = async () => {
        const templateId = query.get('template_id');
        const professionalId = query.get('professional_id');

        const response = await getFormTemplates(userToken, professionalId);

        const template = response.data?.data?.find((entry) => entry.id === templateId);

        if (!template) {
            toast.error('Form Template nicht gefunden!');
            return;
        }

        setGermanFormName(template.form_name_de);
        setEnglishFormName(template.form_name_en);
        setSelectedProfessional(template.professional.key);
        setCategories(
            template.content.categories.map((category) => ({
                id: category.position,
                germanName: category.name_de,
                englishName: category.name_en,
                questions: category.questions,
            }))
        );
        setSelectedAppointmentTypes(
            template.configuration.appointment_types.reduce((obj, appointmentType) => {
                // eslint-disable-next-line no-param-reassign
                obj[appointmentType.key] = appointmentType.id;
                return obj;
            }, {})
        );
        setInvalidQuestions(template.content.categories);
        setIsTemplateLoaded(true);
    };

    useEffect(() => {
        if (query.get('template_id') && query.get('professional_id')) loadTemplate();
    }, []);

    useEffect(() => {
        if (
            professionalMappings &&
            cachedProfessionals &&
            appointmentTypes &&
            (!query.get('template_id') || !query.get('professional_id') || isTemplateLoaded)
        ) {
            setLoading(false);
        }
    }, [professionalMappings, isTemplateLoaded, appointmentTypes, cachedProfessionals]);

    const addCategory = () => {
        setCategories([
            ...categories,
            {
                id: uuidv4(),
                germanName: `Category ${categories.length + 1}`,
                englishName: `Category ${categories.length + 1}`,
                questions: [],
            },
        ]);
    };

    const updateCategoryName = (newName, key) => {
        setCategories((prevCategories) => {
            return prevCategories.map((category) => {
                if (category.id === selectedCategory) {
                    return {
                        ...category,
                        [key]: newName,
                    };
                }
                return category;
            });
        });
    };

    const addQuestion = (selectedQuestions, isRequired) => {
        setCategories((prevCategories) => {
            return prevCategories.map((category) => {
                if (category.id === selectedCategory) {
                    return {
                        ...category,
                        questions: [
                            ...category.questions,
                            ...Object.keys(selectedQuestions).map((questionKey) => ({
                                key: questionKey,
                                is_required: isRequired,
                            })),
                        ],
                    };
                }
                return category;
            });
        });
        setShowQuestionSelection(false);
    };

    const removeQuestion = (questionKey, categoryId) => {
        setCategories((prevCategories) => {
            return prevCategories.map((category) => {
                if (category.id === selectedCategory) {
                    return {
                        ...category,
                        questions: category.questions.filter((question) => question.key !== questionKey),
                    };
                }
                return category;
            });
        });

        if (!invalidQuestions[categoryId]?.[questionKey]) return;

        const newInvalidQuestions = { ...invalidQuestions };
        delete newInvalidQuestions[categoryId][questionKey];

        useAnamnesisBuilderStore.setState({ invalidQuestions: newInvalidQuestions });
    };

    const removeCategory = () => {
        setCategories((prevCategories) => prevCategories.filter((category) => category.id !== selectedCategory));
        setSelectedCategory(null);

        if (!invalidQuestions[selectedCategory]) return;

        const newInvalidQuestions = { ...invalidQuestions };
        delete newInvalidQuestions[selectedCategory];

        useAnamnesisBuilderStore.setState({ invalidQuestions: newInvalidQuestions });
    };

    const createNewForm = async () => {
        setLoading(true);

        try {
            const newForm = {
                professional_id: professionalMappings[selectedProfessional].user_id,
                is_custom: true,
                form_name_de: germanFormName,
                form_name_en: englishFormName,
                status: 'active',
                professional: {
                    id: professionalMappings[selectedProfessional].user_id,
                    key: selectedProfessional,
                },
                content: {
                    categories: categories.map((category, index) => ({
                        position: index + 1,
                        name_de: category.germanName,
                        name_en: category.englishName,
                        questions: category.questions.map((question, idx) => ({
                            ...question,
                            position: idx + 1,
                        })),
                    })),
                },
                configuration: {
                    frequency_type: 'once-per-professional',
                    appointment_types: Object.keys(selectedAppointmentTypes).map((key) => ({
                        id: selectedAppointmentTypes[key],
                        key: key,
                    })),
                    signature_type: 'digital',
                    insurance_types: [InsuranceType.PUBLIC, InsuranceType.PRIVATE, InsuranceType.SELF_PAYER],
                },
            };

            if (isTemplateLoaded) newForm.id = query.get('template_id');

            await updateForm(userToken, newForm);
            history.goBack();
        } catch (e) {
            console.debug(e);
            toast.error('Etwas ist schiefgelaufen!');
        }
        setLoading(false);
    };

    if (loading) return <LoadingPage />;

    if (hasFailed) return <FailedFallback reloadData={() => {}} />;

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <h1 style={{ fontSize: '1.75rem' }}>Ein neues Formular hinzufügen</h1>
                <div className="d-flex justify-content-center align-items-center gap-3">
                    {problems.length > 0 && (
                        <CButton
                            className="d-flex justify-content-center align-items-center gap-2"
                            onClick={() => setShowCustomQuestionsCheck(true)}
                            color="light"
                        >
                            <CIcon icon={cilWarning} className="text-danger" />
                            {`${problems.length} Probleme gefunden`}
                        </CButton>
                    )}
                    <CButton onClick={() => history.goBack()} color="light">
                        Abbrechen
                    </CButton>
                    <CButton disabled={isSaveButtonDisabled} onClick={createNewForm} color="secondary">
                        {!isTemplateLoaded ? 'Formular hinzufügen' : 'Formular aktualisieren'}
                    </CButton>
                </div>
            </div>
            <CCard className="mt-3 p-4 d-flex flex-column gap-3">
                <section className="d-flex flex-column gap-1">
                    <h4>Formularname</h4>
                    <div className="d-flex gap-3">
                        <CFormInput
                            style={{ width: '20rem' }}
                            placeholder="Formularname in Deutsch"
                            value={germanFormName}
                            onChange={(e) => setGermanFormName(e.target.value)}
                        />
                        <CFormInput
                            style={{ width: '20rem' }}
                            placeholder="Formularname in Englisch"
                            value={englishFormName}
                            onChange={(e) => setEnglishFormName(e.target.value)}
                        />
                    </div>
                </section>
                <section className="d-flex flex-column gap-1">
                    <h4>Professional</h4>
                    <div style={{ width: '20rem' }}>
                        <ProfessionalSelection
                            selectedProfessional={selectedProfessional}
                            setSelectedProfessional={setSelectedProfessional}
                            disabled={isTemplateLoaded}
                        />
                    </div>
                </section>
                {selectedProfessional && selectedProfessional !== '-' && cachedProfessionals[selectedProfessional] && (
                    <section className="d-flex flex-column gap-1">
                        <h4>Terminarten</h4>
                        <div style={{ width: '20rem' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                <h6 className="p-0 m-0">
                                    {Object.keys(selectedAppointmentTypes).length === 0
                                        ? 'Alle ausgewählt'
                                        : `${Object.keys(selectedAppointmentTypes).length} Terminarten ausgewählt`}
                                </h6>
                                <CIcon
                                    icon={cilPencil}
                                    size="md"
                                    onClick={() => setShowAppointmentTypeSelection(true)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        </div>
                    </section>
                )}
                <section className="d-flex flex-column gap-1">
                    <h4>Kategorien</h4>
                    <CAccordion
                        flush
                        style={{ borderTop: '2px solid #F5F5F5', borderBottom: '2px solid #F5F5F5' }}
                        className="my-3"
                    >
                        <Category
                            categoryData={personalInfoCategoryData}
                            setShowQuestionSelection={setShowQuestionSelection}
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            removeCategory={removeCategory}
                            removeQuestion={removeQuestion}
                            updateCategoryName={updateCategoryName}
                            isFixed
                        />
                        {categories.map((category) => (
                            <Category
                                key={category.id}
                                categoryData={category}
                                setShowQuestionSelection={setShowQuestionSelection}
                                selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}
                                removeCategory={removeCategory}
                                removeQuestion={removeQuestion}
                                updateCategoryName={updateCategoryName}
                                setCategories={setCategories}
                                categories={categories}
                            />
                        ))}
                    </CAccordion>
                    <div>
                        <CButton onClick={addCategory} color="secondary">
                            Kategorie hinzufügen
                        </CButton>
                    </div>
                </section>
            </CCard>
            <QuestionSelectionModal
                showQuestionSelection={showQuestionSelection}
                setShowQuestionSelection={setShowQuestionSelection}
                addQuestion={addQuestion}
            />
            <CustomQuestionsCheckModal
                showCustomQuestionsCheck={showCustomQuestionsCheck}
                setShowCustomQuestionsCheck={setShowCustomQuestionsCheck}
            />
            <AppointmentTypeSelectionModal
                showAppointmentTypeSelection={showAppointmentTypeSelection}
                setShowAppointmentTypeSelection={setShowAppointmentTypeSelection}
                setSelectedAppointmentTypes={setSelectedAppointmentTypes}
                selectedAppointmentTypes={selectedAppointmentTypes}
                selectedProfessional={selectedProfessional}
            />
        </div>
    );
};

export default AnamnesisBuilder;
