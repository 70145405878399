import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react';
import { useCallback, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import isEmail from 'validator/lib/isEmail';

import useAuth from '@/hooks/useAuth';
import useDynamicImport from '@/hooks/useDynamicImport';

import { createUser } from '@/utils/helpers';

const CreateUserPopup = ({ visible, setVisible, reloadUsers }) => {
    const { t } = useTranslation();
    const { userToken } = useAuth();
    const [tagifyEmails, setTagifyEmails] = useState([]);
    const [confirmChanges, setConfirmChanges] = useState(false);
    const tagifyRef = useRef();
    const [wereUsersInserted, setWereUsersInserted] = useState(false);
    const ReactTagify = useDynamicImport(() => import('@yaireo/tagify/dist/react.tagify'));
    useDynamicImport(() => import('@yaireo/tagify/dist/tagify.css'));

    const onChange = useCallback((e) => {
        setTagifyEmails(e.detail.tagify.value);
    }, []);

    const showConfirmChanges = () => {
        setConfirmChanges(true);
    };

    const cancel = () => {
        setTagifyEmails([]);
        if (wereUsersInserted) {
            reloadUsers();
        }
        setVisible(false);
    };

    const create = async () => {
        const emailList = tagifyEmails.map((tag) => tag.value);
        const responses = await createUser(userToken, emailList);

        const success_users = responses.filter((response) => response.success);
        const error_users = responses.filter((response) => !response.success);

        // Remove succeeded users from tags
        success_users.forEach((response) => {
            setTagifyEmails((currentState) => {
                return currentState.filter((tag) => tag.value !== response.user_email);
            });
        });

        if (success_users.length > 0) {
            setWereUsersInserted(true);
            toast.success(`${t('CreateUsersSuccess')}: ${success_users.map((response) => `${response.user_email}`)}`, {
                iconTheme: {
                    primary: '#4C726D',
                    secondary: '#fff',
                },
                style: {
                    textAlign: 'center',
                },
            });
        }
        if (error_users.length > 0) {
            let error_msg = t('CreateUsersError');
            error_users.forEach((response) => {
                error_msg += `\n\n${response.user_email}: ${response.message}`;
            });

            toast.error(error_msg, {
                style: {
                    whiteSpace: 'pre-line',
                    textAlign: 'center',
                },
            });
        }
        setConfirmChanges(false);
        if (error_users.length === 0) {
            if (wereUsersInserted) {
                reloadUsers();
            }
            setVisible(false);
        }
    };

    if (!ReactTagify) return null;

    return (
        <CModal scrollable visible={visible} onClose={cancel} size={!confirmChanges ? 'lg' : 'md'}>
            <CModalHeader>{!confirmChanges ? t('CreateUsers') : t('Confirm Changes')}</CModalHeader>

            <CModalBody
                style={{ height: !confirmChanges ? '60vh' : 'auto', maxHeight: '60vh' }}
                className="overflow-scroll d-flex flex-column gap-2"
                lg={12}
                md={12}
                sm={12}
                xs={12}
            >
                {!confirmChanges ? (
                    <>
                        <div
                            style={{ fontWeight: 'bold' }}
                            className="d-flex align-items-center justify-content-between"
                        >
                            E-mails
                        </div>
                        <p className="px-0 py-1 m-0" style={{ fontSize: '0.8rem' }}>
                            {t('CreateUsersEmailHint')}
                        </p>
                        <ReactTagify
                            tagifyRef={tagifyRef}
                            className="form-control"
                            value={tagifyEmails}
                            onChange={onChange}
                            settings={{
                                validate: (email) => isEmail(email.value),
                            }}
                        />
                    </>
                ) : (
                    <div className="d-flex flex-column align-items-center">
                        {tagifyEmails.map((tag) => (
                            <p className="p-0 m-0" style={{ fontStyle: 'italic', fontSize: '0.9rem' }} key={tag.value}>
                                {tag.value}
                            </p>
                        ))}
                    </div>
                )}
            </CModalBody>

            <CModalFooter>
                <CButton color="light" onClick={cancel}>
                    {t('Cancel')}
                </CButton>
                <CButton onClick={confirmChanges ? create : showConfirmChanges} color="secondary">
                    {confirmChanges ? t('Save Changes') : t('Save')}
                </CButton>
            </CModalFooter>
        </CModal>
    );
};

export default CreateUserPopup;
