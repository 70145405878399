/* eslint-disable import/no-anonymous-default-export */
import {
    CLEAR_ERRORS,
    GET_ALL_DC_PROFESSIONALS_FAIL,
    GET_ALL_DC_PROFESSIONALS_SUCCESS,
    GET_DC_PROFESSIONAL_BY_ID_FAIL,
    GET_DC_PROFESSIONAL_BY_ID_SUCCESS,
    GET_DC_PROFESSIONAL_MAPPINGS_FAIL,
    GET_DC_PROFESSIONAL_MAPPINGS_SUCCESS,
    GET_DC_PROFESSIONAL_MAPPING_BY_ID_FAIL,
    GET_DC_PROFESSIONAL_MAPPING_BY_ID_SUCCESS,
    SET_TO_DEFAULT,
} from '@/store/actions';

export default (state, action) => {
    switch (action.type) {
        case GET_ALL_DC_PROFESSIONALS_SUCCESS:
            return {
                ...state,
                dcProfessionals: action.payload,
                successMessage: action.successMessage,
            };

        case GET_DC_PROFESSIONAL_MAPPINGS_SUCCESS:
            return {
                ...state,
                dcProfessionalsMapping: action.payload,
            };

        case GET_DC_PROFESSIONAL_BY_ID_SUCCESS:
            return {
                ...state,
                singleDcProfessionalRecord: action.payload,
                successMessage: action.successMessage,
            };

        case GET_DC_PROFESSIONAL_MAPPING_BY_ID_SUCCESS:
            return {
                ...state,
                dcProfessionalsIds: action.payload,
                successMessage: action.successMessage,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                successMessage: null,
            };
        case SET_TO_DEFAULT:
            return {
                ...state,
                dcProfessionals: [],
                singleDcProfessionalRecord: null,
                error: null,
                successMessage: null,
            };

        case GET_DC_PROFESSIONAL_BY_ID_FAIL:
        case GET_ALL_DC_PROFESSIONALS_FAIL:
        case GET_DC_PROFESSIONAL_MAPPING_BY_ID_FAIL:
        case GET_DC_PROFESSIONAL_MAPPINGS_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};
