import { CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react';

import { usePowerUserStore } from '@/zustandStore';

const CertModal = () => {
    const lastUsedCert = usePowerUserStore((state) => state.lastUsedCert);
    const showCertModal = usePowerUserStore((state) => state.showCertModal);

    return (
        <CModal
            size="lg"
            scrollable
            visible={showCertModal}
            onClose={() => usePowerUserStore.setState({ showCertModal: false })}
        >
            <CModalHeader />
            <CModalBody lg={20} md={12} sm={12} xs={12}>
                <p className="p-0 m-0">{lastUsedCert}</p>
            </CModalBody>
            <CModalFooter />
        </CModal>
    );
};

export default CertModal;
