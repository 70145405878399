import { useMemo, useReducer } from 'react';

import { getAppointments, getTodayAppointments, updateUserByNumber } from '@/api/appointment/AppointmentCalls';

import {
    CLEAR_CHECK_IN_SUCCESS,
    CLEAR_ERRORS,
    CLEAR_OBJECTS,
    GET_ALL_APPOINTMENTS_FAIL,
    GET_ALL_APPOINTMENTS_SUCCESS,
    GET_ALL_TODAYS_APPOINTMENTS_FAIL,
    GET_ALL_TODAYS_APPOINTMENTS_SUCCESS,
    SET_TO_DEFAULT,
    UPDATE_USER_BY_NUMBER_FAIL,
    UPDATE_USER_BY_NUMBER_SUCCESS,
} from '@/store/actions';

import { useLocationStore, useTodaysAppointmentsStore } from '@/zustandStore';

import AppointmentContext from './AppointmentContext';
import AppointmentReducer from './AppointmentReducer';

const AppointmentState = ({ children }) => {
    const initialState = {
        appointments: [],
        todaysAppointments: [],
        checkInDetails: null,
        selectedAppointments: [],
        code: null,
        error: null,
        successMessage: null,
    };
    const [state, dispatch] = useReducer(AppointmentReducer, initialState);

    // getAllTodaysAppointments
    const getAllTodaysAppointments = async (userToken, currentDate, selectedLocation) => {
        console.debug('in getAllTodaysAppointment');
        try {
            const response = await getTodayAppointments(userToken, currentDate, selectedLocation);
            console.debug('response == ', response);
            const inactiveAppointments = response.data.data.inactive
                .filter((entry) => entry.status !== 'CANCELLED')
                .map((entry) => {
                    return {
                        ...entry,
                        inactive: true,
                    };
                });
            const payload = [...response.data.data.active, ...inactiveAppointments];
            if (payload.length === 0) useLocationStore.setState({ loading: false });
            dispatch({
                type: GET_ALL_TODAYS_APPOINTMENTS_SUCCESS,
                payload: payload,
                successMessage: response.data.data.active.length !== 0 ? 'Record Found!!' : 'Record Not Found!',
            });
        } catch (error) {
            dispatch({
                type: GET_ALL_TODAYS_APPOINTMENTS_FAIL,
                payload: error.message,
            });
        }
        useTodaysAppointmentsStore.setState({ isTodaysAppointmentsLoading: false });
    };

    // getAllsAppointments
    const getAllAppointments = async (userToken) => {
        console.debug('in getAllAppointments');
        try {
            const response = await getAppointments(userToken);
            dispatch({
                type: GET_ALL_APPOINTMENTS_SUCCESS,
                payload: response.data.data.active,
                successMessage: response.data.data.active.length !== 0 ? 'Record Found!!' : 'Record Not Found!!',
            });
        } catch (error) {
            dispatch({
                type: GET_ALL_APPOINTMENTS_FAIL,
                payload: error.message,
            });
        }
    };

    // updateAppointmentByUser
    const updateAppointmentByUser = async (userToken, userInfo, id) => {
        try {
            const response = await updateUserByNumber(userToken, userInfo, id);
            console.debug('response', response);
            if (response.status === 200) {
                dispatch({
                    type: UPDATE_USER_BY_NUMBER_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: UPDATE_USER_BY_NUMBER_FAIL,
                payload: error.response.data,
            });
        }
    };

    const clearRecords = () => {
        dispatch({ type: CLEAR_OBJECTS });
    };

    const clearCheckinDetails = () => {
        dispatch({ type: CLEAR_CHECK_IN_SUCCESS });
    };

    const clearError = () => {
        dispatch({ type: CLEAR_ERRORS });
    };

    const setToDefault = () => {
        dispatch({ type: SET_TO_DEFAULT });
    };

    const value = useMemo(
        () => ({
            todaysAppointments: state.todaysAppointments,
            appointments: state.appointments,
            checkInDetails: state.checkInDetails,
            selectedAppointments: state.selectedAppointments,
            code: state.code,
            error: state.error,
            successMessage: state.successMessage,
            getAllTodaysAppointments: getAllTodaysAppointments,
            getAllAppointments: getAllAppointments,
            updateAppointmentByUser: updateAppointmentByUser,
            clearCheckinDetails: clearCheckinDetails,
            clearError: clearError,
            setToDefault: setToDefault,
            clearRecords: clearRecords,
        }),
        [state]
    );

    return <AppointmentContext.Provider value={value}>{children}</AppointmentContext.Provider>;
};
export default AppointmentState;
