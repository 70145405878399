import { CButton, CCol, CImage, CModal, CModalBody, CModalFooter } from '@coreui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PreviewAndSave = ({ formData }) => {
    const { t } = useTranslation(['common']);

    const [dialog, setDialog] = useState(false);
    const [imageSrc, setImageSrc] = useState('');

    const toggleDialog = () => {
        setDialog(!dialog);
    };

    return (
        <>
            <CCol className="pt-5 pb-3 custom-form-text" xs={12} sm={12} lg={12}>
                {t('Check the form and save it')}
            </CCol>

            <CCol className="pb-3 paragraph-text" xs={12} sm={12} lg={12}>
                {t(
                    'This is a preview of the form to see how patients will see it. Please choose a different template if not all required input fields are available in the current form'
                )}
            </CCol>
            {formData?.templates_image?.map(
                (imageUrl) =>
                    imageUrl && (
                        <CCol key={imageUrl} className="pb-3" style={{ cursor: 'pointer', objectFit: 'contain' }}>
                            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                            <img
                                src={imageUrl}
                                alt="Form Preview"
                                onClick={() => {
                                    setImageSrc(imageUrl);
                                    toggleDialog();
                                }}
                                width={350}
                                height={350}
                            />
                        </CCol>
                    )
            )}
            {dialog && (
                <CModal visible={dialog} size="lg">
                    <CModalBody>
                        <div className="text-center">
                            <CImage style={{ objectFit: 'contain' }} rounded src={imageSrc} width={750} height={750} />
                        </div>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={() => toggleDialog()}>
                            {t('Close')}
                        </CButton>
                    </CModalFooter>
                </CModal>
            )}
        </>
    );
};

export default PreviewAndSave;
