import {
    CAccordion,
    CAccordionBody,
    CAccordionHeader,
    CAccordionItem,
    CButton,
    CCard,
    CCardBody,
    CCardText,
    CCardTitle,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CPagination,
    CPaginationItem,
    CRow,
} from '@coreui/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@/components/Alert';

import AWSContext from '@/context/AWSContext';
import AlertContext from '@/context/alert/AlertContext';
import AppointmentContext from '@/context/appointment/AppointmentContext';
import PatientContext from '@/context/patient/PatientContext';

const Appointments = () => {
    const { t } = useTranslation(['common']);
    const [edit, setEdit] = useState(false);
    const [firstTimeLoad, setFirstTimeLoad] = useState(false);
    const [firstTimeApiCall, setFirstTimeApiCall] = useState(false);
    const appointmentContext = useContext(AppointmentContext);
    const patientContext = useContext(PatientContext);
    const alertContext = useContext(AlertContext);
    const awsContext = useContext(AWSContext);
    const {
        getAllAppointments,
        appointments,
        updateAppointmentByUser,
        error,
        clearError,
        successMessage,
        setToDefault,
    } = appointmentContext;
    const { getSinglePatientRecord, singlePatientRecord, clearRecords } = patientContext;
    const { setAlert } = alertContext;
    const { userToken } = awsContext;
    const [activeItem] = useState(0);
    const [userInfo, setUserInfo] = useState({
        group: 'BR',
        number: '',
        name: '',
        user_id: '',
    });

    const onChange = (e) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const updatePatientAppointmentDetails = () => {
        if (singlePatientRecord) {
            userInfo.name = `${singlePatientRecord.name.first} ${singlePatientRecord.name.last}`;
        }

        updateAppointmentByUser(userToken, userInfo, userInfo.user_id);
        clearRecords();
    };

    useEffect(() => {
        if (appointments.length === 0) {
            if (firstTimeApiCall === false) {
                getAllAppointments(userToken);
                setFirstTimeApiCall(true);
            }
        }
        if (error !== null) {
            if (appointments.length === 0) {
                setAlert(error, 'danger');
                setFirstTimeLoad(true);
                clearError();
            } else {
                setAlert(error, 'danger');
                clearError();
            }
        }

        if (successMessage !== null) {
            setAlert(successMessage, 'success');
            setFirstTimeLoad(true);
            clearError();
        }
    }, [appointments, singlePatientRecord, error, successMessage]);

    useEffect(() => {
        return () => {
            setToDefault();
            console.debug('Do some cleanup');
        };
    }, []);

    return (
        <>
            <CContainer style={{ marginTop: 20, marginBottom: 10 }}>
                <CRow xs={{ gutterY: 2 }}>
                    <CCol sm={6} xs={12}>
                        <CForm>
                            <div className="mb-3">
                                <CFormInput type="text" id="search" placeholder={`${t('Search')}`} />
                            </div>
                        </CForm>
                    </CCol>
                </CRow>
                <Alert />
            </CContainer>
            <CContainer>
                {appointments.length !== 0 ? (
                    <CRow xs={{ gutterY: 2 }}>
                        <CAccordion activeItemKey={activeItem}>
                            {appointments.map((item, index) => (
                                <CAccordionItem key={item.id} itemKey={index}>
                                    <CAccordionHeader onClick={() => getSinglePatientRecord(userToken, item.user_id)}>
                                        {`${item.location_key} | ${item.status} | (${item.day}) `}
                                    </CAccordionHeader>
                                    <CAccordionBody>
                                        <CRow xs={{ gutterY: 2 }}>
                                            <CCol lg={4} md={4} sm={12} xs={12}>
                                                <CCard>
                                                    <CCardBody>
                                                        <CCardTitle style={{ fontSize: 16 }}>
                                                            {typeof item.user_id !== 'undefined' ? item.user_id : '-'}
                                                        </CCardTitle>
                                                        <CCardText style={{ fontSize: 13 }}>{t('User Id')}</CCardText>
                                                    </CCardBody>
                                                </CCard>
                                            </CCol>

                                            <CCol lg={4} md={4} sm={12} xs={12}>
                                                <CCard>
                                                    <CCardBody>
                                                        <CCardTitle style={{ fontSize: 16 }}>
                                                            {singlePatientRecord !== null
                                                                ? `${singlePatientRecord.name.first} ${singlePatientRecord.name.last}`
                                                                : '-'}
                                                        </CCardTitle>
                                                        <CCardText style={{ fontSize: 13 }}>{t('Username')}</CCardText>
                                                    </CCardBody>
                                                </CCard>
                                            </CCol>

                                            <CCol lg={4} md={4} sm={12} xs={12}>
                                                <CCard>
                                                    <CCardBody>
                                                        <CCardTitle style={{ fontSize: 16 }}>
                                                            {' '}
                                                            {typeof item.start !== 'undefined'
                                                                ? new Date(item.start).toLocaleTimeString()
                                                                : '-'}{' '}
                                                        </CCardTitle>
                                                        <CCardText style={{ fontSize: 13 }}>
                                                            {' '}
                                                            {t('Start Time')}{' '}
                                                        </CCardText>
                                                    </CCardBody>
                                                </CCard>
                                            </CCol>
                                            <CCol lg={4} md={4} sm={12} xs={12}>
                                                <CCard>
                                                    <CCardBody>
                                                        <CCardTitle style={{ fontSize: 16 }}>
                                                            {' '}
                                                            {typeof item.end !== 'undefined'
                                                                ? new Date(item.end).toLocaleTimeString()
                                                                : '-'}{' '}
                                                        </CCardTitle>
                                                        <CCardText style={{ fontSize: 13 }}>
                                                            {' '}
                                                            {t('End Time')}{' '}
                                                        </CCardText>
                                                    </CCardBody>
                                                </CCard>
                                            </CCol>
                                            <CCol lg={4} md={4} sm={12} xs={12}>
                                                <CCard>
                                                    <CCardBody>
                                                        <CCardTitle style={{ fontSize: 16 }}>
                                                            {typeof item.status !== 'undefined' ? item.status : '-'}
                                                        </CCardTitle>
                                                        <CCardText style={{ fontSize: 13 }}>{t('Status')}</CCardText>
                                                    </CCardBody>
                                                </CCard>
                                            </CCol>
                                            <CCol lg={4} md={4} sm={12} xs={12}>
                                                <CCard>
                                                    <CCardBody>
                                                        <CCardTitle style={{ fontSize: 16 }}>
                                                            {typeof item.professional.type_key !== 'undefined'
                                                                ? item.professional.type_key
                                                                : '-'}
                                                        </CCardTitle>
                                                        <CCardText style={{ fontSize: 13 }}>{t('Type')}</CCardText>
                                                    </CCardBody>
                                                </CCard>
                                            </CCol>
                                            <CCol lg={4} md={4} sm={12} xs={12}>
                                                <CCard>
                                                    <CCardBody>
                                                        <CCardTitle style={{ fontSize: 16 }}>
                                                            {typeof item.name !== 'undefined' ? item.name : '-'}
                                                        </CCardTitle>
                                                        <CCardText style={{ fontSize: 13 }}>{t('Name')}</CCardText>
                                                    </CCardBody>
                                                </CCard>
                                            </CCol>
                                            <CCol lg={4} md={4} sm={12} xs={12}>
                                                <CCard>
                                                    <CCardBody>
                                                        <CCardTitle style={{ fontSize: 16 }}>
                                                            {typeof item.duration !== 'undefined'
                                                                ? `${item.duration.value} ${item.duration.units}`
                                                                : '-'}
                                                        </CCardTitle>
                                                        <CCardText style={{ fontSize: 13 }}>{t('Duration')}</CCardText>
                                                    </CCardBody>
                                                </CCard>
                                            </CCol>
                                            <CCol lg={4} md={4} sm={12} xs={12}>
                                                <CCard>
                                                    <CCardBody>
                                                        <CCardTitle style={{ fontSize: 16 }}>
                                                            {typeof item.data.insurance_coverage_type !== 'undefined'
                                                                ? item.data.insurance_coverage_type
                                                                : '-'}
                                                        </CCardTitle>
                                                        <CCardText style={{ fontSize: 13 }}>
                                                            {t('Insurance Type')}
                                                        </CCardText>
                                                    </CCardBody>
                                                </CCard>
                                            </CCol>
                                            <CCol lg={4} md={4} sm={12} xs={12}>
                                                <CCard>
                                                    <CCardBody>
                                                        <CCardTitle style={{ fontSize: 16 }}>
                                                            {typeof item.professional.id !== 'undefined'
                                                                ? item.professional.id
                                                                : '-'}
                                                        </CCardTitle>
                                                        <CCardText style={{ fontSize: 13 }}>
                                                            {t('Professional Id')}
                                                        </CCardText>
                                                    </CCardBody>
                                                </CCard>
                                            </CCol>
                                        </CRow>
                                    </CAccordionBody>
                                </CAccordionItem>
                            ))}
                        </CAccordion>
                    </CRow>
                ) : (
                    <CCol lg={12} md={12} sm={12} xs={12}>
                        {firstTimeLoad === false ? (
                            <CCard>
                                <CCardBody>
                                    <div className="text-center">
                                        <div className="spinner-grow text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </CCardBody>
                            </CCard>
                        ) : (
                            <CCard>
                                <CCardBody>
                                    <div className="text-center">
                                        <h3>{t('No Data Found')}</h3>
                                    </div>
                                </CCardBody>
                            </CCard>
                        )}
                    </CCol>
                )}
            </CContainer>

            <CContainer>
                <CRow xs={{ gutterY: 2 }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            marginTop: 50,
                        }}
                    >
                        <CPagination size="sm" aria-label="Pagination">
                            <CPaginationItem>Previous</CPaginationItem>
                            <CPaginationItem>1</CPaginationItem>
                            <CPaginationItem>2</CPaginationItem>
                            <CPaginationItem>3</CPaginationItem>
                            <CPaginationItem>4</CPaginationItem>
                            <CPaginationItem>5</CPaginationItem>
                            <CPaginationItem>6</CPaginationItem>
                            <CPaginationItem>Next</CPaginationItem>
                        </CPagination>
                    </div>
                </CRow>
            </CContainer>
            <CModal
                scrollable
                visible={edit}
                onClose={() => {
                    setEdit(false);
                }}
            >
                <CModalHeader>
                    <CModalTitle>Edit</CModalTitle>
                </CModalHeader>
                <CModalBody lg={12} md={12} sm={12} xs={12}>
                    {userInfo !== null ? (
                        <CContainer style={{ marginTop: 20, marginBottom: 10 }}>
                            <CRow xs={{ gutterY: 2 }}>
                                <CCol lg={12} md={12} sm={12} xs={12}>
                                    <CCard>
                                        <CCardBody>
                                            <CCardTitle style={{ fontSize: 16 }}>
                                                {' '}
                                                {'Please Provide Reffernce Number'}{' '}
                                            </CCardTitle>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol lg={12} md={12} sm={12} xs={12}>
                                    <CCard>
                                        <CCardBody>
                                            <CCardTitle style={{ fontSize: 16 }}> Reffrence Number </CCardTitle>
                                            <CForm>
                                                <div className="mb-3">
                                                    <CFormInput
                                                        type="text"
                                                        value={userInfo.number}
                                                        name="number"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </CForm>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                        </CContainer>
                    ) : (
                        <div className="text-center">
                            <div className="spinner-grow text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )}
                </CModalBody>

                <CModalFooter>
                    <CButton
                        color="secondary"
                        onClick={() => {
                            setEdit(false);
                        }}
                    >
                        Close
                    </CButton>
                    <CButton
                        onClick={() => {
                            setEdit(false);
                            updatePatientAppointmentDetails();
                        }}
                        color="secondary"
                    >
                        Save changes
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    );
};

export default Appointments;
