import { CButton, CForm } from '@coreui/react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { t } from 'i18next';
import { useContext, useEffect } from 'react';
import useSWRImmutable from 'swr/immutable';

import AddMappingModal from '@/components/AppointmentsMapping/AddMappingModal';

import AWSContext from '@/context/AWSContext';

import { getCustomerId, swrFetcher } from '@/utils/helpers';

import { useAppointmentsMappingStore } from '@/zustandStore';

const BASE_URL = `${import.meta.env.VITE_PROTOCOL}://${import.meta.env.VITE_PRACTITIONER_API_DOMAIN}/${
    import.meta.env.VITE_SECURE_DC_API_PATH
}/`;
const API_VERSION = 'v2';

const AppointmentsMapping = () => {
    const scheduleMappings = useAppointmentsMappingStore((state) => state.scheduleMappings);
    const scheduleTypes = useAppointmentsMappingStore((state) => state.scheduleTypes);
    const { userToken } = useContext(AWSContext);
    const { data: mappingsResponse } = useSWRImmutable(
        [
            `${BASE_URL}schedule-types/mappings`,
            {
                headers: {
                    Authorization: userToken,
                    version: API_VERSION,
                    customer_id: getCustomerId(),
                },
            },
        ],
        swrFetcher
    );
    const { data: typesResponse } = useSWRImmutable(
        [
            `${BASE_URL}schedule-types`,
            {
                headers: {
                    Authorization: userToken,
                    version: API_VERSION,
                    customer_id: getCustomerId(),
                },
            },
        ],
        swrFetcher
    );

    useEffect(() => {
        if (!mappingsResponse) return;

        useAppointmentsMappingStore.setState({ scheduleMappings: mappingsResponse.data });
    }, [mappingsResponse]);

    useEffect(() => {
        if (!typesResponse) return;

        const scheduleTypesObj = typesResponse.data.reduce(
            (acc, type) => ({
                ...acc,
                [`${type.instance_id}-${type.id}`]: type,
            }),
            {}
        );
        useAppointmentsMappingStore.setState({ scheduleTypes: scheduleTypesObj });
    }, [typesResponse]);

    const editMapping = (mapping) => {
        useAppointmentsMappingStore.setState({ mapping: mapping });
    };

    if (!scheduleTypes) return null;

    return (
        <>
            <CForm>
                <CButton
                    onClick={() => {
                        useAppointmentsMappingStore.setState({ showAddMappingModal: true, mapping: undefined });
                    }}
                    color="secondary"
                >
                    {t('Add Mapping')}
                </CButton>
            </CForm>
            <TableContainer
                component={Paper}
                style={{
                    width: '90%',
                    borderRight: '1px solid #ccc',
                }}
            >
                <Table aria-label="collapsible table">
                    <TableHead
                        style={{
                            position: 'sticky',
                            top: 0,
                            background: 'white',
                        }}
                    >
                        <TableRow>
                            <TableCell>
                                <b>{t('Appointment')}</b>
                            </TableCell>
                            <TableCell style={{ minWidth: '11rem' }}>
                                <b>{t('Prismic key')}</b>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scheduleMappings?.map((val) => {
                            return (
                                <TableRow key={val.user_id}>
                                    <TableCell>
                                        {scheduleTypes[`${val.dc_instance_id}-${val.dc_schedule_type_id}`]?.name}
                                    </TableCell>
                                    <TableCell style={{ minWidth: '11rem' }}>{val.prismic_key}</TableCell>
                                    <TableCell>
                                        <CButton
                                            onClick={() => {
                                                editMapping(val);
                                                useAppointmentsMappingStore.setState({ showAddMappingModal: true });
                                            }}
                                        >
                                            Edit
                                        </CButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <AddMappingModal />
        </>
    );
};

export default AppointmentsMapping;
