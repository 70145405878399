import {
    CButton,
    CCard,
    CCardBody,
    CCardText,
    CCardTitle,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
} from '@coreui/react';
import { Button, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@/context/theme/ThemeContext';

import useAuth from '@/hooks/useAuth';

import { AccessRequestStatus, EmployeeType, accessRequestEmployeeTypesMap } from '@/utils/constants';
import { dayjs } from '@/utils/dayjsSetup';
import { getPrismicText, sendDecision } from '@/utils/helpers';

import { usePrismicStore } from '@/zustandStore';

import BasicStatusTag from '../TodaysAppointments/BasicStatusTag';
import { cardContentStyle, cardStyle, cardTitleStyle } from './UserEntry';

const AccessRequest = ({ data, customersById, locationsByGroup, loadAccessRequests, getUsers }) => {
    const { t } = useTranslation();
    const { user, userToken } = useAuth();
    const { colors } = useTheme();
    const prismicData = usePrismicStore((state) => state.accessRequests);
    const [showModal, setShowModal] = useState(false);
    const [selectedEmployeeType, setSelectedEmployeeType] = useState(null);
    const [selectedDecision, setSelectedDecision] = useState(data.decisions[user.sub]?.decision ?? null);

    const decisions = useMemo(() => Object.values(data.decisions), [data]);

    const saveDecision = async () => {
        if (!selectedDecision) return;

        setShowModal(false);

        const obj = {
            access_request_user_id: data.user_id,
            employee_type: selectedEmployeeType ?? EmployeeType.HUB_EMPLOYEE,
            decision: selectedDecision,
        };

        const [isSaved, response] = await sendDecision(userToken, obj);

        if (!isSaved) {
            toast.error(getPrismicText(prismicData.fail_message));
            return;
        }

        toast.success(
            `${getPrismicText(prismicData.updated_decision_message)}${
                response.is_user_updated ? ` ${getPrismicText(prismicData.updated_user_message)}` : ''
            }`,
            {
                iconTheme: {
                    primary: colors.secondary,
                    secondary: '#fff',
                },
            }
        );

        if (response.is_user_updated) await Promise.all([loadAccessRequests(data.user_id), getUsers()]);
        else await loadAccessRequests(data.user_id);
    };

    if (!prismicData) return null;

    return (
        <CCard className="px-4 py-4 d-flex flex-row align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-4" style={{ flex: 1 }}>
                <CCard style={{ minWidth: '10rem' }}>
                    <CCardBody style={cardStyle}>
                        <CCardText style={cardTitleStyle}>{getPrismicText(prismicData.user_field)}</CCardText>
                        <CCardTitle style={cardContentStyle}>
                            {data.name ?? getPrismicText(prismicData.unknown_data).toLowerCase()}
                        </CCardTitle>
                    </CCardBody>
                </CCard>
                <CCard style={{ minWidth: '10rem' }}>
                    <CCardBody style={cardStyle}>
                        <CCardText style={cardTitleStyle}>
                            {getPrismicText(prismicData.customer_location_field)}
                        </CCardText>
                        <div className="d-flex flex-column">
                            <CCardTitle style={cardContentStyle}>
                                {customersById[data.requested_customer]?.customer_name[0].text ??
                                    getPrismicText(prismicData.unknown_data)}
                            </CCardTitle>
                            <CCardTitle style={cardContentStyle}>
                                {locationsByGroup[data.requested_location]?.title[0].text ??
                                    getPrismicText(prismicData.unknown_data)}
                            </CCardTitle>
                        </div>
                    </CCardBody>
                </CCard>
                <CCard style={{ minWidth: '10rem' }}>
                    <CCardBody style={cardStyle}>
                        <CCardText style={cardTitleStyle}>{getPrismicText(prismicData.employee_type_field)}</CCardText>
                        <CCardTitle style={cardContentStyle}>
                            {data.employee_type && data.employee_type !== ''
                                ? (accessRequestEmployeeTypesMap[data.employee_type]?.name ??
                                  getPrismicText(prismicData.unknown_data))
                                : accessRequestEmployeeTypesMap[EmployeeType.HUB_EMPLOYEE].name}
                        </CCardTitle>
                    </CCardBody>
                </CCard>
                <CCard style={{ minWidth: '10rem' }}>
                    <CCardBody style={cardStyle}>
                        <CCardText style={cardTitleStyle}>{getPrismicText(prismicData.timestamps_field)}</CCardText>
                        <div className="d-flex flex-column">
                            <CCardTitle style={cardContentStyle}>
                                {`${getPrismicText(prismicData.created_timestamp)} ${dayjs(data.meta.created_at).format(
                                    'HH:mm DD.MM.YYYY'
                                )}`}
                            </CCardTitle>
                            <CCardTitle style={cardContentStyle}>
                                {`${getPrismicText(prismicData.updated_timestamp)} ${dayjs(data.meta.updated_at).format(
                                    'HH:mm DD.MM.YYYY'
                                )}`}
                            </CCardTitle>
                        </div>
                    </CCardBody>
                </CCard>
            </div>
            <div className="d-flex gap-3">
                <BasicStatusTag status={data.status} label={t(data.status)} />
                <CButton color="secondary" onClick={() => setShowModal(true)}>
                    {getPrismicText(prismicData.manage_decisions_cta).replace(
                        '{decisions_amount_placeholder}',
                        decisions.length
                    )}
                </CButton>
            </div>
            <CModal
                scrollable
                visible={showModal}
                onClose={() => {
                    setShowModal(false);
                }}
                size="md"
            >
                <CModalHeader>{getPrismicText(prismicData.decisions_modal_header)}</CModalHeader>
                <CModalBody className="d-flex flex-column">
                    <div className="d-flex flex-column gap-2">
                        {decisions.map((decision) => (
                            <div key={decision.user_id} className="w-100 d-flex justify-content-between">
                                <p className="m-0 p-0">
                                    {decision.user_id === user.sub ? `Du (${decision.name})` : decision.name}
                                </p>
                                <BasicStatusTag
                                    status={decision.decision}
                                    label={t(decision.decision)}
                                    styles={{ width: '5rem' }}
                                />
                            </div>
                        ))}
                        {decisions.length === 0 && (
                            <div className="w-100 d-flex align-items-center justify-content-center">
                                {getPrismicText(prismicData.no_decisions)}
                            </div>
                        )}
                    </div>
                </CModalBody>
                {data.status !== AccessRequestStatus.ACCEPTED && (
                    <CModalFooter>
                        <div className="m-0 p-0 w-100 d-flex flex-column align-items-center gap-3">
                            {getPrismicText(prismicData.your_decision)}
                            <div className="d-flex gap-3">
                                {Object.values(accessRequestEmployeeTypesMap).map((employeeType) => {
                                    const isSelectedEmployeeType =
                                        employeeType.key === selectedEmployeeType ||
                                        (!selectedEmployeeType && employeeType.key === EmployeeType.HUB_EMPLOYEE);

                                    return (
                                        <Button
                                            key={employeeType.key}
                                            disableRipple
                                            style={{
                                                backgroundColor: isSelectedEmployeeType ? 'white' : '#FBFBF9',
                                                boxShadow: isSelectedEmployeeType
                                                    ? `0 0 0 2px #ffc877`
                                                    : `0 0 0 1px #cac5b4`,
                                                borderRadius: '0.5rem',
                                                padding: '1rem 1.25rem',
                                                display: 'inline-block',
                                                width: '10rem',
                                            }}
                                            onClick={() => setSelectedEmployeeType(employeeType.key)}
                                        >
                                            <Typography
                                                style={{
                                                    textTransform: 'none',
                                                    color: 'black',
                                                }}
                                            >
                                                {employeeType.name}
                                            </Typography>
                                        </Button>
                                    );
                                })}
                            </div>
                            <div className="d-flex gap-3">
                                <Button
                                    disableRipple
                                    style={{
                                        backgroundColor:
                                            selectedDecision === AccessRequestStatus.ACCEPTED ? 'white' : '#FBFBF9',
                                        boxShadow:
                                            selectedDecision === AccessRequestStatus.ACCEPTED
                                                ? `0 0 0 2px #ffc877`
                                                : `0 0 0 1px #cac5b4`,
                                        borderRadius: '0.5rem',
                                        padding: '1rem 1.25rem',
                                        display: 'inline-block',
                                        width: '10rem',
                                    }}
                                    onClick={() => setSelectedDecision(AccessRequestStatus.ACCEPTED)}
                                >
                                    <Typography
                                        style={{
                                            textTransform: 'none',
                                            color: 'black',
                                        }}
                                    >
                                        {t(AccessRequestStatus.ACCEPTED)}
                                    </Typography>
                                </Button>
                                <Button
                                    disableRipple
                                    style={{
                                        backgroundColor:
                                            selectedDecision === AccessRequestStatus.DECLINED ? 'white' : '#FBFBF9',
                                        boxShadow:
                                            selectedDecision === AccessRequestStatus.DECLINED
                                                ? `0 0 0 2px #ffc877`
                                                : `0 0 0 1px #cac5b4`,
                                        borderRadius: '0.5rem',
                                        padding: '1rem 1.25rem',
                                        display: 'inline-block',
                                        width: '10rem',
                                    }}
                                    onClick={() => setSelectedDecision(AccessRequestStatus.DECLINED)}
                                >
                                    <Typography
                                        style={{
                                            textTransform: 'none',
                                            color: 'black',
                                        }}
                                    >
                                        {t(AccessRequestStatus.DECLINED)}
                                    </Typography>
                                </Button>
                            </div>
                            <CButton disabled={!selectedDecision} color="secondary" onClick={saveDecision}>
                                {t('Save')}
                            </CButton>
                        </div>
                    </CModalFooter>
                )}
            </CModal>
        </CCard>
    );
};

export default AccessRequest;
