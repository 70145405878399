import { cloneDeep } from 'es-toolkit';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import fingerprintIcon from '@/assets/Handwriting-cercles.svg';

import AWSContext from '@/context/AWSContext';

import { getExternalPatientDetails } from '@/utils/helpers';

import { useHeraklesStore } from '@/zustandStore';

import DetailsField from '../DetailsField';
import EternoSpinner from '../EternoLogoSpinner/EternoSpinner';
import Icon from '../Icon/Icon';
import StatusIcon from '../StatusIcon';
import HeraklesFormLink, { HeraklesFormLinkStatus } from './HeraklesFormLink';

const HeraklesPatientDetails = ({ patient, detailsLoading, setDetailsLoading }) => {
    const { userToken } = useContext(AWSContext);
    const { t } = useTranslation();
    const [hasFailed, setHasFailed] = useState(false);
    const patientDetails = useHeraklesStore((state) => state.patientDetails);

    const getPatientData = async () => {
        if (patientDetails[patient.user_id]?.timestamp > Date.now()) {
            setDetailsLoading(false);
            return;
        }

        const data = await getExternalPatientDetails(userToken, patient.user_id);
        if (data?.documents) {
            const availableDocuments = patient.status_data.data.status_data.reduce((obj, form) => {
                const documents = data.documents.filter((document) => document.document.name.startsWith(form));

                let document;
                documents.forEach((doc) => {
                    if (
                        !document ||
                        new Date(doc.document.meta.updated_at) > new Date(document.document.meta.updated_at)
                    )
                        document = doc;
                });

                if (!document?.download_url) return obj;

                // eslint-disable-next-line no-param-reassign
                obj[form] = document.download_url;
                return obj;
            }, {});

            const newPatientDetails = cloneDeep(patientDetails);
            newPatientDetails[patient.user_id] = {
                data: { ...data, available_documents: availableDocuments },
                timestamp: Date.now() + 300000,
            };

            useHeraklesStore.setState({ patientDetails: newPatientDetails });
            if (hasFailed) setHasFailed(false);
        } else {
            setHasFailed(true);
        }

        setDetailsLoading(false);
    };

    useEffect(() => {
        if (!patient) return;
        getPatientData();
    }, [patient]);

    if (detailsLoading || hasFailed) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    background: 'white',
                    width: '40%',
                    position: 'relative',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {detailsLoading ? <EternoSpinner /> : <h4>{t('FailedCallTitle')}</h4>}
            </div>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                background: 'white',
                width: '40%',
                padding: '2rem',
                position: 'relative',
            }}
        >
            <div style={{ width: '4rem', height: '5.6rem', position: 'absolute', right: '6rem', top: '20%' }}>
                <Icon src={fingerprintIcon} width="100%" height="100%" />
            </div>
            <h3 className="m-0">{`${patient.personal_data.data.personal.first_name} ${patient.personal_data.data.personal.last_name}`}</h3>
            <p
                className="p-0"
                style={{
                    fontSize: '0.8rem',
                    textTransform: 'uppercase',
                    color: '#ABABAB',
                    margin: '2rem 0 1rem 0',
                }}
            >
                {t('Patient Details')}
            </p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <DetailsField name={t('First Name')}>
                    <p className="m-0 p-0">{patient.personal_data.data.personal.first_name}</p>
                </DetailsField>
                <DetailsField name={t('Last Name')}>
                    <p className="m-0 p-0">{patient.personal_data.data.personal.last_name}</p>
                </DetailsField>
                <DetailsField name={t('Date Of Birth')}>
                    <p className="m-0 p-0">{patient.personal_data.data.personal.date_of_birth}</p>
                </DetailsField>
                <DetailsField name={t('Status')}>
                    <p className="m-0 p-0 w-25">
                        <StatusIcon state={patient.filledForms} />
                    </p>
                </DetailsField>
                <DetailsField name={t('Additional Form')} alignStart>
                    <div className="m-0 p-0" style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                        <HeraklesFormLink
                            status={
                                patientDetails[patient.user_id].data.available_documents['admission-herakles']
                                    ? HeraklesFormLinkStatus.COMPLETED
                                    : HeraklesFormLinkStatus.INCOMPLETE
                            }
                            link={patientDetails[patient.user_id].data.available_documents['admission-herakles']}
                            name="Aufnahmebogen"
                        />
                        <HeraklesFormLink
                            status={
                                patientDetails[patient.user_id].data.available_documents['privacy-herakles']
                                    ? HeraklesFormLinkStatus.COMPLETED
                                    : HeraklesFormLinkStatus.INCOMPLETE
                            }
                            link={patientDetails[patient.user_id].data.available_documents['privacy-herakles']}
                            name="Datenschutz Patienteninformation"
                        />
                        <HeraklesFormLink
                            status={
                                patientDetails[patient.user_id].data.available_documents[
                                    'communication-channels-herakles'
                                ]
                                    ? HeraklesFormLinkStatus.COMPLETED
                                    : HeraklesFormLinkStatus.INCOMPLETE
                            }
                            link={
                                patientDetails[patient.user_id].data.available_documents[
                                    'communication-channels-herakles'
                                ]
                            }
                            name="Kommunikationswege"
                        />
                        <HeraklesFormLink
                            status={
                                patientDetails[patient.user_id].data.available_documents['course-enrollment-herakles']
                                    ? HeraklesFormLinkStatus.COMPLETED
                                    : HeraklesFormLinkStatus.PENDING
                            }
                            link={
                                patientDetails[patient.user_id].data.available_documents['course-enrollment-herakles']
                            }
                            name="Rückenkurs"
                        />
                    </div>
                </DetailsField>
            </div>
        </div>
    );
};

export default HeraklesPatientDetails;
