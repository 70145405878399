import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';

import AlertState from '@/context/alert/AlertState';
import AppointmentState from '@/context/appointment/AppointmentState';
import DcCalendarState from '@/context/dccalendar/DcCalendarState';
import DcPatientState from '@/context/dcpatient/DcPatientState';
import DcProfessionalState from '@/context/dcprofessional/DcProfessionalState';
import PatientState from '@/context/patient/PatientState';
import PatUserHealthProfileState from '@/context/patuserhealthprofile/PatUserHealthProfileState';
import PracUserAvailabilityState from '@/context/pracuseravailability/PracUserAvailabilityState';
import ProfessionalState from '@/context/professional/ProfessionalState';

import useLocations from '@/hooks/useLocations';
import usePrismic from '@/hooks/usePrismic';
import useRequiredAuthCheck from '@/hooks/useRequiredAuthCheck';

import Navigation from './Navigation';
import './i18n';
import './scss/style.scss';

const App = () => {
    usePrismic();
    useLocations();
    useRequiredAuthCheck();

    useEffect(() => {
        if (import.meta.env.VITE_ENVIRONMENT === 'prod') {
            console.debug = function () {};
        }
    }, []);

    return (
        <AlertState>
            <PatientState>
                <ProfessionalState>
                    <AppointmentState>
                        <DcProfessionalState>
                            <DcPatientState>
                                <DcCalendarState>
                                    <PatUserHealthProfileState>
                                        <PracUserAvailabilityState>
                                            <Navigation />
                                            <Toaster />
                                        </PracUserAvailabilityState>
                                    </PatUserHealthProfileState>
                                </DcCalendarState>
                            </DcPatientState>
                        </DcProfessionalState>
                    </AppointmentState>
                </ProfessionalState>
            </PatientState>
        </AlertState>
    );
};

export default App;
