import { useTranslation } from 'react-i18next';

import { useTheme } from '@/context/theme/ThemeContext';

import { AccessRequestStatus } from '@/utils/constants';

export const BasicStatus = {
    YES: 'yes',
    NO: 'no',
    UNKNOWN: 'unknown',
    EGK_READ: 'egk read',
    EGK_NOT_READ: 'egk not read',
    NOT_RELEVANT: 'not relevant',
};

const BasicStatusTag = ({ status, label = '', styles = {} }) => {
    const { t } = useTranslation();
    const { colors } = useTheme();

    let color;
    let text;

    switch (status) {
        case BasicStatus.YES:
        case AccessRequestStatus.ACCEPTED:
            color = colors.secondary;
            text = t('Yes');
            break;
        case BasicStatus.NO:
        case AccessRequestStatus.DECLINED:
            color = '#e55353';
            text = t('No');
            break;
        case BasicStatus.EGK_READ:
            color = colors.secondary;
            text = 'bereits eingelesen';
            break;
        case BasicStatus.EGK_NOT_READ:
            color = '#e55353';
            text = 'nicht eingelesen';
            break;
        case BasicStatus.NOT_RELEVANT:
        case AccessRequestStatus.PENDING:
            color = '#ABABAB';
            text = 'Nicht relevant';
            break;
        case BasicStatus.UNKNOWN:
        default:
            color = '#ABABAB';
            text = t('Unknown');
    }

    if (label) text = label;

    return (
        <div
            style={{
                ...styles,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0.25rem 0.5rem',
                borderRadius: '0.3rem',
                fontSize: '0.8rem',
                color: 'white',
                background: color,
            }}
        >
            {text}
        </div>
    );
};

export default BasicStatusTag;
