import { CSpinner } from '@coreui/react';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import AppointmentDetailsDocumentsItem from '@/components/TodaysAppointments/AppointmentDetailsDocumentsItem';
import DocumentCodePopup from '@/components/TodaysAppointments/DocumentCodePopup';

import useAuth from '@/hooks/useAuth';

import { Appointment } from '@/models/Appointment';
import { Document, DocumentResponseDto } from '@/models/Document';
import { DocumentCodeErrorResponse, ErrorResponseDto } from '@/models/document-error-dto';
import { MedicalRecordsDocument } from '@/models/prismic-types';

import { downloadUrl } from '@/utils/appointmentHelpers';
import { getDocumentDownloadUrl, getDocumentsByUserAndAppointment } from '@/utils/helpers';

import { usePrismicStore } from '@/zustandStore';

interface Props {
    appointment: Appointment;
}

export const AppointmentDetailsDocuments: FC<Props> = ({ appointment }) => {
    const { userToken } = useAuth() as { userToken: string | undefined };
    const [showModal, setShowModal] = useState(false);
    const medicalRecords: MedicalRecordsDocument['data'] | undefined = usePrismicStore((state) => state.medicalRecords);
    const [documentToDownloadAfterCodeInput, setDocumentToDownloadAfterCodeInput] = useState<string | undefined>();
    const { t } = useTranslation();

    const { data, isLoading } = useSWR<Document[]>(
        { key: 'appointment-documents', userId: appointment.user_id, appointmentId: appointment.id },
        () => getDocumentsByUserAndAppointment(userToken, appointment.user_id, appointment.id)
    );

    const unexpectedError = (errorData: string) => {
        console.warn(errorData);
        toast.error(`${t('unexpected_error_sending_email')}: ${errorData}`);
    };

    const downloadDocument = async (documentId: string): Promise<void> => {
        const [success, documentDownloadData, error]: [
            boolean,
            DocumentResponseDto | undefined,
            AxiosError | undefined,
        ] = (await getDocumentDownloadUrl(userToken, appointment.user_id, documentId)) as [
            boolean,
            DocumentResponseDto | undefined,
            AxiosError | undefined,
        ];

        if (success && documentDownloadData && documentDownloadData.download_url) {
            downloadUrl(documentDownloadData.download_url);
            return;
        }

        if (error?.response?.status === 403) {
            const errorData = error.response.data as ErrorResponseDto;
            if (
                errorData.error_code === DocumentCodeErrorResponse.INVALID_OR_EXPIRED ||
                errorData.error_code === DocumentCodeErrorResponse.EXPIRED_CODE
            ) {
                setShowModal(true);
                setDocumentToDownloadAfterCodeInput(documentId);
                return;
            }
        }
        const fallbackError = error?.response?.data ?? error?.message;
        unexpectedError(JSON.stringify(fallbackError));
    };

    const onInputCodeSuccess = () => {
        if (documentToDownloadAfterCodeInput) downloadDocument(documentToDownloadAfterCodeInput);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '0.5rem',
            }}
        >
            <DocumentCodePopup setVisible={setShowModal} visible={showModal} onInputCodeSuccess={onInputCodeSuccess} />
            {isLoading && <CSpinner size="sm" color="secondary" variant="grow" />}
            {(!data || data.length === 0) && <p className="m-0 p-0">{medicalRecords?.no_documents_found[0]?.text}</p>}
            {data?.map((document) => (
                <AppointmentDetailsDocumentsItem
                    key={document.id}
                    document={document}
                    downloadDocument={downloadDocument}
                />
            ))}
        </div>
    );
};
