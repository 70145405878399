import { CButton, CContainer, CForm, CFormInput } from '@coreui/react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEmail from 'validator/es/lib/isEmail';

import googleLogo from '@/assets/Google_logo.svg';
import mailIcon from '@/assets/mail_logo.svg';

import SimpleButton from '@/components/SimpleButton/SimpleButton';

import { isEternoDomain, isLocalEnvironment } from '@/utils/helpers';

const Login = () => {
    const { t } = useTranslation();
    const [externalSignIn, setExternalSignIn] = useState(false);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [magicLinkSuccess, setMagicLinkSuccess] = useState(false);

    const updateEmail = (e) => {
        setEmail(e.target.value);
        setValidEmail(isEmail(e.target.value));
    };

    const resetLogin = () => {
        setEmail('');
        setValidEmail(false);
        setMagicLinkSuccess(false);
        setExternalSignIn(true);
    };

    const magicLinkSignIn = async () => {
        if (!validEmail) return;
        setLoading(true);
        try {
            await axios.post(`https://${import.meta.env.VITE_ADMIN_API_DOMAIN}/public/auth/magic-link`, {
                email: email,
                domain: isLocalEnvironment()
                    ? 'eterno-health.io'
                    : window.location.hostname.split('.').slice(-2).join('.'),
                is_white_label: !isEternoDomain(),
            });
            setMagicLinkSuccess(true);
        } catch (e) {
            setMagicLinkSuccess(false);
            console.debug(e);
        }
        setLoading(false);
    };

    const googleHandler = async () => {
        setLoading(true);
        try {
            const user = await Auth.federatedSignIn({ provider: 'Google' });
            console.debug(user);
        } catch (err) {
            console.error(err);
        }
        setLoading(false);
    };

    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <img
                style={{ height: '100vh', width: '60%', objectFit: 'cover' }}
                src="https://assets.website-files.com/61fd7628494324361585dec9/6429a8e8f5406635e276e77d_20230330-MAP_2328-p-2000.jpg"
                alt="Eterno Wartebereich"
            />
            <CContainer
                className="d-flex flex-column align-items-center vh-100 bg-white position-relative"
                style={{ width: '40%', padding: '8rem 6rem 0 6rem' }}
            >
                {!isEternoDomain() && (
                    <SimpleButton
                        disabled={loading}
                        icon={googleLogo}
                        onClick={googleHandler}
                        style={{ position: 'absolute', bottom: '1rem', right: '1rem', padding: '1rem' }}
                    />
                )}
                {!magicLinkSuccess ? (
                    <>
                        <h1 style={{ maxWidth: '26rem' }}>{t('LoginTitle')}</h1>
                        <div
                            className="d-flex flex-column align-items-center gap-4 pb-4"
                            style={{ borderBottom: '1px solid #d5d5d5', margin: '2rem 0 1rem 0', width: '26rem' }}
                        >
                            {isEternoDomain() && (
                                <SimpleButton disabled={loading} fullWidth icon={googleLogo} onClick={googleHandler}>
                                    {t('GoogleSignInCta')}
                                </SimpleButton>
                            )}
                            <SimpleButton
                                disabled={loading}
                                active={externalSignIn}
                                fullWidth
                                onClick={() => setExternalSignIn(true)}
                            >
                                {isEternoDomain() ? t('ExternalSignInCta') : t('SignInCta')}
                            </SimpleButton>
                            {externalSignIn && (
                                <CForm className="w-100 d-flex flex-column align-items-center gap-4">
                                    <CFormInput
                                        color="secondary"
                                        disabled={loading}
                                        onChange={updateEmail}
                                        placeholder={t('EmailAddress')}
                                    />
                                    <CButton
                                        onClick={magicLinkSignIn}
                                        disabled={loading || !validEmail}
                                        color="secondary"
                                        type="button"
                                    >
                                        {t('MagicLinkCta')}
                                    </CButton>
                                </CForm>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <h1 style={{ maxWidth: '26rem' }}>{t('MagicLinkSuccessTitle')}</h1>
                        <div
                            className="d-flex flex-column align-items-center gap-4 pb-4"
                            style={{ borderBottom: '1px solid #d5d5d5', margin: '2rem 0 1rem 0', width: '26rem' }}
                        >
                            <div className="w-100">
                                <img src={mailIcon} alt="Mail" />
                            </div>
                            <p className="p-0 m-0">
                                {t('MagicLinkSuccessMessage').replace('{EmailPlaceholder}', email)}
                            </p>
                            <div className="w-100">
                                <CButton size="sm" onClick={resetLogin} color="secondary" type="button">
                                    {t('ChangeSignInEmailCta')}
                                </CButton>
                            </div>
                        </div>
                    </>
                )}
                <p style={{ fontSize: '0.9rem', width: '26rem' }}>
                    {t('FirstLoginMessage')} <a href="mailto:tech.admin@eterno.health">tech.admin@eterno.health</a>
                </p>
            </CContainer>
        </div>
    );
};

export default Login;
