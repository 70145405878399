import { Amplify } from 'aws-amplify';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { AWSProvider } from '@/context/AWSContext';
import { ThemeProvider } from '@/context/theme/ThemeContext';

import App from './App';
import awsExports from './aws-exports';
import store from './store';

Amplify.configure({
    ...awsExports,
    oauth: {
        ...awsExports.oauth,
        redirectSignIn: `${window.location.origin}/`,
        redirectSignOut: `${window.location.origin}/`,
    },
});

createRoot(document.getElementById('root')!).render(
    <Provider store={store}>
        <AWSProvider>
            <ThemeProvider>
                <App />
            </ThemeProvider>
        </AWSProvider>
    </Provider>
);
