import { useEffect } from 'react';

import { useTheme } from '@/context/theme/ThemeContext';

import { getHoverColors, getTextColor, lightenColor } from '@/utils/colors';

const useThemeOverride = () => {
    const { colors } = useTheme();

    const setButtonVars = (rule, color) => {
        rule.style.setProperty('--cui-btn-bg', color);
        rule.style.setProperty('--cui-btn-color', getTextColor(color));
        rule.style.setProperty('--cui-btn-border-color', color);
        const { hoverBgColor, hoverTextColor } = getHoverColors(color);
        rule.style.setProperty('--cui-btn-hover-bg', hoverBgColor);
        rule.style.setProperty('--cui-btn-hover-color', hoverTextColor);
        rule.style.setProperty('--cui-btn-hover-border-color', hoverBgColor);
        rule.style.setProperty('--cui-btn-disabled-bg', color);
        rule.style.setProperty('--cui-btn-disabled-color', getTextColor(color));
        rule.style.setProperty('--cui-btn-disabled-border-color', color);
        rule.style.setProperty('--cui-btn-active-bg', lightenColor(color, 0.25));
        rule.style.setProperty('--cui-btn-active-border-color', lightenColor(color, 0.1));
        rule.style.setProperty('--cui-btn-shadow', `${color}80`);
    };

    useEffect(() => {
        for (const sheet of document.styleSheets) {
            for (const rule of sheet.cssRules) {
                switch (rule.selectorText) {
                    case '.btn-primary':
                        setButtonVars(rule, colors.secondary);
                        break;
                    case '.btn-secondary':
                        setButtonVars(rule, colors.primary);
                        break;
                    case '.form-check-input':
                        rule.style.setProperty('--cui-form-check-input-checked-bg-color', colors.secondary);
                        rule.style.setProperty('--cui-form-check-input-checked-border-color', colors.secondary);
                        break;
                    default:
                        break;
                }
            }
        }
    }, [colors]);
};

export default useThemeOverride;
