import CIcon from '@coreui/icons-react';
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react';
import i18next from 'i18next';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { sygnet } from '@/assets/brand/sygnet';

import { useTheme } from '@/context/theme/ThemeContext';

import useAuth from '@/hooks/useAuth';

import { useAdminConfigStore, useLocalisationStore, useLocationStore, usePrismicStore } from '@/zustandStore';

import { AppSidebarNav } from './AppSidebarNav';
import CustomerDropdown from './CustomerDropdown';
import LocationDropdown from './LocationDropdown';

const AppSidebar = () => {
    const { i18n, t } = useTranslation(['common']);
    const dispatch = useDispatch();
    const unfoldable = useSelector((state) => state.sidebarUnfoldable);
    const sidebarShow = useSelector((state) => state.sidebarShow);
    const [lang, setLang] = useState('de');
    const isLanguageSelectionEnabled = useLocalisationStore((state) => state.isLanguageSelectionEnabled);
    const { user } = useAuth();
    const { logos } = useTheme();
    const customers = useAdminConfigStore((state) => state.customers);
    const locations = useLocationStore((state) => state.locations);
    const currentCustomer = usePrismicStore((state) => state.currentCustomer);

    const myCustomers = useMemo(
        () =>
            Object.values(customers || {}).filter(
                ({ customer_id }) => user.groups?.includes('admin') || user.customerIds.includes(customer_id[0].text)
            ),
        [user, customers]
    );

    useEffect(() => {
        if (localStorage.getItem('i18nextLng')?.length > 2) {
            i18next.changeLanguage('en');
        }
    }, []);

    const handleLanguageChange = (e) => {
        i18n.changeLanguage(e.target.value);

        setLang(e.target.value);
    };

    return (
        <CSidebar
            position="fixed"
            unfoldable={unfoldable}
            visible={sidebarShow}
            onVisibleChange={(visible) => {
                dispatch({ type: 'set', sidebarShow: visible });
            }}
        >
            <CSidebarBrand className="d-none d-md-flex" to="/">
                <img
                    style={{
                        filter: currentCustomer?.convert_to_white ? 'brightness(0) invert(1)' : 'unset',
                        width: '13rem',
                        maxHeight: '3rem',
                        objectFit: 'contain',
                    }}
                    src={logos.large}
                    alt="customer logo"
                />
                <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
            </CSidebarBrand>
            {(myCustomers.length > 1 || locations.length > 1) && (
                <>
                    <CustomerDropdown myCustomers={myCustomers} />
                    <LocationDropdown locations={locations} />
                </>
            )}
            <CSidebarNav style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <SimpleBar>
                    <AppSidebarNav />
                </SimpleBar>
                {isLanguageSelectionEnabled && (
                    <div className="p-3" style={{ marginTop: 50 }}>
                        <h6>{t('selectlanguage')}</h6>
                        <select
                            className="w-100"
                            value={lang}
                            onChange={(e) => handleLanguageChange(e)}
                            style={{
                                background: 'white',
                                border: '1px solid white',
                                color: 'black',
                                padding: '4px',
                            }}
                        >
                            <option value="en">English</option>
                            <option value="de">German</option>
                        </select>
                    </div>
                )}
            </CSidebarNav>

            <CSidebarToggler
                className="d-none d-lg-flex"
                onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
            />
        </CSidebar>
    );
};

export default memo(AppSidebar);
