import { useContext } from 'react';

import AWSContext from '@/context/AWSContext';

type UseAuthContext = { userToken: string | undefined; user: { sub: string; email: string } };

// ==============================|| AUTH HOOKS ||============================== //
const useAuth = (): UseAuthContext => {
    const context = useContext(AWSContext);
    if (!context) throw new Error('context must be use inside provider');
    return context;
};
export default useAuth;
