import { routes } from '@/routes';
import { CContainer, CSpinner } from '@coreui/react';
import { Suspense, memo, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import useAuth from '@/hooks/useAuth';

import LoadingPage from '@/views/pages/loading/LoadingPage';
import NoRoutesFallback from '@/views/pages/no-routes/NoRoutesFallback';

import { useAdminConfigStore, useLocationStore, usePrismicStore } from '@/zustandStore';

const AppContent = () => {
    const [navRoutes, setNavRoutes] = useState([]);
    const [contentLoaded, setContentLoaded] = useState(false);
    const { user } = useAuth();
    const renderedPages = useAdminConfigStore((state) => state.renderedPages);
    const hasFailed = useLocationStore((state) => state.hasFailed);
    const customers = useAdminConfigStore((state) => state.customers);
    const currentCustomer = usePrismicStore((state) => state.currentCustomer);

    useEffect(() => {
        if (routes && renderedPages) {
            const arr = [];
            if (
                user.email === import.meta.env.VITE_USER_ADMIN ||
                user.groups?.includes('admin') ||
                import.meta.env.VITE_ENVIRONMENT === 'local'
            ) {
                routes.forEach((item) => {
                    arr.push(item);
                });
                setNavRoutes(arr);
            } else {
                routes.forEach((item) => {
                    if (renderedPages[item.key]) arr.push(item);
                });
                setNavRoutes(arr);
            }
            setContentLoaded(true);
        }
    }, [renderedPages]);

    if ((!contentLoaded || !customers) && !hasFailed) return <LoadingPage />;

    if (
        (navRoutes.length === 0 ||
            hasFailed ||
            !user.customerIds ||
            user.customerIds.length === 0 ||
            !currentCustomer) &&
        !user.groups?.includes('admin')
    ) {
        return <NoRoutesFallback />;
    }

    return (
        <CContainer lg>
            <Suspense fallback={<CSpinner color="primary" />}>
                <Switch>
                    {navRoutes.map((route) => {
                        return (
                            route.component && (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    exact={route.exact}
                                    name={route.name}
                                    render={(props) => <route.component {...props} />}
                                />
                            )
                        );
                    })}

                    <Redirect from="/" to={navRoutes[0].path} />
                </Switch>
            </Suspense>
        </CContainer>
    );
};

export default memo(AppContent);
