import EternoSpinner from '@/components/EternoLogoSpinner/EternoSpinner';

const LoadingPage = () => {
    return (
        <div style={{ height: '70vh' }} className="w-100 d-flex align-items-center justify-content-center m-0 p-0">
            <EternoSpinner />
        </div>
    );
};

export default LoadingPage;
