import { cilCloudDownload } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { FC, useState } from 'react';

import ButtonWithLoadingSpinner from '@/components/ButtonWithLoadingSpinner';
import DetailsField from '@/components/DetailsField';

import { Document } from '@/models/Document';
import { MedicalRecordsDocument } from '@/models/prismic-types';

import { getDocumentTypeTranslation } from '@/utils/appointmentHelpers';

import { usePrismicStore } from '@/zustandStore';

interface Props {
    document: Document;
    downloadDocument: (documentId: string) => Promise<void>;
}

const AppointmentDetailsDocumentsItem: FC<Props> = ({ document, downloadDocument }) => {
    const [isLoading, setIsLoading] = useState(false);
    const medicalRecords: MedicalRecordsDocument['data'] | undefined = usePrismicStore((state) => state.medicalRecords);

    const handleDownloadDocument = async (documentId: string) => {
        setIsLoading(true);
        await downloadDocument(documentId);
        setIsLoading(false);
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '0.5rem',
            }}
        >
            <DetailsField name={getDocumentTypeTranslation(medicalRecords?.type_of_doc_response, document.type)}>
                <p className="m-0 p-0">{document.name}</p>
            </DetailsField>
            <ButtonWithLoadingSpinner
                onClick={() => handleDownloadDocument(document.id)}
                color="secondary"
                isLoading={isLoading}
            >
                <CIcon icon={cilCloudDownload} size="lg" />
            </ButtonWithLoadingSpinner>
        </div>
    );
};

export default AppointmentDetailsDocumentsItem;
