import { CForm, CFormInput } from '@coreui/react';
import { CSSProperties, FC } from 'react';
import OTPInput from 'react-otp-input';

import { MedicalRecordsDocument } from '@/models/prismic-types';

import { usePrismicStore } from '@/zustandStore';

const codeStyles: CSSProperties = {
    height: '11.25rem',
    width: '6.5rem',
    borderRadius: '1rem',
    fontSize: '4rem',
};

interface Props {
    code: string;
    setCode: (arg0: string) => void;
}

export const CodeInput: FC<Props> = ({ code, setCode }: Props) => {
    const medicalRecords: MedicalRecordsDocument['data'] | undefined = usePrismicStore((state) => state.medicalRecords);

    return (
        <>
            {medicalRecords?.input_description[0]?.text}
            <CForm
                style={{
                    marginTop: '1rem',
                    marginBottom: '1rem',
                }}
            >
                <OTPInput
                    containerStyle="flex gap-2"
                    value={code}
                    onChange={setCode}
                    numInputs={6}
                    inputType="text"
                    inputStyle={codeStyles}
                    renderInput={(props) => (
                        <CFormInput
                            {...props}
                            value={props.value as string}
                            autoComplete="false"
                            id="exampleFormControlInput1"
                        />
                    )}
                />
            </CForm>
        </>
    );
};
