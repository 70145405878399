import { CButton, CCard, CCol } from '@coreui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import WizardPopup from '@/components/AnamnesisBuilder/WizardPopup';

import { FeatureKey } from '@/utils/helpers';

import { useAdminConfigStore, useProfessionalsStore } from '@/zustandStore';

import LoadingPage from '../pages/loading/LoadingPage';
import LeftSideBar from './LeftSideBar';
import RightSideBar from './RightSideBar';

const PractitionersOnboarding = () => {
    const history = useHistory();
    const [wizardLoading, setWizardLoading] = useState(false);
    const [showAnamnesisBuilderWizard, setShowAnamnesisBuilderWizard] = useState(false);
    const featureAccess = useAdminConfigStore((state) => state.featureAccess);
    const professionals = useProfessionalsStore((state) => state.finalRecords);
    const templates = useProfessionalsStore((state) => state.templateArray);
    const childData = useProfessionalsStore((state) => state.childData);

    const shiftToAddFormPage = () => {
        history.push('/forms-portal');
    };

    return (
        <>
            {wizardLoading ? (
                <LoadingPage />
            ) : (
                <div className="row">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="name">Behandler:innen Onboarding</div>
                        <div className="d-flex gap-3">
                            {featureAccess[FeatureKey.ANAMNESIS_BUILDER] && (
                                <CButton
                                    className="custom-btn-text"
                                    color="secondary"
                                    onClick={() => setShowAnamnesisBuilderWizard(true)}
                                >
                                    Anamnesis Builder starten
                                </CButton>
                            )}
                            <CButton className="custom-btn-text" color="secondary" onClick={() => shiftToAddFormPage()}>
                                Ein neues Formular hinzufügen
                            </CButton>
                        </div>
                    </div>

                    <CCol lg={4} className="p-0 pb-5">
                        <CCard>
                            <LeftSideBar professionals={professionals} />
                        </CCard>
                    </CCol>
                    <CCol lg={8} className="p-0">
                        <CCard>
                            <RightSideBar data={childData} templates={templates} />
                        </CCard>
                    </CCol>
                </div>
            )}
            <WizardPopup
                showAnamnesisBuilderWizard={showAnamnesisBuilderWizard}
                setShowAnamnesisBuilderWizard={setShowAnamnesisBuilderWizard}
                professionals={professionals}
                selectedProfessional={childData}
                setWizardLoading={setWizardLoading}
            />
        </>
    );
};

export default PractitionersOnboarding;
