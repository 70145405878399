import { useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';

import { useTheme } from '@/context/theme/ThemeContext';

import { isAuthRequired } from '@/utils/constants';

import useAuth from './useAuth';

const useRequiredAuthCheck = () => {
    const { logout, user } = useAuth();
    const { colors } = useTheme();
    const versionNumber = import.meta.env.VITE_VERSION;
    const authRequired = isAuthRequired[versionNumber];
    const key = `auth-check-${versionNumber}`;

    const isOnLoginPage = useMemo(() => window.location.hash.slice(1) === '/login', [window]);

    const setLocalStorage = () => {
        localStorage.clear();
        localStorage.setItem(key, 'true');
    };

    useEffect(() => {
        if (!user) {
            if (!isOnLoginPage) return;

            setLocalStorage();
            return;
        }

        if (!authRequired || localStorage.getItem(key)) return;

        logout();
        setLocalStorage();
        toast('Sie wurden abgemeldet. Ihre Sitzung ist abgelaufen oder ein Update wurde ausgeführt!', {
            style: {
                border: `1px solid ${colors.secondary}`,
            },
        });
    }, []);
};

export default useRequiredAuthCheck;
