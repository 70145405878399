import { useLocationStore } from '@/zustandStore';

const LocationDropdown = ({ locations }) => {
    const loading = useLocationStore((state) => state.loading);

    const handleLocationChange = (event) => {
        const selection = locations.find((location) => location.key === event.target.value);
        useLocationStore.setState({
            selectedLocation: { ...selection },
        });
        localStorage.setItem('selectedLocation', selection.key);
    };

    return (
        <select
            disabled={loading || locations.length === 1}
            className="mx-3 py-1 my-3"
            onChange={(e) => handleLocationChange(e)}
            // eslint-disable-next-line react-compiler/react-compiler
            defaultValue={useLocationStore.getState().selectedLocation?.key}
        >
            {locations?.map((location) => (
                <option key={location.key} value={location.key}>
                    {location.name}
                </option>
            ))}
        </select>
    );
};

export default LocationDropdown;
