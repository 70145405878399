import { CModal, CModalBody, CModalHeader } from '@coreui/react';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { QuestionType } from '@/utils/constants';

import { useAnamnesisBuilderStore, usePrismicStore } from '@/zustandStore';

import QuestionOption from './QuestionOption';

const Problem = {
    MISSING_TITLE: 'Fehlender Titel',
    MISSING_KEY: 'Fehlender Question Key',
    MISSING_LABEL: 'Fehlendes Label',
    DUPLICATE_KEY: 'Question Key mehrfach verwendet',
    EMPTY_SUB_QUESTION: 'Leere Sub Question(s)',
};

const CustomQuestionsCheckModal = ({ showCustomQuestionsCheck, setShowCustomQuestionsCheck }) => {
    const allQuestions = usePrismicStore((state) => state.allQuestions);
    const problems = useAnamnesisBuilderStore((state) => state.problems);

    const getQuestionsWithMissingValues = () => {
        const questionTypesWithRequiredLabel = [
            QuestionType.DROPDOWN,
            QuestionType.TEXTFIELD,
            QuestionType.TEXT_AREA,
            QuestionType.NUMBER_FIELD,
            QuestionType.DATE_FIELD,
            QuestionType.LIST,
        ];
        const questionsWithMissingValues = [];

        Object.values(allQuestions).forEach((question) => {
            if (!question.question_key?.[0]?.text) {
                questionsWithMissingValues.push({ question: question, problem: Problem.MISSING_KEY });
            }
            if (!question.title?.[0]?.text) {
                questionsWithMissingValues.push({ question: question, problem: Problem.MISSING_TITLE });
            }
            if (!question.label?.[0]?.text && questionTypesWithRequiredLabel.includes(question.question_type)) {
                questionsWithMissingValues.push({ question: question, problem: Problem.MISSING_LABEL });
            }
        });

        return questionsWithMissingValues;
    };

    const getDuplicates = () => {
        const keyCount = new Map();
        const duplicates = new Set();
        const duplicateObjects = [];

        // Step 1: Count each ID
        Object.values(allQuestions).forEach((item) => {
            if (!item.question_key?.[0]?.text) return;
            if (keyCount.has(item.question_key[0].text)) {
                keyCount.set(item.question_key[0].text, keyCount.get(item.question_key[0].text) + 1);
            } else {
                keyCount.set(item.question_key[0].text, 1);
            }
        });

        // Step 2: Identify duplicates
        keyCount.forEach((count, key) => {
            if (count > 1) {
                duplicates.add(key);
            }
        });

        // Step 3: Collect duplicate objects
        if (duplicates.size > 0) {
            Object.values(allQuestions).forEach((item) => {
                if (duplicates.has(item.question_key?.[0]?.text)) {
                    duplicateObjects.push({ question: item, problem: Problem.DUPLICATE_KEY });
                }
            });
        }

        return duplicateObjects;
    };

    const checkForEmptySubQuestions = () => {
        const emptySubQuestionQuestions = [];

        Object.values(allQuestions).forEach((question) => {
            switch (question.question_type) {
                case 'Radio':
                    if (
                        question.radio_subquestions.some(
                            ({ radio_subquestion }) =>
                                !radio_subquestion || !allQuestions[radio_subquestion.id]?.question_type
                        )
                    ) {
                        emptySubQuestionQuestions.push({
                            question: question,
                            problem: Problem.EMPTY_SUB_QUESTION,
                        });
                    }
                    break;
                case 'Multi Select':
                    if (
                        question.multi_select_subquestions.some(
                            ({ multi_select_subquestion }) =>
                                !multi_select_subquestion || !allQuestions[multi_select_subquestion.id]?.question_type
                        )
                    ) {
                        emptySubQuestionQuestions.push({
                            question: question,
                            problem: Problem.EMPTY_SUB_QUESTION,
                        });
                    }
                    break;
                case 'List':
                    if (
                        question.list_subquestions.some(
                            ({ list_subquestion }) =>
                                !list_subquestion || !allQuestions[list_subquestion.id]?.question_type
                        )
                    ) {
                        emptySubQuestionQuestions.push({
                            question: question,
                            problem: Problem.EMPTY_SUB_QUESTION,
                        });
                    }
                    break;
                default:
                    break;
            }
        });

        return emptySubQuestionQuestions;
    };

    const runChecks = () => {
        useAnamnesisBuilderStore.setState({
            problems: [...getQuestionsWithMissingValues(), ...getDuplicates(), ...checkForEmptySubQuestions()],
        });
    };

    useEffect(() => {
        if (Object.keys(allQuestions)?.length > 0) runChecks();
    }, [allQuestions]);

    return (
        <CModal scrollable visible={showCustomQuestionsCheck} onClose={() => setShowCustomQuestionsCheck(false)}>
            <CModalHeader>Prismic Fragen Check</CModalHeader>
            <CModalBody className="d-flex flex-column align-items-center gap-4 p-4">
                <div className="d-flex flex-column gap-3 my-2 py-2" style={{ height: '90vh', overflow: 'scroll' }}>
                    {problems.map(({ question, problem }) => (
                        <QuestionOption
                            key={question.question_key[0]?.text ?? question.title[0]?.text ?? uuidv4()}
                            question={question}
                            problem={problem}
                            selectedQuestions={[]}
                            setSelectedQuestions={() => {}}
                        />
                    ))}
                </div>
            </CModalBody>
        </CModal>
    );
};

export default CustomQuestionsCheckModal;
