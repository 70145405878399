import {
    CButton,
    CCardTitle,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow,
} from '@coreui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePrismicStore, useTodaysAppointmentsStore } from '@/zustandStore';

import ProfessionalSelection from '../ProfessionalSelection';

const ManualCheckinModal = ({
    selectedAppointment,
    showNewManualCheckinModal,
    manualCheckinPatientName,
    setManualCheckinPatientName,
    manualCheckin,
}) => {
    const { t } = useTranslation();
    const [selectedProfessional, setSelectedProfessional] = useState('');
    const patients = useTodaysAppointmentsStore((state) => state.patients);
    const professionals = usePrismicStore((state) => state.professionals);

    useEffect(() => {
        if (showNewManualCheckinModal) {
            const patientName = selectedAppointment.user_id ? patients[selectedAppointment.user_id]?.name : '';

            setManualCheckinPatientName(
                selectedAppointment.data.patient_name ||
                    (patientName?.first ? `${patientName.first} ${patientName.last}` : '')
            );
            setSelectedProfessional(selectedAppointment.professional?.key || '');
        }
    }, [showNewManualCheckinModal]);

    return (
        <CModal
            scrollable
            visible={showNewManualCheckinModal}
            onClose={() => useTodaysAppointmentsStore.setState({ showNewManualCheckinModal: false })}
        >
            <CModalHeader />
            <CModalBody lg={12} md={12} sm={12} xs={12}>
                <CContainer style={{ marginTop: 10, marginBottom: 10 }}>
                    <h3>{t('Manual Checkin')}</h3>
                    <CRow xs={{ gutterY: 0 }}>
                        <CCol lg={12} md={12} sm={12} xs={12}>
                            <CCardTitle style={{ fontSize: 14 }}> {t('Please enter the patient`s name')} </CCardTitle>
                        </CCol>
                        <CCol lg={12} md={12} sm={12} xs={12}>
                            <CForm>
                                <div className="mb-3">
                                    <CFormInput
                                        type="text"
                                        value={manualCheckinPatientName}
                                        placeholder={`${t('Patient name')}`}
                                        name="name"
                                        onChange={(e) => setManualCheckinPatientName(e.target.value)}
                                    />
                                </div>
                            </CForm>
                        </CCol>
                        {professionals && (
                            <CCol lg={12} md={12} sm={12} xs={12}>
                                <CForm>
                                    <div className="mb-3">
                                        <CCardTitle style={{ fontSize: 14 }}>Professional</CCardTitle>
                                        <ProfessionalSelection
                                            selectedProfessional={selectedProfessional}
                                            setSelectedProfessional={setSelectedProfessional}
                                        />
                                    </div>
                                </CForm>
                            </CCol>
                        )}
                    </CRow>
                </CContainer>
            </CModalBody>

            <CModalFooter>
                <CButton
                    color="light"
                    onClick={() => useTodaysAppointmentsStore.setState({ showNewManualCheckinModal: false })}
                >
                    {t('Close')}
                </CButton>
                <CButton
                    disabled={!selectedProfessional || !manualCheckinPatientName}
                    onClick={() => {
                        manualCheckin(selectedProfessional);
                        useTodaysAppointmentsStore.setState({ showNewManualCheckinModal: false });
                    }}
                    color="secondary"
                >
                    {t('Save Changes')}
                </CButton>
            </CModalFooter>
        </CModal>
    );
};

export default ManualCheckinModal;
